import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal, Slider, styled } from '@mui/material';
import { useGetAccount, useGetNetworkConfig, useGetIsLoggedIn, useTrackTransactionStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { InputNumber } from 'antd';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelFlashMintCommonContext, JewelFlashMintFarmContext, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelLendPool, JewelLpFarmContext, JewelOnedexFarmContext, JewelswapFarm } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, ERROR_NOT_ENOUGH_BALANCE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getFarmApy, getFarmApyJewelLpFarm, getHatomApy, getJewelFlashMintHatomPoolApy, getJewelHatomPoolApy, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR, toastError, YEAR_IN_HOURS } from 'z/utils';
import { contracts, SLIPPAGE } from 'config';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openHatomPosition, openJewelFlashMintPosition, openJewelLpFarmPosition, openOnedexPosition, queryFarmGetEquivalent } from 'z/elrond';
import { routeNames } from 'routes';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const LeverageSlider = styled(Slider)({
    color: '#54F5B7',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-markLabel': {
        top: '30px'
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        backgroundColor: 'currentColor',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 35,
        height: 35,
        color: 'black',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#54F5B7',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const valueLabelFormat = (value: number) => {
    if (value === 1) {
        return `${value} X`;
    }
    return `${value} X`;
};

interface valueMark {
    value: number,
    label: string,
}

const LEVERAGE_MARKS: valueMark[] = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 1.5,
        label: '1.5',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 2.5,
        label: '2.5',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 3.5,
        label: '3.5',
    },
    {
        value: 4,
        label: '4',
    },
];

interface JewelLpFarmPoolProps {
    farm: JewelLpFarmContext,
    jewelswapFarm: JewelswapFarm,
    ashswapTokens: any,
    userTokens: any,
    poolName: string,
}

export const JewelLpFarmPool: FC<JewelLpFarmPoolProps> = ({
    farm,
    jewelswapFarm,
    ashswapTokens,
    userTokens,
    poolName,
}) => {
    const navigate = useNavigate();
    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, balance, shard } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [tokenPrices, setTokenPrices] = useState([]);
    useEffect(() => {
        if (ashswapTokens?.data) {
            setTokenPrices(ashswapTokens.data.tokens);
        }
    }, [ashswapTokens]);

    const [equivalentAmount, setEquivalentAmount] = useState<string>('0');
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const outputAmount = await queryFarmGetEquivalent(apiAddress, farm.jaf_farm_id, farm.lp_token_id, farm.lp_farm_base_token_id, new BigNumber(convertEsdtToWei(1)).toFixed());
            setEquivalentAmount(outputAmount);
        })();
    }, [hasPendingTransactions]);

    const maxLeverage = farm.lp_farm_leverage_max_percent / PERCENT_DENOMINATOR / 100;
    const [showFarmModal, setShowFarmModal] = useState<boolean>(false);
    const [selectedLeverage, setSelectedLeverage] = useState<number>(maxLeverage);
    const [tokenAmount, setTokenAmount] = useState<string>('0');
    const [tokenErrorMsg, setTokenErrorMsg] = useState<string>('');
    const [leverage, setLeverage] = useState<number>(maxLeverage); // modal

    const handleChangeSelectedLeverage = (value: any, isModal = false) => {
        if (Number(value) > maxLeverage) {
            if (isModal) {
                setLeverage(maxLeverage);
            } else {
                setSelectedLeverage(maxLeverage);
            }
        } else {
            if (isModal) {
                setLeverage(value);
            } else {
                setSelectedLeverage(value);
            }
        }
    };

    const handleShowModal = () => {
        if (!farm) return;
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        setTokenAmount('0');
        setLeverage(maxLeverage);
        setShowFarmModal(true);
    };

    const getErrorMsg = (value: BigNumber, index: number): string => {
        const userBalance = userTokens[farm.lp_token_id].balance;

        let error = '';

        if (value == undefined) {
            error = 'Invalid number';
        } else if (value.comparedTo(0) < 0) {
            error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
        } else {
            if (value.comparedTo(userBalance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        return error;
    };

    const handleChangeTokenAmount = (value: string, index: number) => {
        setTokenAmount(value);
        setTokenErrorMsg(getErrorMsg(convertEsdtToWei(value, getTokenDecimal(farm.lp_token_id)), index));
    };

    const handleMax = async (index: number) => {
        const value = convertWeiToEsdt(userTokens[farm.lp_token_id].balance, getTokenDecimal(farm.lp_token_id)).toFixed();
        handleChangeTokenAmount(value, index);
    };

    const handleOpenPosition = async () => {
        if (!farm || tokenErrorMsg !== '') return;

        // check min deposit
        const depositTokenAmount = BigNumber(tokenAmount).multipliedBy(equivalentAmount);

        if (depositTokenAmount.lt(farm.lp_farm_min_deposit_base_amount)) {
            toastError(`Please deposit more than ${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_min_deposit_base_amount, getTokenDecimal(farm.lp_farm_base_token_id)))} ${getTokenTicker(farm.lp_farm_base_token_id)}`);
            return;
        }

        // check max cap
        let debtAmount = BigNumber(tokenAmount).multipliedBy(equivalentAmount).multipliedBy(leverage - 1).multipliedBy(Math.pow(10, getTokenDecimal(farm.lp_farm_debt_token_id) - getTokenDecimal(farm.lp_farm_base_token_id)));
        debtAmount = debtAmount.plus(debtAmount.multipliedBy(0.025));
        if (debtAmount.gt(0)) {
            if (debtAmount.plus(farm.lp_farm_debt_token_amount).gt(farm.lp_farm_max_cap)) {
                toastError(`Can not borrow more than max cap`);
                return;
            }
        }

        // open position 
        const result = await openJewelLpFarmPosition(chainID, address, farm.lp_farm_id, farm.lp_token_id, convertEsdtToWei(tokenAmount).toFixed(), leverage * 100 * PERCENT_DENOMINATOR);

        setShowFarmModal(false);

        setSessionId(result.sessionId);
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('Enter farm request was successful');
            navigate('/mypositions/jewelswap');
        }
    }, [sessionId, hasPendingTransactions]);

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 farm-container mobile-farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center d-flex mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='combine-bage'>
                                {farm.token_ids.map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />))}
                            </div>
                            <div>
                                <div>
                                    {farm.token_ids.map((item: string, key: number) => {
                                        let poolName = '';
                                        if (key === (farm.token_ids.length - 1)) {
                                            poolName += getTokenTicker(item);
                                        } else {
                                            poolName += `${getTokenTicker(item)}-`;
                                        }
                                        return poolName;
                                    })}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-4'>
                        <div className='d-flex align-items-center'>TVL</div>
                        <div>
                            <div className='text-end'>
                                {jewelswapFarm ? `$${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(jewelswapFarm.total_staked_lp_amount).multipliedBy(jewelswapFarm.lp_price), getTokenDecimal(farm.lp_token_id)))} (${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelswapFarm.total_staked_lp_amount, getTokenDecimal(farm.lp_token_id)))} LP tokens)` : '- LP'}
                            </div>
                            {
                                jewelswapFarm && farm.token_ids.map((item: string, index: number) => (
                                    <div key={index} className='text-end'>
                                        {`${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelswapFarm.reserves[index], getTokenDecimal(item)))} ${getTokenTicker(item)} (1 ${getTokenTicker(item)} = ${tokenPrices.length > 0 ? tokenPrices.filter((token: any) => token.id === item)[0]['price'] : '-'} USD)`}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-2'>
                        <div className='d-flex align-items-center'>Total Borrowed</div>
                        <div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_debt_token_amount))} / ${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_max_cap))} ${getTokenTicker(farm.lp_farm_debt_token_id)}`}
                            </div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(new BigNumber(farm.lp_farm_debt_token_amount).dividedBy(farm.lp_farm_max_cap).multipliedBy(100))}% utilized`}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-between mt-2'>
                        <div className='d-flex'>
                            <div className='d-flex'>APY</div>
                            <Tooltip
                                placement="top"
                                title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                        <div className='avaliable-color'>{`${jewelswapFarm ? convertBigNumberValueToLocalString(getFarmApyJewelLpFarm(jewelswapFarm.farm_id, jewelswapFarm.apr, YEAR_IN_HOURS) * selectedLeverage) : '-'}%`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>Leverage</div>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center mt-4'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2 w-100" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 farm-container'>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center'>
                        <div className='d-flex align-items-center'>
                            <div className='combine-bage'>
                                {farm.token_ids.map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />))}
                            </div>
                            <div>
                                <div>
                                    {farm.token_ids.map((item: string, index: number) => {
                                        let poolName = '';
                                        if (index === (farm.token_ids.length - 1)) {
                                            poolName += getTokenTicker(item);
                                        } else {
                                            poolName += `${getTokenTicker(item)}-`;
                                        }
                                        return poolName;
                                    })}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <div className='justify-content-center'>
                            {jewelswapFarm ? `$${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(jewelswapFarm.total_staked_lp_amount).multipliedBy(jewelswapFarm.lp_price), getTokenDecimal(farm.lp_token_id)))} (${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelswapFarm.total_staked_lp_amount, getTokenDecimal(farm.lp_token_id)))} LP tokens)` : '- LP'}
                        </div>
                        {
                            jewelswapFarm && farm.token_ids.map((item: string, index: number) => (
                                <div key={index} className='justify-content-center'>
                                    {`${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelswapFarm.reserves[index], getTokenDecimal(item)))} ${getTokenTicker(item)} (1 ${getTokenTicker(item)} = ${tokenPrices.length > 0 ? tokenPrices.filter((token: any) => token.id === item)[0]['price'] : '-'} USD)`}
                                </div>
                            ))
                        }
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <div>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_debt_token_amount))} / ${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_max_cap))} ${getTokenTicker(farm.lp_farm_debt_token_id)}`}
                        </div>
                        <div>
                            {`${convertBigNumberValueToLocalString(new BigNumber(farm.lp_farm_debt_token_amount).dividedBy(farm.lp_farm_max_cap).multipliedBy(100))}% utilized`}
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 text-center align-self-center'>
                        <div className='avaliable-color'>{`${jewelswapFarm ? convertBigNumberValueToLocalString(getFarmApyJewelLpFarm(jewelswapFarm.farm_id, jewelswapFarm.apr, YEAR_IN_HOURS) * selectedLeverage) : '-'}%`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showFarmModal}
                onClose={() => setShowFarmModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showFarmModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        {
                            getTokenTicker(farm.lp_farm_debt_token_id) === 'JWLEGLD' && (
                                <div className='d-flex'>
                                    JewelSwap farms allow users to obtain 1-step leverage borrowing $JWLEGLD to amplify their farm positions. Profitable positions may be closed in times of heavy $JWLEGLD redemptions. Please be aware of a potential large price impact when opening a large position.
                                </div>
                            )
                        }
                        {
                            getTokenTicker(farm.lp_farm_debt_token_id) === 'JWLUSD' && (
                                <div className='d-flex'>
                                    JewelSwap farms allow users to obtain 1-step leverage borrowing $JWLUSD to amplify their farm positions. Profitable positions may be closed in times of heavy $JWLUSD redemptions. Please be aware of a potential large price impact when opening a large position.
                                </div>
                            )
                        }
                        {
                            getTokenTicker(farm.lp_farm_debt_token_id) === 'HTM' && (
                                <div className='d-flex'>
                                    JewelSwap farms allow users to obtain 1-step leverage borrowing $HTM to amplify their farm positions. Profitable positions may be closed in times of heavy $HTM redemptions. Please be aware of a potential large price impact when opening a large position.
                                </div>
                            )
                        }
                        <div className='d-flex mt-4'>
                            How much would you like to add for farming?
                        </div>
                        <div className='mt-3 borrow-form'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                    <div className={`combine-bage ${farm.token_ids.length === 3 ? 'combine-bage-width' : ''}`}>
                                        {farm.token_ids.map((tokenId: string, i: number) => (<img key={i} src={getTokenImage(tokenId)} className='egld-image mx-2' alt={tokenId} />))}
                                    </div>
                                    <div style={{ fontSize: isMobile ? '0.7rem' : '0.8rem' }}>{getTokenTicker(farm.lp_token_id)}</div>
                                </div>
                                <input type="number" value={tokenAmount} onChange={(e: any) => handleChangeTokenAmount(e.target.value, 0)} />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-center' style={{ fontSize: isMobile ? '0.7rem' : '0.8rem', marginRight: '3px' }}>{`Avail :`}</div>
                                    <div style={{ fontSize: isMobile ? '0.7rem' : '0.8rem' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[farm.lp_token_id]?.balance))} ${getTokenTicker(farm.lp_token_id)}`}</div>
                                </div>
                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                    <button className='max-but' onClick={() => handleMax(0)}>MAX</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                            {tokenErrorMsg}
                        </div>
                        <div className={`d-flex gap-2 ${tokenErrorMsg === '' ? 'mt-2' : ''}`}>
                            <div className='d-flex align-items-center'>Leverage</div>
                            <InputNumber
                                className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                                type='number'
                                lang='en'
                                min={1}
                                step={0.5}
                                value={leverage}
                                onChange={(value: any) => handleChangeSelectedLeverage(value, true)}
                            />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div style={{ width: '95%' }}>
                                <LeverageSlider
                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    min={1}
                                    max={maxLeverage}
                                    step={0.01}
                                    marks={LEVERAGE_MARKS}
                                    value={leverage}
                                    valueLabelFormat={valueLabelFormat}
                                    onChange={(e: any) => setLeverage(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex mt-2'>
                            Summary
                        </div>
                        <div className='farm-summary mt-2'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span className='d-flex align-items-center'>Leverage</span>
                                <div className='d-flex gap-2'>
                                    <span className='d-flex align-items-center'>{`${leverage}X`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Yield Farm APY</span>
                                <div className='d-flex gap-2'>
                                    <span className='avaliable-color d-flex align-items-center'>{`${jewelswapFarm ? convertBigNumberValueToLocalString(getFarmApyJewelLpFarm(jewelswapFarm.farm_id, jewelswapFarm.apr * leverage, YEAR_IN_HOURS)) : '-'}%`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Deposit Amount (Current)</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(farm.lp_farm_base_token_id)} alt={farm.lp_farm_base_token_id} />
                                    <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(tokenAmount).multipliedBy(equivalentAmount), getTokenDecimal(farm.lp_farm_base_token_id)))}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Deposit Amount (MIN)</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(farm.lp_farm_base_token_id)} alt={farm.lp_farm_base_token_id} />
                                    <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_farm_min_deposit_base_amount, getTokenDecimal(farm.lp_farm_base_token_id)))}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Debt Amount</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(farm.lp_farm_debt_token_id)} alt={farm.lp_farm_debt_token_id} />
                                    <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(tokenAmount).multipliedBy(equivalentAmount).multipliedBy(leverage - 1), getTokenDecimal(farm.lp_farm_base_token_id)))}</span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleOpenPosition}>
                                Farm
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
