import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal } from '@mui/material';
import { useGetAccount, useGetNetworkConfig, useGetIsLoggedIn, useTrackTransactionStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelOnedexFarmContext } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, ERROR_NOT_ENOUGH_BALANCE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR, toastError, YEAR_IN_HOURS } from 'z/utils';
import { contracts, SLIPPAGE } from 'config';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openOnedexPosition } from 'z/elrond';
import { routeNames } from 'routes';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

interface OneDexFarmPoolProps {
    farm: JewelOnedexFarmContext,
    userTokens: any,
    poolName: string,
}

export const OneDexFarmPool: FC<OneDexFarmPoolProps> = ({
    farm,
    userTokens,
    poolName,
}) => {
    const navigate = useNavigate();
    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, balance, shard } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [showFarmModal, setShowFarmModal] = useState<boolean>(false);
    const [firstTokenAmount, setFirstTokenAmount] = useState<string>('0');
    const [secondTokenAmount, setSecondTokenAmount] = useState<string>('0');
    const [lpTokenAmount, setLpTokenAmount] = useState<string>('0');
    const [firstTokenErrorMsg, setFirstTokenErrorMsg] = useState<string>('');
    const [secondTokenErrorMsg, setSecondTokenErrorMsg] = useState<string>('');
    const [lpTokenErrorMsg, setLpTokenErrorMsg] = useState<string>('');

    const handleShowFarmModal = () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        setFirstTokenAmount('0');
        setSecondTokenAmount('0');
        setLpTokenAmount('0');
        setFirstTokenErrorMsg('');
        setSecondTokenErrorMsg('');
        setLpTokenErrorMsg('');
        setShowFarmModal(true);
    };

    const getEquivalent = (value: BigNumber, index: number): BigNumber => {
        if (index === 0) {
            // updated first token amount
            const newFirstTokenReservePercent = value.dividedBy(farm.first_token_reserve);
            return BigNumber(farm.second_token_reserve).multipliedBy(newFirstTokenReservePercent);
        } else {
            // updated second token amount
            const newSecondTokenReservePercent = value.dividedBy(farm.second_token_reserve);
            return BigNumber(farm.first_token_reserve).multipliedBy(newSecondTokenReservePercent);
        }
    };

    const getEquivalentAmount = (depositTokenId: string, value: string): BigNumber => {
        const k = BigNumber(farm.first_token_reserve).multipliedBy(farm.second_token_reserve);
        if (depositTokenId === farm.first_token_id) {
            const x = BigNumber(farm.first_token_reserve).plus(value);
            const y = k.dividedBy(x);
            return BigNumber(farm.second_token_reserve).minus(y);
        } else {
            const x = BigNumber(farm.second_token_reserve).plus(value);
            const y = k.dividedBy(x);
            return BigNumber(farm.first_token_reserve).minus(y);
        }
    };

    const getErrorMsg = (value: BigNumber, index: number): string => {
        let userBalance = '0';
        if (index === 0) {
            if (getTokenTicker(farm.first_token_id) === 'EGLD') {
                userBalance = BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
            } else {
                userBalance = userTokens[farm.first_token_id].balance;
            }
        } else if (index === 1) {
            if (getTokenTicker(farm.second_token_id) === 'EGLD') {
                userBalance = BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
            } else {
                userBalance = userTokens[farm.second_token_id].balance;
            }
        } else {
            userBalance = userTokens[farm.lp_token_id].balance;
        }

        let error = '';

        if (value == undefined) {
            error = 'Invalid number';
        } else if (value.comparedTo(0) < 0) {
            error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
        } else {
            if (value.comparedTo(userBalance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        return error;
    };

    const handleChangeTokenAmount = (value: string, index: number) => {
        if (index === 0) {
            // update first token
            setFirstTokenAmount(value);
            setFirstTokenErrorMsg(getErrorMsg(convertEsdtToWei(value, getTokenDecimal(farm.first_token_id)), index));
            // const newSecondTokenAmount = getEquivalent(convertEsdtToWei(value, getTokenDecimal(farm.first_token_id)), index);
            // setSecondTokenErrorMsg(getErrorMsg(newSecondTokenAmount, 1));
            // setSecondTokenAmount(convertWeiToEsdt(newSecondTokenAmount, getTokenDecimal(farm.second_token_id)).toFixed());
        } else if (index === 1) {
            // update second token
            setSecondTokenAmount(value);
            setSecondTokenErrorMsg(getErrorMsg(convertEsdtToWei(value, getTokenDecimal(farm.second_token_id)), index));
            // const newFirstTokenAmount = getEquivalent(convertEsdtToWei(value, getTokenDecimal(farm.second_token_id)), index);
            // setFirstTokenErrorMsg(getErrorMsg(newFirstTokenAmount, 0));
            // setFirstTokenAmount(convertWeiToEsdt(newFirstTokenAmount, getTokenDecimal(farm.first_token_id)).toFixed());
        } else {
            // update lp token
            setLpTokenAmount(value);
            setLpTokenErrorMsg(getErrorMsg(convertEsdtToWei(value, getTokenDecimal(farm.lp_token_id)), index));
        }
    };

    const handleMax = async (index: number) => {
        let value = '0';
        if (index === 0) {
            // first token
            if (getTokenTicker(farm.first_token_id) === 'EGLD') {
                value = convertWeiToEsdt(BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT), getTokenDecimal(farm.first_token_id)).toFixed();
            } else {
                value = convertWeiToEsdt(userTokens[farm.first_token_id].balance, getTokenDecimal(farm.first_token_id)).toFixed();
            }
        } else if (index === 1) {
            // second token
            if (getTokenTicker(farm.second_token_id) === 'EGLD') {
                value = convertWeiToEsdt(BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT), getTokenDecimal(farm.second_token_id)).toFixed();
            } else {
                value = convertWeiToEsdt(userTokens[farm.second_token_id].balance, getTokenDecimal(farm.second_token_id)).toFixed();
            }
        } else {
            value = convertWeiToEsdt(userTokens[farm.lp_token_id].balance, getTokenDecimal(farm.lp_token_id)).toFixed();
        }
        handleChangeTokenAmount(value, index);
    };

    const handleOpenPosition = async () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        if (firstTokenErrorMsg) {
            toastError(firstTokenErrorMsg);
            return;
        }
        if (secondTokenErrorMsg) {
            toastError(firstTokenErrorMsg);
            return;
        }
        if (lpTokenErrorMsg) {
            toastError(lpTokenErrorMsg);
            return;
        }

        let _depositAmount = BigNumber('0');
        const tokenIds = [];
        const tokenAmounts = [];

        if (BigNumber(firstTokenAmount).gt('0')) {
            tokenIds.push(farm.first_token_id);
            tokenAmounts.push(convertEsdtToWei(firstTokenAmount, getTokenDecimal(farm.first_token_id)).toFixed());
        }
        if (BigNumber(secondTokenAmount).gt('0')) {
            tokenIds.push(farm.second_token_id);
            tokenAmounts.push(convertEsdtToWei(secondTokenAmount, getTokenDecimal(farm.second_token_id)).toFixed());
        }
        if (BigNumber(lpTokenAmount).gt('0')) {
            tokenIds.push(farm.lp_token_id);
            tokenAmounts.push(convertEsdtToWei(lpTokenAmount, getTokenDecimal(farm.lp_token_id)).toFixed());
        }

        if (tokenIds.length > 0) {
            for (let i = 0; i < tokenIds.length; i++) {
                if (tokenIds[i] === farm.min_deposit_base_token) {
                    _depositAmount = _depositAmount.plus(tokenAmounts[i]);
                } else if (tokenIds[i] === farm.lp_token_id) {
                    // lp
                    const _firstTokenAmount = BigNumber(farm.first_token_reserve).multipliedBy(tokenAmounts[i]).dividedBy(farm.lp_token_supply);
                    const _secondTokenAmount = BigNumber(farm.second_token_reserve).multipliedBy(tokenAmounts[i]).dividedBy(farm.lp_token_supply);
                    if (farm.first_token_id === farm.min_deposit_base_token) {
                        _depositAmount = _depositAmount.plus(_firstTokenAmount);
                        const equivalentAmount = getEquivalentAmount(farm.second_token_id, _secondTokenAmount.toFixed());
                        _depositAmount = _depositAmount.plus(equivalentAmount);
                    } else {
                        _depositAmount = _depositAmount.plus(_secondTokenAmount);
                        const equivalentAmount = getEquivalentAmount(farm.first_token_id, _firstTokenAmount.toFixed());
                        _depositAmount = _depositAmount.plus(equivalentAmount);
                    }
                } else {
                    const equivalentAmount = getEquivalentAmount(tokenIds[i], tokenAmounts[i]);
                    _depositAmount = _depositAmount.plus(equivalentAmount);
                }
            }

            // console.log(_depositAmount.toFixed());

            if (_depositAmount.gt(farm.min_deposit_base_amount)) {
                let wrapAddress = contracts.Wrap0.address;

                if (shard === 1) {
                    wrapAddress = contracts.Wrap1.address;
                } else if (shard === 2) {
                    wrapAddress = contracts.Wrap2.address;
                }

                const result = await openOnedexPosition(chainID, address, farm.farm_id, tokenIds, tokenAmounts, SLIPPAGE * PERCENT_DENOMINATOR, wrapAddress);
                setSessionId(result.sessionId);

                setShowFarmModal(false);

                return;
            }
        }

        // error msg
        toastError(`Please deposit more than ${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.min_deposit_base_amount, getTokenDecimal(farm.min_deposit_base_token)))} ${getTokenTicker(farm.min_deposit_base_token)}`);
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('Enter farm request was successful');
            navigate('/mypositions/onedex');
        }
    }, [sessionId, hasPendingTransactions]);

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 farm-container mobile-farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center d-flex mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='combine-bage'>
                                <img src={getTokenImage(farm.first_token_id)} className='egld-image mx-2' alt={farm.first_token_id} />
                                <img src={getTokenImage(farm.second_token_id)} className='egld-image mx-2' alt={farm.second_token_id} />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(farm.first_token_id)}-${getTokenTicker(farm.second_token_id)}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-4'>
                        <div className='d-flex align-items-center'>TVL</div>
                        <div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_token_amount, getTokenDecimal(farm.lp_token_id)))} LP tokens`}
                            </div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.lp_token_amount).multipliedBy(farm.first_token_reserve).dividedBy(farm.lp_token_supply), getTokenDecimal(farm.first_token_id)))} ${getTokenTicker(farm.first_token_id)}`}
                            </div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.lp_token_amount).multipliedBy(farm.second_token_reserve).dividedBy(farm.lp_token_supply), getTokenDecimal(farm.second_token_id)))} ${getTokenTicker(farm.second_token_id)}`}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-between mt-2'>
                        <div className='d-flex'>
                            <div className='d-flex'>APY</div>
                            <Tooltip
                                placement="top"
                                title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                        <div className='avaliable-color'>{`${convertBigNumberValueToLocalString(getFarmApy(farm.apr, YEAR_IN_HOURS))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between'>
                        <div className='d-flex'>Leverage</div>
                        <div className='d-flex'>1X</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center mt-4'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2 w-100" style={{ cursor: 'pointer' }} onClick={handleShowFarmModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 farm-container'>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center'>
                        <div className='d-flex align-items-center'>
                            <div className='combine-bage'>
                                <img src={getTokenImage(farm.first_token_id)} className='egld-image mx-2' alt={farm.first_token_id} />
                                <img src={getTokenImage(farm.second_token_id)} className='egld-image mx-2' alt={farm.second_token_id} />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(farm.first_token_id)}-${getTokenTicker(farm.second_token_id)}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 text-center align-self-center'>
                        <div className='d-flex justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.lp_token_amount, getTokenDecimal(farm.lp_token_id)))} LP tokens`}
                        </div>
                        <div className='d-flex justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.lp_token_amount).multipliedBy(farm.first_token_reserve).dividedBy(farm.lp_token_supply), getTokenDecimal(farm.first_token_id)))} ${getTokenTicker(farm.first_token_id)}`}
                        </div>
                        <div className='d-flex justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.lp_token_amount).multipliedBy(farm.second_token_reserve).dividedBy(farm.lp_token_supply), getTokenDecimal(farm.second_token_id)))} ${getTokenTicker(farm.second_token_id)}`}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <div className='avaliable-color'>{`${convertBigNumberValueToLocalString(getFarmApy(farm.apr, YEAR_IN_HOURS))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        1X
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleShowFarmModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showFarmModal}
                onClose={() => setShowFarmModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showFarmModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex'>
                            Warning: Please be aware of a potential large price impact when opening a large position. This is due to your position size compared to the underlying liquidity of the pool.
                        </div>
                        <div className='d-flex mt-4'>
                            How much would you like to add for farming?
                        </div>
                        <div className='mt-3 borrow-form'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                    <img src={getTokenImage(farm.first_token_id)} alt={farm.first_token_id} />
                                    <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(farm.first_token_id)}</div>
                                </div>
                                <input type="number" value={firstTokenAmount} onChange={(e: any) => handleChangeTokenAmount(e.target.value, 0)} />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                    <div style={{ fontSize: '0.9rem' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(farm.first_token_id) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[farm.first_token_id]?.balance, getTokenDecimal(farm.first_token_id)))} ${getTokenTicker(farm.first_token_id)}`}</div>
                                </div>
                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                    <button className='max-but' onClick={() => handleMax(0)}>MAX</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                            {firstTokenErrorMsg}
                        </div>
                        <div className='mt-3 borrow-form'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                    <img src={getTokenImage(farm.second_token_id)} alt={farm.second_token_id} />
                                    <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(farm.second_token_id)}</div>
                                </div>
                                <input type="number" value={secondTokenAmount} onChange={(e: any) => handleChangeTokenAmount(e.target.value, 1)} />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                    <div style={{ fontSize: '0.9rem' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(farm.second_token_id) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[farm.second_token_id]?.balance, getTokenDecimal(farm.second_token_id)))} ${getTokenTicker(farm.second_token_id)}`}</div>
                                </div>
                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                    <button className='max-but' onClick={() => handleMax(1)}>MAX</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                            {secondTokenErrorMsg}
                        </div>
                        <div className='mt-3 borrow-form'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                    <div className={`combine-bage`}>
                                        <img src={getTokenImage(farm.first_token_id)} className='egld-image mx-2' alt={farm.first_token_id} />
                                        <img src={getTokenImage(farm.second_token_id)} className='egld-image mx-2' alt={farm.second_token_id} />
                                    </div>
                                    <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(farm.lp_token_id)}</div>
                                </div>
                                <input type="number" value={lpTokenAmount} onChange={(e: any) => handleChangeTokenAmount(e.target.value, 2)} />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                    <div style={{ fontSize: '0.9rem' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(farm.lp_token_id) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[farm.lp_token_id]?.balance, getTokenDecimal(farm.lp_token_id)))} ${getTokenTicker(farm.lp_token_id)}`}</div>
                                </div>
                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                    <button className='max-but' onClick={() => handleMax(2)}>MAX</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                            {lpTokenErrorMsg}
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleOpenPosition}>
                                Farm
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
