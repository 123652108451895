import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn, useTrackTransactionStatus, useGetSuccessfulTransactions } from '@multiversx/sdk-dapp/hooks';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isMobile } from 'react-device-detect';
import BigNumber from 'bignumber.js';
import { queryJewelExchangeFarmBaseContext, queryJewelExchangeFarmStatsContext, queryJewelStableCommonContext, queryJewelStableStatsContext, mintJwlxmex, sendJewelStableRedeem, stakeJwlxmex, sendJewelStableUnbond, unstakeJwlxmex, wrapXmex, getWrapXmexResult } from 'z/elrond';
import { ElrondStatsType, JewelExchangeFarmBaseContext, JewelExchangeFarmStatsContext, JewelStableCommonContext, JewelStableStatsContext, NftWithIndexType } from 'z/types';
import { Spinner } from 'components/Spinner';
import { StakeInput } from './StakeInput';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_INVALID_VALUE, getTokenImage, getTokenTicker, getUnbondEndTimeString, getUnstakeTokenAttributes, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import './index.scss';
import { getElrondStatsFromApi, getNftsByCollectionFromApi, getUserTokenBalance } from 'z/api';
import { JEWEL_EXCHANGE_SC_DEPLOYED_TIMESTAMP, USDC_TOKEN_ID, WDAI_TOKEN_ID, XMEX_TOKEN_ID } from 'config';
import { Dropdown } from 'react-bootstrap';
import { TokenType } from '@multiversx/sdk-dapp/types/tokens.types';
import { numberToPaddedHex } from '@multiversx/sdk-core/out/utils.codec';
import { TokenTransfer } from '@multiversx/sdk-core/out';

export const XmexStake = () => {
    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions, pendingTransactions } = useGetPendingTransactions();
    // const { successfulTransactions } = useGetSuccessfulTransactions();

    const [selectedTokenNonce, setSelectedTokenNonce] = useState<number>(0);
    const selectedTokenId = `${XMEX_TOKEN_ID}-${numberToPaddedHex(selectedTokenNonce)}`;
    const [selectedTokenBalance, setSelectedTokenBalance] = useState<string>('0');

    const [jwlxmexTokenBalance, setJwlxmexTokenBalance] = useState<string>('0');
    const [sjwlxmexTokenBalance, setSjwlxmexTokenBalance] = useState<string>('0');
    const [walletNfts, setWalletNfts] = useState<NftWithIndexType[]>([]);

    const [isMintExpanded, setIsMintExpanded] = useState<boolean>(false);
    const [mintInputAmount, setMintInputAmount] = useState<string>('0');
    const [mintInputAmountError, setMintInputAmountError] = useState<string>('');
    const [redeemInputAmount, setRedeemInputAmount] = useState<string>('0');
    const [redeemInputAmountError, setRedeemInputAmountError] = useState<string>('');

    const [isStakeExpanded, setIsStakeExpanded] = useState<boolean>(false);
    const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
    const [stakeInputAmountError, setStakeInputAmountError] = useState<string>('');
    const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
    const [unstakeInputAmountError, setUnstakeInputAmountError] = useState<string>('');

    const [networkConfig, setNetworkConfig] = useState<ElrondStatsType>();
    const [unbondingNfts, setUnbondingNfts] = useState<NftWithIndexType[]>([]);
    const [unbondedNfts, setUnbondedNfts] = useState<NftWithIndexType[]>([]);
    const [withdrawlAmount, setWithdrawlAmount] = useState<string>('0');
    const [apy, setApy] = useState<string>('-');

    // xExchange
    const [exchangeFarmCommonSettings, setExchangeFarmCommonSettings] = useState<JewelExchangeFarmBaseContext | undefined>();
    const [exchangeFarmStats, setExchangeFarmStats] = useState<JewelExchangeFarmStatsContext | undefined>();

    useEffect(() => {
        (async () => {
            const exchange_base_context = await queryJewelExchangeFarmBaseContext();
            console.log('exchange_base_context', exchange_base_context);
            setExchangeFarmCommonSettings(exchange_base_context);
        })();
    }, []);
    useEffect(() => {
        if (hasPendingTransactions) return;
        (async () => {
            const _stats = await queryJewelExchangeFarmStatsContext();
            console.log('exchangeFarmStats', _stats);
            setExchangeFarmStats(_stats);

            if (_stats && BigNumber(_stats.sjwlxmex_pool_reserve).gt('0')) {
                const _tokenAmount = convertEsdtToWei(1).multipliedBy(_stats.jwlxmex_pool_reserve).dividedBy(_stats.sjwlxmex_pool_reserve);
                const _passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_EXCHANGE_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
                const _apy = _tokenAmount.minus(convertEsdtToWei(1)).dividedBy(_passedDays).multipliedBy(365).multipliedBy(100);
                setApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
            }
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions || !exchangeFarmCommonSettings || !address) return;

        (async () => {
            const tokenIds = [exchangeFarmCommonSettings.jwlxmex_token, exchangeFarmCommonSettings.sjwlxmex_token];
            const _balances = await getUserTokenBalance(apiAddress, address, tokenIds);

            for (const balance of _balances) {
                if (balance.identifier === exchangeFarmCommonSettings.jwlxmex_token && balance.balance) {
                    setJwlxmexTokenBalance(balance.balance);
                } else if (balance.identifier === exchangeFarmCommonSettings.sjwlxmex_token && balance.balance) {
                    setSjwlxmexTokenBalance(balance.balance);
                }
            }
        })();
    }, [hasPendingTransactions, exchangeFarmCommonSettings, address]);

    useEffect(() => {
        if (hasPendingTransactions || !address) return;

        (async () => {
            const _nfts = await getNftsByCollectionFromApi(apiAddress, address, XMEX_TOKEN_ID);
            setWalletNfts(_nfts);
        })();
    }, [hasPendingTransactions, address]);

    const handleSelectedTokenNonce = async (index: number, value: number) => {
        setSelectedTokenNonce(value);
        setSelectedTokenBalance(walletNfts[index].balance);
        setMintInputAmount('0');
    };

    const handleMintExpand = () => {
        cleanMintAmount();

        setIsMintExpanded(!isMintExpanded);
    };

    const handleStakeExpand = () => {
        cleanStakeAmount();

        setIsStakeExpanded(!isStakeExpanded);
    };

    const cleanMintAmount = () => {
        setMintInputAmount('0');
        setMintInputAmountError('');
        setRedeemInputAmount('0');
        setRedeemInputAmountError('');
    };

    const cleanStakeAmount = () => {
        setStakeInputAmount('0');
        setStakeInputAmountError('');
        setUnstakeInputAmount('0');
        setUnstakeInputAmountError('');
    };

    const [mintSessionId, setMintSessionId] = useState<string>('');
    // console.log('mintSessionId', mintSessionId);
    // const mintTransactionStatus = useTrackTransactionStatus({ transactionId: mintSessionId });
    // console.log('pendingTransactions', pendingTransactions);

    const handleMint = async () => {
        if (!exchangeFarmCommonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(mintInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (mintInputAmountError) {
            toastError(mintInputAmountError);
            return;
        }

        const sessionId = await wrapXmex(
            address,
            TokenTransfer.metaEsdtFromBigInteger(XMEX_TOKEN_ID, selectedTokenNonce, convertEsdtToWei(mintInputAmount).toFixed(0))
        );
        setMintSessionId(sessionId);
    };

    useEffect(() => {
        if (!mintSessionId || !pendingTransactions[mintSessionId]) return;
        (async () => {
            setMintSessionId('');
            const txHash = pendingTransactions[mintSessionId].transactions[0].hash;
            console.log('Wrap XMEX txHash', txHash);
            const wxmexPayment = await getWrapXmexResult(txHash);
            if (!wxmexPayment) {
                toastError('Wrapping XMEX failed');
                return;
            }
            console.log('wxmexPayment', wxmexPayment);
            await mintJwlxmex(address, TokenTransfer.metaEsdtFromBigInteger(wxmexPayment.token_identifier, wxmexPayment.token_nonce, wxmexPayment.amount));

            cleanMintAmount();
        })();
    }, [mintSessionId, pendingTransactions]);

    const handleStake = async () => {
        if (!exchangeFarmCommonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (stakeInputAmountError) {
            toastError(stakeInputAmountError);
            return;
        }

        await stakeJwlxmex(chainID, address, exchangeFarmCommonSettings.jwlxmex_token, convertEsdtToWei(stakeInputAmount).toFixed(0));

        cleanStakeAmount();
    };

    const handleUnstake = async () => {
        if (!exchangeFarmCommonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unstakeInputAmountError) {
            toastError(unstakeInputAmountError);
            return;
        }

        await unstakeJwlxmex(chainID, address, exchangeFarmCommonSettings.sjwlxmex_token, convertEsdtToWei(unstakeInputAmount).toFixed(0));

        cleanStakeAmount();
    };

    return (
        <div>
            <div className='d-flex mt-2'>
                <span>
                    {`Convert XMEX to JWLXMEX. By staking JWLXMEX, you're earning a share of the JewelSwap XExchange farm's energy-boosted earnings, when available. You may stake and unstake JWLXMEX tokens, but not convert them back to XMEX at JewelSwap. You will be able to swap JWLMEX-JWLXMEX at `}
                    <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                </span>
            </div>
            {
                exchangeFarmCommonSettings && exchangeFarmStats ? (
                    <>
                        <div className='row mt-4' style={{ justifyContent: "center" }}>
                            <Accordion
                                expanded={isMintExpanded}
                            >
                                <AccordionSummary
                                    className={`col-12`}
                                    sx={{ cursor: 'auto !important' }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                            <Dropdown className='stable-dropdown'>
                                                <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src={getTokenImage(XMEX_TOKEN_ID)} className='egld-image mx-2' alt={XMEX_TOKEN_ID}></img>
                                                        <div>{selectedTokenNonce > 0 ? selectedTokenId : 'Select Nonce'}</div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                {
                                                    walletNfts.length > 0 && (
                                                        <Dropdown.Menu>
                                                            {
                                                                walletNfts.map((nft, index: number) => (
                                                                    <Dropdown.Item className='d-flex align-items-center' key={index} onClick={() => handleSelectedTokenNonce(index, nft.nonce)}>
                                                                        <img src={getTokenImage(XMEX_TOKEN_ID)} alt={XMEX_TOKEN_ID}></img>
                                                                        <div className='token-id'>{`${XMEX_TOKEN_ID}-${numberToPaddedHex(nft.nonce)}`}</div>
                                                                    </Dropdown.Item>
                                                                ))
                                                            }
                                                        </Dropdown.Menu>
                                                    )
                                                }
                                            </Dropdown>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`TVL`}</div>
                                            <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(exchangeFarmStats.jwlxmex_supply))} JWLXMEX`}</div>
                                        </div>
                                        <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                        <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                            <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                <div className='d-flex align-items-center'>Your balance</div>
                                                <div className='text-end'>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance))} ${selectedTokenId}` : '-'}</div>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlxmexTokenBalance))} JWLXMEX` : '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-1 col-se-12'></div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                            <div
                                                className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                onClick={handleMintExpand}
                                            >
                                                <div className='d-flex'>Mint</div>
                                                {isMintExpanded ? (
                                                    <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                ) : (
                                                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails
                                    className='row'
                                    sx={{ padding: '0px 16px 16px !important' }}
                                >
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                <div className='d-flex'>
                                                    {`Avail Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance)) : '-'}`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                <StakeInput
                                                    userBalance={selectedTokenBalance}
                                                    inputAmount={mintInputAmount}
                                                    amountError={mintInputAmountError}
                                                    setInputAmount={setMintInputAmount}
                                                    setAmountError={setMintInputAmountError}
                                                    isUnstake={false}
                                                    tokenId={selectedTokenId}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                <div className='d-flex'>
                                                    <button className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleMint} disabled>
                                                        {`Mint`}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                <div className='d-flex'>
                                                    {`Avail JWLXMEX Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlxmexTokenBalance)) : '-'}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className='row mt-4' style={{ justifyContent: "center" }}>
                            <Accordion
                                expanded={isStakeExpanded}
                            >
                                <AccordionSummary
                                    className={`col-12`}
                                    sx={{ cursor: 'auto !important' }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                            <div className='d-flex align-items-center'>
                                                <img src={getTokenImage(exchangeFarmCommonSettings.jwlxmex_token)} className='egld-image mx-2' alt='ash'></img>
                                                <div>
                                                    <div>{`JWLXMEX`}</div>
                                                    <div style={{ fontSize: '0.8rem' }}>{`1 SJWLXMEX = ${BigNumber(exchangeFarmStats.sjwlxmex_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(BigNumber(exchangeFarmStats.jwlxmex_pool_reserve).dividedBy(exchangeFarmStats.sjwlxmex_pool_reserve), 5) : '-'} JWLXMEX`}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`TVL`}</div>
                                            <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(exchangeFarmStats.sjwlxmex_pool_reserve))} SJWLXMEX`}</div>
                                        </div>
                                        <div className={`col-lg-1 col-md-1 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`APY`}</div>
                                            <div className='avaliable-color'>{`${apy == '0' ? '-' : apy} %`}</div>
                                        </div>
                                        <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                            <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                <div className='d-flex align-items-center'>Your balance</div>
                                                <div className='text-end'>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlxmexTokenBalance))} JWLXMEX` : '-'}</div>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlxmexTokenBalance))} SJWLXMEX (= ${BigNumber(exchangeFarmStats.sjwlxmex_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(sjwlxmexTokenBalance).multipliedBy(exchangeFarmStats.jwlxmex_pool_reserve).dividedBy(exchangeFarmStats.sjwlxmex_pool_reserve))) : '0'} JWLXMEX)` : '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-1 col-se-12'></div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                            <div
                                                className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                onClick={handleStakeExpand}
                                            >
                                                <div className='d-flex'>Stake / Unstake</div>
                                                {isStakeExpanded ? (
                                                    <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                ) : (
                                                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails
                                    className='row'
                                    sx={{ padding: '0px 16px 16px !important' }}
                                >
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                <div className='d-flex'>
                                                    {`Avail JWLXMEX Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlxmexTokenBalance)) : '-'} JWLXMEX`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                <StakeInput
                                                    userBalance={jwlxmexTokenBalance}
                                                    inputAmount={stakeInputAmount}
                                                    amountError={stakeInputAmountError}
                                                    setInputAmount={setStakeInputAmount}
                                                    setAmountError={setStakeInputAmountError}
                                                    isUnstake={false}
                                                    tokenId={exchangeFarmCommonSettings.jwlxmex_token}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleStake}>
                                                        {`Stake`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                <div className='d-flex'>
                                                    {`Avail SJWLXMEX Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlxmexTokenBalance)) : '-'} SJWLXMEX`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                                <StakeInput
                                                    userBalance={sjwlxmexTokenBalance}
                                                    inputAmount={unstakeInputAmount}
                                                    amountError={unstakeInputAmountError}
                                                    setInputAmount={setUnstakeInputAmount}
                                                    setAmountError={setUnstakeInputAmountError}
                                                    isUnstake={true}
                                                    tokenId={exchangeFarmCommonSettings.sjwlxmex_token}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleUnstake}>
                                                        {`Unstake`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </>
                ) : (
                    <div className='mt-5'>
                        <Spinner />
                    </div>
                )}
        </div>
    );
};
