import axios from "axios";
import BigNumber from 'bignumber.js';
import { isDev } from "config";
import { parseBigNumber } from "z/utils";

export const XEXCHANGE_GRAPHQL_URL = isDev ? 'https://devnet-graph.xexchange.com/graphql' : 'https://graph.xexchange.com/graphql';

export interface XexchangePairInfo {
  address: string,
  firstTokenId: string,
  firstTokenDecimals: number,
  firstTokenPriceUSD: BigNumber,
  secondTokenId: string,
  secondTokenDecimals: number,
  secondTokenPriceUSD: BigNumber,
  lpTokenId: string,
  lpTokenDecimals: number,
  lpTokenPriceUSD: BigNumber,
  feesAPR: number,
}

export async function getXexchangePairInfo(pairAddress: string): Promise<XexchangePairInfo | undefined> {
  try {
    const url = XEXCHANGE_GRAPHQL_URL;
    const params = {
      "operationName":null,
      "variables":{},
      "query": `{\n  pairs(address: \"${pairAddress}\") {\n    address\n    firstToken {\n      identifier\n      decimals\n    }\n    firstTokenPriceUSD\n    secondToken {\n      identifier\n      decimals\n    }\n    secondTokenPriceUSD\n    liquidityPoolToken {\n      identifier\n      decimals\n    }\n    liquidityPoolTokenPriceUSD\n feesAPR\n }\n}\n`
    };
    const { data } = await axios.post(url, params);
    if (data.data && data.data.pairs && data.data.pairs.length > 0) {
      const pair = data.data.pairs[0];
      return {
        address: pair.address,
        firstTokenId: pair.firstToken.identifier,
        firstTokenDecimals: pair.firstToken.decimals,
        firstTokenPriceUSD: parseBigNumber(pair.firstTokenPriceUSD),
        secondTokenId: pair.secondToken.identifier,
        secondTokenDecimals: pair.secondToken.decimals,
        secondTokenPriceUSD: parseBigNumber(pair.secondTokenPriceUSD),
        lpTokenId: pair.liquidityPoolToken.identifier,
        lpTokenDecimals: pair.liquidityPoolToken.decimals,
        lpTokenPriceUSD: parseBigNumber(pair.liquidityPoolTokenPriceUSD),
        feesAPR: pair.feesAPR * 100,
      };
    } else {
      throw new Error('No pair info');
    }
  } catch (err) {
    console.log('getXexchangePairInfo:', err);
  }

  return undefined;
}

export interface XexchangeFarmInfo {
  address: string,
  baseApr: number,
  maxBoostedApr: number,
  farmTokenPriceUSD: BigNumber,
  farmTokenSupply: BigNumber,
  farmTokenDecimals: number,

  totalApr: number,
}
export async function getXexchangeFarmsInfo(): Promise<XexchangeFarmInfo[]> {
  try {
    const url = XEXCHANGE_GRAPHQL_URL;
    const params = {
      "operationName": null,
      "variables":{},
      "query":"{\n  farms {\n    ... on FarmModelV2 {\n      address\n      perBlockRewards\n      boostedYieldsRewardsPercenatage\n      boostedYieldsFactors {\n        maxRewardsFactor\n      }\n      farmTokenPriceUSD\n      farmTokenSupply\n      farmToken {\n        decimals\n      }\n      baseApr\n      farmingToken {\n        identifier\n      }\n    }\n  }\n}\n"
    };
    const { data } = await axios.post(url, params);
    if (data.data && data.data.farms && data.data.farms.length > 0) {
      const farmInfos = [];
      for (const farm of data.data.farms) {
        const address = farm.address;
        if (!address) continue; // empty farm data
        const boostedYieldsRewardsPercenatage = farm.boostedYieldsRewardsPercenatage / 100;
        const maxRewardsFactor = Number(farm.boostedYieldsFactors.maxRewardsFactor);
        const farmTokenPriceUSD = parseBigNumber(farm.farmTokenPriceUSD);
        const farmTokenSupply = parseBigNumber(farm.farmTokenSupply);
        const farmTokenDecimals = farm.farmToken.decimals;
        const baseApr = Number(farm.baseApr) * 100;
        const baseYieldsRewardsPercenatage = 40; // default 40%
        const maxBoostedApr = baseApr + (baseApr * maxRewardsFactor * boostedYieldsRewardsPercenatage) / baseYieldsRewardsPercenatage;  // a weird formula :(

        farmInfos.push({
          address,
          baseApr,
          maxBoostedApr,

          farmTokenPriceUSD,
          farmTokenSupply,
          farmTokenDecimals,

          totalApr: maxBoostedApr,
        });
      }
      return farmInfos;
    } else {
      throw new Error('No farm info');
    }
  } catch (err) {
    console.log('getXexchangeFarmsInfo:', err);
  }

  return [];
}
