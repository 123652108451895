import React, { useEffect, useState, useContext } from 'react';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccount, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { animated, useSpring } from 'react-spring';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, InputAdornment, InputBase, Modal, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { isMobile } from 'react-device-detect';
import { Position } from './Position';
import { EsdtPool } from './EsdtPool';
import { queryJewelMoneyMarketCommonContext, queryJewelBorrowPools, queryJewelBorrowUserPools, sendJewelMoneyMarketSupply, sendJewelMoneyMarketWithdraw, sendJewelMoneyMarketBorrow, sendJewelMoneyMarketRepay, queryViewJewelLendContext, queryHatomFarmCommonContext, queryHatomViewFarms, queryLiquidStakStatsContext, queryJewelStableStatsContext, queryJewelBorrowGetEquivalent } from 'z/elrond';
import BigNumber from 'bignumber.js';
import { AshswapFarm, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelLendPool, JewelMoneyMarketCommonContext, JewelMoneyMarketPoolContext, JewelMoneyMarketUserPoolContext } from 'z/types';
import { useNavigate, useParams } from 'react-router-dom';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_EXCEED_MAX_VALUE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getJewelLendPoolApy, getLiquidStakeApy, getMoneyMarketType, getTokenDecimal, getTokenImage, getTokenTicker, hatomFormatRewards, parseBigNumber, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import { getAshswapFarms, getHatomControllerAddress, getHatomMoneyMarket, getHatomRewardsBatch, getHatomTokenPrices, getHTokenExchangeRate, getUserTokenBalance } from 'z/api';
import { Dropdown } from 'react-bootstrap';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import './index.scss';
import { contracts, isDev, JEWEL_BORROW_LIQUIDATION_TOKENS, JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP } from 'config';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const JewelMoneyMarket = () => {
  const {
    network: { apiAddress },
    chainID,
  } = useGetNetworkConfig();
  const { address, shard, balance } = useGetAccount();
  // const address = 'erd1lkwwr39hhgrtl4jhx680rkecy9e0mn32e7x7c0emcljzuhqv2k0q4nq4x6';
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  const { type } = useParams();
  const [mmType, setMmType] = useState<string>('');
  useEffect(() => {
    if (type) {
      setMmType(type);
    } else {
      setMmType('jewel');
    }
  }, [type]);

  const navigate = useNavigate();
  const handleRouter = (value: string) => {
    navigate(`/money-market/${value}`);
  };

  const [isSuppliedExpanded, setIsSuppliedExpanded] = useState<boolean>(false);
  const [isBorrowedExpanded, setIsBorrowedExpanded] = useState<boolean>(false);

  const [showSupplyModal, setShowSupplyModal] = useState<boolean>(false);
  const [isSupply, setIsSupply] = useState<boolean>(false);
  const [showBorrowModal, setShowBorrowModal] = useState<boolean>(false);
  const [isBorrow, setIsBorrow] = useState<boolean>(false);
  const [isHideAssets, setIsHideAssets] = useState<boolean>(false);

  const [userTokens, setUserTokens] = useState<any>();
  const [jewelMoneyMarketPools, setJewelMoneyMarketPools] = useState<JewelMoneyMarketPoolContext[]>([]);
  const [jewelMoneyMarketUserPools, setJewelMoneyMarketUserPools] = useState<JewelMoneyMarketUserPoolContext[]>([]);
  const [userSuppliedPositions, setUserSuppliedPositions] = useState<JewelMoneyMarketUserPoolContext[]>([]);
  const [userBorrowedPositions, setUserBorrowedPositions] = useState<JewelMoneyMarketUserPoolContext[]>([]);

  const [ashswapFarms, setAshswapFarms] = useState<AshswapFarm[]>([]);
  useEffect(() => {
    if (isDev) return;

    (async () => {
      const farms = await getAshswapFarms();
      // console.log('ashswap-farms: ', data);
      setAshswapFarms(farms);
    })();
  }, []);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const pools = await queryJewelBorrowPools();
      // console.log('borrow pools: ', pools);
      setJewelMoneyMarketPools(pools);

      const userTokensBalance: any = [];
      const tokenIds: string[] = [];
      for (const pool of pools) {
        if (!tokenIds.includes(pool.base_token_id)) {
          tokenIds.push(pool.base_token_id);
        }
        if (!tokenIds.includes(pool.debt_token_id)) {
          tokenIds.push(pool.debt_token_id);
        }
        if (pool.hatom_base_token_id && !tokenIds.includes(pool.hatom_base_token_id)) {
          if (pool.hatom_base_token_id !== 'EGLD') {
            tokenIds.push(pool.hatom_base_token_id);
          }
        }
      }

      if (isLoggedIn) {
        const balances = await getUserTokenBalance(apiAddress, address, tokenIds);
        for (const item of balances) {
          userTokensBalance[item.identifier] = {
            balance: item.balance,
          };
        }
      }

      for (const item of tokenIds) {
        if (!userTokensBalance[item]) {
          userTokensBalance[item] = {
            balance: '0',
          };
        }
      }

      userTokensBalance['EGLD'] = {
        balance: balance,
      };

      // console.log('userTokensBalance: ', userTokensBalance);
      setUserTokens(userTokensBalance);
    })();
  }, [isLoggedIn, hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn) return;

    (async () => {
      const user_pools = await queryJewelBorrowUserPools(address);
      // console.log('user borrow pools: ', user_pools);
      setJewelMoneyMarketUserPools(user_pools);

      const supplied_positions: JewelMoneyMarketUserPoolContext[] = [];
      const borrowed_positions: JewelMoneyMarketUserPoolContext[] = [];
      for (const pool of user_pools) {
        if (new BigNumber(pool.user_supplied_amount).gt('0')) {
          supplied_positions.push(pool);
        }
        if (new BigNumber(pool.user_borrowed_amount).gt('0')) {
          borrowed_positions.push(pool);
        }
      }
      setUserBorrowedPositions(borrowed_positions);

      const supplied_filtered_positions: JewelMoneyMarketUserPoolContext[] = [];
      if (isHideAssets) {
        for (const position of supplied_positions) {
          if (BigNumber(position.user_supplied_amount).gt(100)) {
            supplied_filtered_positions.push(position);
          }
        }
        setUserSuppliedPositions(supplied_filtered_positions);
      } else {
        setUserSuppliedPositions(supplied_positions);
      }
    })();
  }, [hasPendingTransactions, isHideAssets]);

  const onChangeHide = (e: any) => {
    setIsHideAssets(e.target.checked);
  };

  const [liquidStakeApy, setLiquidStakeApy] = useState<string>('-');
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _liquidStakeStatsContext = await queryLiquidStakStatsContext();

      if (_liquidStakeStatsContext) {

        const _apy = getLiquidStakeApy(_liquidStakeStatsContext.jwlegld_pool_reserve, _liquidStakeStatsContext.sjwlegld_pool_reserve);
        setLiquidStakeApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
      }
    })();
  }, [hasPendingTransactions]);

  const [jewelLendPools, setJewelLendPools] = useState<JewelLendPool[]>([]);
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const lend_context = await queryViewJewelLendContext(apiAddress);
      if (lend_context) {
        const lend_pools: JewelLendPool[] = [];
        for (let i = 0; i < lend_context.lendPool.length; i++) {
          lend_pools.push({
            ...lend_context.lendPool[i],
            apy: convertBigNumberValueToLocalString(getJewelLendPoolApy(lend_context.lendPool[i]))
          });
        }
        // console.log('lend_pools: ', lend_pools);
        setJewelLendPools(lend_pools);
      }
    })();
  }, [hasPendingTransactions]);

  const [hatomMoneyMarkets, setHatomMoneyMarkets] = useState<JewelHatomMoneyMarket[]>([]);
  const [hatomFormattedRewards, setHatomFormattedRewards] = useState<any[]>([]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      // reward apy
      const [
        controllerAddress,
        rewardsBatch,
        tokenPricesMap,
        hTokenExchangeRateMap,
      ] = await Promise.all([
        getHatomControllerAddress(),
        getHatomRewardsBatch(),
        getHatomTokenPrices(),
        getHTokenExchangeRate(),
      ]);

      if (!controllerAddress) return;
      const tokenIds: string[] = [];
      tokenIds.push(...rewardsBatch.map(({ moneyMarket }: any) => moneyMarket.hToken.id));
      const accountTokens = await getUserTokenBalance(
        apiAddress,
        controllerAddress,
        tokenIds
      );
      const hTokensIdsMap: any = accountTokens.reduce(
        (prev, { name, balance }) => ({
          ...prev,
          [`${name.replace("Hatom", "H")}-`]: balance,
        }),
        {}
      );
      const wrappedIdsMap: any = accountTokens.reduce(
        (prev, { name, balance }) => ({
          ...prev,
          [`${name.replace("Wrapped", "")}-`]: balance,
        }),
        {}
      );

      const totalCollateralTokensMap = tokenIds.reduce((prev, currentIdenfitier) => {
        const balanceByIdentifier = accountTokens.find(
          ({ identifier }) => identifier === currentIdenfitier
        )?.balance;
        const balanceByAsset = accountTokens.find(({ assets }) =>
          assets?.pngUrl?.includes(currentIdenfitier)
        )?.balance;
        const balanceByHToken: any =
          hTokensIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

        const balanceByWrapped: any =
          wrappedIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

        return {
          ...prev,
          [currentIdenfitier]:
            balanceByIdentifier ||
            balanceByAsset ||
            balanceByHToken ||
            balanceByWrapped ||
            "0",
        };
      }, {});

      const formattedRewards = hatomFormatRewards({
        rewardsBatch,
        tokenPricesMap,
        hTokenExchangeRateMap,
        totalCollateralTokensMap,
      });
      setHatomFormattedRewards(formattedRewards);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      // supply, borrow apy
      const _hatomMoneyMarket = await getHatomMoneyMarket();
      // console.log('_hatomMoneyMarket: ', _hatomMoneyMarket);
      if (_hatomMoneyMarket) {
        setHatomMoneyMarkets(_hatomMoneyMarket.data.queryMoneyMarket);
      }
    })();
  }, [hasPendingTransactions]);

  const [stableStakeApy, setStableStakeApy] = useState<string>('-');
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _statsContext = await queryJewelStableStatsContext();
      if (_statsContext) {
        if (BigNumber(_statsContext.sjwlusd_pool_reserve).gt('0')) {
          const _tokenAmount = convertEsdtToWei(1).multipliedBy(_statsContext.jwlusd_pool_reserve).dividedBy(_statsContext.sjwlusd_pool_reserve);
          const _passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
          const _apy = _tokenAmount.minus(convertEsdtToWei(1)).dividedBy(_passedDays).multipliedBy(365).multipliedBy(100);
          setStableStakeApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
        }
      }
    })();
  }, [hasPendingTransactions]);

  const [selectedMmPool, setSelectedMmPool] = useState<JewelMoneyMarketPoolContext>();
  const [selectedTokenId, setSelectedTokenId] = useState<string>('');
  const [avaliableAmount, setAvaliableAmount] = useState<string>('');
  const [userBorrowedAmount, setUserBorrowedAmount] = useState<string>('');
  const [amount, setAmount] = useState<string>('0');
  const [amountError, setAmountError] = useState<string>('');

  const handleOpenSupplyModal = async (is_supply: boolean, mm_pool: JewelMoneyMarketPoolContext, selected_token_id: string, equivalentAmount = '0') => {
    // if (mm_pool.borrow_pool_type == 'Idle') return;
    setIsSupply(is_supply);
    if (is_supply) {
      // base token amount of wallet
      let userBalance = userTokens && userTokens[selected_token_id] ? userTokens[selected_token_id].balance : '0';
      if (getTokenTicker(selected_token_id) === 'EGLD') {
        if (BigNumber(userBalance).gt(EXTRA_GAS_FEE_AMOUNT)) {
          userBalance = BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed(0);
        } else {
          userBalance = '0';
        }
      }
      setAvaliableAmount(userBalance);
    } else {
      // user withdrawble amount
      for (let i = 0; i < jewelMoneyMarketUserPools.length; i++) {
        if (jewelMoneyMarketUserPools[i].base_token_id === mm_pool.base_token_id && jewelMoneyMarketUserPools[i].debt_token_id === mm_pool.debt_token_id) {
          let user_supplied_amount = jewelMoneyMarketUserPools[i].user_supplied_amount;
          if (JEWEL_BORROW_LIQUIDATION_TOKENS.includes(jewelMoneyMarketUserPools[i].base_token_id)) {
            user_supplied_amount = convertWeiToEsdt(BigNumber(user_supplied_amount).multipliedBy(equivalentAmount), getTokenDecimal(jewelMoneyMarketUserPools[i].base_token_id)).toFixed(0);
          }

          const user_debt_amount = jewelMoneyMarketUserPools[i].user_debt_amount;
          const user_max_borrow_amount = jewelMoneyMarketUserPools[i].user_max_borrow_amount;
          let min_supply_amount = BigNumber('0');
          if (BigNumber(user_max_borrow_amount).gt('0')) {
            min_supply_amount = BigNumber(user_supplied_amount).multipliedBy(user_debt_amount).dividedBy(user_max_borrow_amount);
            if (JEWEL_BORROW_LIQUIDATION_TOKENS.includes(jewelMoneyMarketUserPools[i].base_token_id)) {
              min_supply_amount = convertEsdtToWei(BigNumber(min_supply_amount).dividedBy(equivalentAmount).multipliedBy(1.005), getTokenDecimal(jewelMoneyMarketUserPools[i].base_token_id));
            }
          }
          user_supplied_amount = jewelMoneyMarketUserPools[i].user_supplied_amount;

          if (min_supply_amount.gte(user_supplied_amount)) {
            setAvaliableAmount('0');
          } else {
            setAvaliableAmount(BigNumber(user_supplied_amount).minus(min_supply_amount).toFixed(0));
          }
          break;
        }
      }
    }

    setSelectedMmPool(mm_pool);
    setSelectedTokenId(selected_token_id);
    setAmount('0');
    setAmountError('');

    setShowSupplyModal(true);
  };

  const handleOpenBorrowModal = (is_borrow: boolean, mm_pool: JewelMoneyMarketPoolContext) => {
    // if (mm_pool.borrow_pool_type == 'Idle') return;
    setIsBorrow(is_borrow);
    if (is_borrow) {
      // avail borrow amount
      for (let i = 0; i < jewelMoneyMarketUserPools.length; i++) {
        if (jewelMoneyMarketUserPools[i].base_token_id === mm_pool.base_token_id && jewelMoneyMarketUserPools[i].debt_token_id === mm_pool.debt_token_id) {
          let availBorrowAmount = BigNumber(jewelMoneyMarketUserPools[i].user_max_borrow_amount).minus(jewelMoneyMarketUserPools[i].user_debt_amount);
          if (JEWEL_BORROW_LIQUIDATION_TOKENS.includes(jewelMoneyMarketUserPools[i].base_token_id)) {
            const marginAmount = availBorrowAmount.multipliedBy(0.0025);
            availBorrowAmount = availBorrowAmount.minus(marginAmount);
          }
          setAvaliableAmount(availBorrowAmount.toFixed(0));
          break;
        }
      }
    } else {
      // debt token amount of wallet
      const userBalance = userTokens && userTokens[mm_pool.debt_token_id] ? userTokens[mm_pool.debt_token_id].balance : '0';
      // setAvaliableAmount(userBalance);
      for (let i = 0; i < jewelMoneyMarketUserPools.length; i++) {
        if (jewelMoneyMarketUserPools[i].base_token_id === mm_pool.base_token_id && jewelMoneyMarketUserPools[i].debt_token_id === mm_pool.debt_token_id) {
          const borrowedAmount = BigNumber(jewelMoneyMarketUserPools[i].user_debt_amount).plus(BigNumber(jewelMoneyMarketUserPools[i].user_debt_amount).multipliedBy(0.005)).toFixed(0);
          setUserBorrowedAmount(borrowedAmount);
          if (BigNumber(userBalance).gte(borrowedAmount)) {
            setAvaliableAmount(borrowedAmount);
          } else {
            setAvaliableAmount(userBalance);
          }
          break;
        }
      }
    }

    setSelectedMmPool(mm_pool);
    setAmount('0');
    setAmountError('');

    setShowBorrowModal(true);
  };

  const handleSelectedTokenId = async (value: string) => {
    if (!selectedMmPool) return '';

    handleOpenSupplyModal(isSupply, selectedMmPool, value);
  };

  const handleMax = () => {
    // let amount = '0';
    // let userBalance = avaliableAmount;
    // if (getTokenTicker(baseTokenId) === 'EGLD') {
    //   if (BigNumber(userBalance).gt(EXTRA_GAS_FEE_AMOUNT)) {
    //     userBalance = BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed(0);
    //   } else {
    //     userBalance = '0';
    //   }
    // }
    // const amount = convertWeiToEsdt(avaliableAmount, getTokenDecimal(baseTokenId)).toFixed();
    onChangeAmount(avaliableAmount);
  };

  const checkAmount = (value: string) => {
    if (!selectedMmPool) return '';

    const bValue = parseBigNumber(value);
    let error = '';
    if (bValue == undefined) {
      error = 'Invalid number';
    } else if (bValue.comparedTo(0) < 0) {
      error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
    } else {
      if (bValue.comparedTo(avaliableAmount) > 0) {
        error = ERROR_EXCEED_MAX_VALUE;
      }
      if (getTokenTicker(selectedTokenId) === 'EGLD') {
        if (bValue.gt(BigNumber(avaliableAmount).minus(EXTRA_GAS_FEE_AMOUNT))) {
          error = ERROR_EXCEED_MAX_VALUE;
        }
      }
    }

    return error;
  };

  const onChangeAmount = (value: string) => {
    setAmount(value);
    const error = checkAmount(value);
    setAmountError(error);
  };

  const handleSupply = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    let wrapAddress = contracts.Wrap0.address;
    if (shard === 1) {
      wrapAddress = contracts.Wrap1.address;
    } else if (shard === 2) {
      wrapAddress = contracts.Wrap2.address;
    }

    await sendJewelMoneyMarketSupply(chainID, address, selectedTokenId, selectedMmPool.base_token_id, amount, selectedMmPool.debt_token_id, selectedMmPool.hatom_base_token_id === selectedTokenId ? true : false, wrapAddress);

    setShowSupplyModal(false);
  };

  const handleWithdraw = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    await sendJewelMoneyMarketWithdraw(chainID, address, selectedMmPool.base_token_id, selectedMmPool.debt_token_id, amount, selectedMmPool.hatom_base_token_id === selectedTokenId ? true : false);

    setShowSupplyModal(false);
  };

  const handleBorrow = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    let isExceedMaxDebtCap = false;
    for (let i = 0; i < jewelMoneyMarketPools.length; i++) {
      if (jewelMoneyMarketPools[i].base_token_id === selectedMmPool.base_token_id && jewelMoneyMarketPools[i].debt_token_id === selectedMmPool.debt_token_id) {
        const newBorrowAmount = BigNumber(jewelMoneyMarketPools[i].borrowed_amount).plus(amount);
        if (newBorrowAmount.gt(jewelMoneyMarketPools[i].max_debt_cap)) {
          isExceedMaxDebtCap = true;
        }

        break;
      }
    }
    if (isExceedMaxDebtCap) {
      toastError('Cannot borrow more than max cap');
      return;
    }

    await sendJewelMoneyMarketBorrow(chainID, address, selectedMmPool.base_token_id, selectedMmPool.debt_token_id, amount);

    setShowBorrowModal(false);
  };

  const handleRepay = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    await sendJewelMoneyMarketRepay(chainID, address, selectedMmPool.debt_token_id, amount, selectedMmPool.base_token_id);

    setShowBorrowModal(false);
  };

  return (
    <>
      <div className='container' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div style={{ fontSize: isMobile ? '24px' : '28px' }}>
              <span>Money Market (Isolated Pool)</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='d-flex mt-3 mb-4 align-items-center w-100 overflow-auto' style={{ height: '40px' }}>
            <div className='mx-2 h-100 d-flex align-items-center' style={{ fontSize: '17px' }}>Type:</div>
            <div className='d-flex ml-3'>
              <div className={`mx-2 farm-dex-tab text-nowrap ${mmType === 'jewel' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('jewel')}>Jewel</div>
              <div className={`mx-2 farm-dex-tab text-nowrap ${mmType === 'hatom' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('hatom')}>Hatom</div>
              <div className={`mx-2 farm-dex-tab text-nowrap ${mmType === 'ashswap' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('ashswap')}>Ashswap LPs</div>
            </div>
          </div>
          <div className='user-positions container'>
            <div className='row mt-4' style={{ justifyContent: "center" }}>
              <Accordion
                expanded={isSuppliedExpanded}
              >
                <AccordionSummary
                  sx={{ cursor: 'auto !important' }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6 col-sm-12 user-positions-title'>My Supplied Positions</div>
                      <div className={`col-lg-7 col-md-5 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-3' : 'justify-content-end'}`}>
                        <div className='d-flex'>
                          <Checkbox
                            checked={isHideAssets}
                            onChange={e => onChangeHide(e)}
                            sx={{ '&': { padding: '0px', marginRight: '3px' } }}
                          />
                          <div>{`Hide assets < 1 USD`}</div>
                        </div>
                      </div>
                      <div className={`col-lg-1 col-md-1 col-sm-12 d-flex justify-content-end align-items-center ${isMobile ? 'mt-3 justify-content-center' : ''}`}>
                        {isSuppliedExpanded ? (
                          <KeyboardArrowUpIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsSuppliedExpanded(!isSuppliedExpanded)} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsSuppliedExpanded(!isSuppliedExpanded)} />
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: '0px 16px 16px !important' }}
                >
                  {
                    !isMobile && (
                      <div className='row mt-1 mb-2'>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Assets</div>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Supplied</div>
                        </div>
                        <div className='col-lg-1 com-md-1 col-sm-1 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>APY</div>
                        </div>
                        <div className='col-lg-2 com-md-2 col-sm-2 d-flex justify-content-center'>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3'></div>
                      </div>
                    )
                  }
                  {
                    userSuppliedPositions.map((item: JewelMoneyMarketUserPoolContext, index: number) => (
                      getMoneyMarketType(item.base_token_id) === mmType && (
                        <Position
                          key={index}
                          pool={item}
                          handleOpenModal={handleOpenSupplyModal}
                          isSupplied={true}
                          jewelMoneyMarketPools={jewelMoneyMarketPools}
                          jewelLendPools={jewelLendPools}
                          hatomMoneyMarkets={hatomMoneyMarkets}
                          hatomFormattedRewards={hatomFormattedRewards}
                          liquidStakeApy={liquidStakeApy}
                          stableStakeApy={stableStakeApy}
                          ashswapFarms={ashswapFarms}
                        />
                      )
                    ))
                  }
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='user-positions container'>
            <div className='row mt-4' style={{ justifyContent: "center" }}>
              <Accordion
                expanded={isBorrowedExpanded}
              >
                <AccordionSummary
                  sx={{ cursor: 'auto !important' }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6 col-sm-12 user-positions-title'>My Borrowed Positions</div>
                      <div className={`col-lg-7 col-md-5 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-3' : 'justify-content-end'}`}>
                        {/* <div className='d-flex align-items-center positions-border' style={{ marginRight: '10px' }}>
                          <div className='text-gray-color'>Borrowed</div>
                          <div>$10</div>
                        </div>
                        <div className='d-flex align-items-center positions-border'>
                          <div className='text-gray-color'>Health</div>
                          <div>2.5</div>
                        </div> */}
                      </div>
                      <div className={`col-lg-1 col-md-1 col-sm-12 d-flex justify-content-end align-items-center ${isMobile ? 'mt-3 justify-content-center' : ''}`}>
                        {isBorrowedExpanded ? (
                          <KeyboardArrowUpIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsBorrowedExpanded(!isBorrowedExpanded)} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsBorrowedExpanded(!isBorrowedExpanded)} />
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: '0px 16px 16px !important' }}
                >
                  {
                    !isMobile && (
                      <div className='row mt-1 mb-2'>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Assets</div>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Borrowed (Incl. interests)</div>
                        </div>
                        <div className='col-lg-1 com-md-1 col-sm-1 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>APY</div>
                        </div>
                        <div className='col-lg-2 com-md-2 col-sm-2 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Health Factor</div>
                          <Tooltip
                            placement="top"
                            title="The health factor is calculated from user's collateral balance multiplied by current liquidation threshold for the user specific asset, divided by 100, divided by user's borrow balance/fees. Positions with health factor <1 will trigger liquidation."
                          >
                            <InfoCircleOutlined style={{ fontSize: '14px', cursor: 'pointer', marginTop: '2px' }} />
                          </Tooltip>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3'></div>
                      </div>
                    )
                  }
                  {
                    userBorrowedPositions.map((item: JewelMoneyMarketUserPoolContext, index: number) => (
                      getMoneyMarketType(item.base_token_id) === mmType && (
                        <Position
                          key={index}
                          pool={item}
                          handleOpenModal={handleOpenBorrowModal}
                          isSupplied={false}
                          jewelMoneyMarketPools={jewelMoneyMarketPools}
                          jewelLendPools={jewelLendPools}
                          hatomMoneyMarkets={hatomMoneyMarkets}
                          hatomFormattedRewards={hatomFormattedRewards}
                          liquidStakeApy={liquidStakeApy}
                          stableStakeApy={stableStakeApy}
                          ashswapFarms={ashswapFarms}
                        />
                      )
                    ))
                  }
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='mt-5'>
            <div className='mx-2' style={{ fontSize: '20px' }}>{mmType === 'ashswap' ? 'Ashswap LP Tokens' : 'ESDT Tokens'}</div>
            <div className='mt-1 mb-5 mx-2'>
              {
                mmType === 'ashswap' ? 'The Supply APY reflects the existing Jewelswap farming APY for the respective LPs. There is no additional supply APY.' : mmType === 'hatom' ? 'The Supply APY reflects the existing Hatom Supply APY + Rewards APY (autocompounded by Jewelswap) + Booster APY (Boosted by Jewelswap Hatom staked). *Booster Rewards is in effect but currently not unavailable to be displayed in UI.' : 'The Supply APY reflects the existing Jewelswap staking APY and lending pools APY for the respective tokens. There is no additional supply APY apart from the above.'
              }
            </div>
            {
              !isMobile && (
                <div className='row justify-content-center align-items-center'>
                  <div className='col-lg-3 col-md-3 col-sm-3 text-center'>
                    <div>Pool</div>
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center'>
                    <div className='d-flex'>
                      <div className='text-center'>Supply APY</div>
                    </div>
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center'>
                    <div className='d-flex'>
                      <div className='text-center'>Borrow APY</div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-3 col-sm-3 d-flex'>
                    <div className='col-lg-6 col-md-6 col-sm-6 text-center'>
                      <div className='text-center'>Total Supply</div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 text-center'>
                      <div className='text-center'>Total Borrowed</div>
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2 text-end'>
                    <div>Your Balance</div>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2'></div>
                </div>
              )
            }
            {
              userTokens && jewelMoneyMarketPools.map((item: JewelMoneyMarketPoolContext, index: number) => (
                getMoneyMarketType(item.base_token_id) === mmType && (
                  <EsdtPool
                    key={index}
                    pool={item}
                    userTokens={userTokens}
                    handleOpenSupplyModal={handleOpenSupplyModal}
                    handleOpenBorrowModal={handleOpenBorrowModal}
                    jewelLendPools={jewelLendPools}
                    hatomMoneyMarkets={hatomMoneyMarkets}
                    hatomFormattedRewards={hatomFormattedRewards}
                    liquidStakeApy={liquidStakeApy}
                    stableStakeApy={stableStakeApy}
                    mmType={mmType}
                    ashswapFarms={ashswapFarms}
                  />
                )
              ))
            }
          </div>
        </div>
      </div>
      {
        selectedMmPool && (
          <>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showSupplyModal}
              onClose={() => setShowSupplyModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showSupplyModal}>
                {
                  isSupply ? (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      {
                        getMoneyMarketType(selectedMmPool.base_token_id) === 'hatom' ? (
                          <>
                            <div className='d-flex align-items-center'>
                              <div style={{ fontSize: '1.2rem' }}>{`Supply `}</div>
                              <Dropdown className='stable-dropdown'>
                                <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                                  <div className='d-flex align-items-center'>
                                    <img src={getTokenImage(selectedTokenId)} className='egld-image mx-2' alt={selectedTokenId}></img>
                                    <div>{getTokenTicker(selectedTokenId)}</div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item className='d-flex align-items-center' onClick={() => handleSelectedTokenId(selectedMmPool.base_token_id)}>
                                    <img src={getTokenImage(selectedMmPool.base_token_id)} alt={selectedMmPool.base_token_id}></img>
                                    <div className='token-id'>{getTokenTicker(selectedMmPool.base_token_id)}</div>
                                  </Dropdown.Item>
                                  {
                                    selectedMmPool.hatom_base_token_id && (
                                      <Dropdown.Item className='d-flex align-items-center' onClick={() => handleSelectedTokenId(selectedMmPool.hatom_base_token_id)}>
                                        <img src={getTokenImage(selectedMmPool.hatom_base_token_id)} alt={selectedMmPool.hatom_base_token_id}></img>
                                        <div className='token-id'>{getTokenTicker(selectedMmPool.hatom_base_token_id)}</div>
                                      </Dropdown.Item>
                                    )
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        ) : (
                          <div className='d-flex align-items-center'>
                            <div style={{ fontSize: '1.2rem' }}>{`Supply ${getTokenTicker(selectedMmPool.base_token_id)}`}</div>
                          </div>
                        )
                      }
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedTokenId)))} ${getTokenTicker(selectedTokenId)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedTokenId), getTokenDecimal(selectedTokenId)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedTokenId)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleSupply}>
                          Supply
                        </div>
                      </div>
                    </Box>
                  ) : (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      {
                        getMoneyMarketType(selectedMmPool.base_token_id) === 'hatom' ? (
                          <>
                            <div className='d-flex align-items-center'>
                              <div style={{ fontSize: '1.2rem' }}>{`Withdraw`}</div>
                              <Dropdown className='stable-dropdown'>
                                <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                                  <div className='d-flex align-items-center'>
                                    <img src={getTokenImage(selectedTokenId)} className='egld-image mx-2' alt={selectedTokenId}></img>
                                    <div>{getTokenTicker(selectedTokenId)}</div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item className='d-flex align-items-center' onClick={() => handleSelectedTokenId(selectedMmPool.base_token_id)}>
                                    <img src={getTokenImage(selectedMmPool.base_token_id)} alt={selectedMmPool.base_token_id}></img>
                                    <div className='token-id'>{getTokenTicker(selectedMmPool.base_token_id)}</div>
                                  </Dropdown.Item>
                                  {
                                    selectedMmPool.hatom_base_token_id && (
                                      <Dropdown.Item className='d-flex align-items-center' onClick={() => handleSelectedTokenId(selectedMmPool.hatom_base_token_id)}>
                                        <img src={getTokenImage(selectedMmPool.hatom_base_token_id)} alt={selectedMmPool.hatom_base_token_id}></img>
                                        <div className='token-id'>{getTokenTicker(selectedMmPool.hatom_base_token_id)}</div>
                                      </Dropdown.Item>
                                    )
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        ) : (
                          <div className='d-flex align-items-center'>
                            <div style={{ fontSize: '1.2rem' }}>{`Withdraw ${getTokenTicker(selectedMmPool.base_token_id)}`}</div>
                          </div>
                        )
                      }
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedMmPool.base_token_id)))} ${getTokenTicker(selectedMmPool.base_token_id)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.base_token_id), getTokenDecimal(selectedMmPool.base_token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.base_token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleWithdraw}>
                          Withdraw
                        </div>
                      </div>
                    </Box>
                  )
                }
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showBorrowModal}
              onClose={() => setShowBorrowModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showBorrowModal}>
                {
                  isBorrow ? (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Borrow ${getTokenTicker(selectedMmPool.debt_token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedMmPool.debt_token_id)))} ${getTokenTicker(selectedMmPool.debt_token_id)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.debt_token_id), getTokenDecimal(selectedMmPool.debt_token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.debt_token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleBorrow}>
                          Borrow
                        </div>
                      </div>
                    </Box>
                  ) : (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Repay ${getTokenTicker(selectedMmPool.debt_token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. balance in Wallet: ${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens && userTokens[selectedMmPool.debt_token_id] ? userTokens[selectedMmPool.debt_token_id].balance : '0', getTokenDecimal(selectedMmPool.debt_token_id)))} ${getTokenTicker(selectedMmPool.debt_token_id)}`}
                      </div>
                      <div className='d-flex justify-content-start' style={{ color: 'gray' }}>
                        {`Position Debt: ${convertBigNumberValueToLocalString(convertWeiToEsdt(userBorrowedAmount, getTokenDecimal(selectedMmPool.debt_token_id)))} ${getTokenTicker(selectedMmPool.debt_token_id)}`}
                      </div>
                      {
                        userTokens && userTokens[selectedMmPool.debt_token_id] && BigNumber(userBorrowedAmount).gt(userTokens[selectedMmPool.debt_token_id].balance) && (
                          <div className='d-flex justify-content-start my-1' style={{ color: 'red' }}>
                            {`You are short of ${convertWeiToEsdt(BigNumber(userBorrowedAmount).minus(userTokens[selectedMmPool.debt_token_id].balance), getTokenDecimal(selectedMmPool.debt_token_id), getTokenDecimal(selectedMmPool.debt_token_id))} ${getTokenTicker(selectedMmPool.debt_token_id)} for full repayment.`}
                          </div>
                        )
                      }
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.debt_token_id), getTokenDecimal(selectedMmPool.debt_token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.debt_token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleRepay}>
                          Repay
                        </div>
                      </div>
                    </Box>
                  )
                }
              </Fade>
            </Modal>
          </>
        )
      }
    </>
  );
};
