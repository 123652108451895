import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal } from '@mui/material';
import { DataGrid, GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { animated, useSpring } from 'react-spring';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import imageQuestionMarkInCircle from 'assets/img/question-mark-in-circle.png';
import { getNftsByIdFromApi, getUserTokenBalance } from 'z/api';
import { queryCommonSettings, queryViewJewelFarmBaseContext, queryViewJewelFarmBaseContextData, queryViewJewelFarms, queryViewLoans, queryViewLoansById, queryViewUser, sendRedeemLoan, sendRenewLoans, sendTopUp } from 'z/elrond';
import { CommonSettingsType, JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData, LendUserType, LoanStatusEnum, LoanType, NftWithIndexType } from 'z/types';
import { ASH_LP_DECIMALS, convertBigNumberValueToLocalString, convertEsdtToWei, convertSecondsToPrintDateTime, convertSecondsToPrintDateTimeString, convertSecondsToPrintDateTimeStringInYear, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getImageUrl, getTokenDecimal, getTokenTicker, parseBigNumber, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ADMIN_ADDRESS, apiTimeout, ASHSWAP_VOTING_ESCROW_ADDRESS, ASH_TOKEN_ID, isDev, JEWEL_ASHSWAP_SC_ADDRESS, NETWORK_JEWEL_API } from 'config';
import { routeNames } from 'routes';
import { Boost } from './boost';
import { ChainId, ContractManager, MVXProxyNetworkAddress } from '@ashswap/ash-sdk-js/out';
import { ProxyNetworkProvider } from '@ashswap/ash-sdk-js/node_modules/@multiversx/sdk-network-providers/out';
import ash_logo from 'assets/img/coin/ash.png';
import { govFeeClaim, govWithdraw, increaseLockedTime, manageStakeFarm } from 'z/elrond/sc/jewel-farm';

export const AdminVeash = () => {
  const navigate = useNavigate();
  const {
    network: { apiAddress },
    chainID,
  } = useGetNetworkConfig();
  const { address, balance } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  // useEffect(() => {
  //   if (address != ADMIN_ADDRESS) {
  //     navigate(routeNames.dashboard);
  //   }
  // }, [address]);

  const [commonSettings, setCommonSettings] = useState<JewelFarmBaseContext | undefined>();
  const [ashswapFarms, setAshswapFarms] = useState<JewelFarmContext[]>([]);
  const [ashBalance, setAshBalance] = useState<string>('0');
  const [veashBalance, setVeashBalance] = useState<string>('0');
  const [lockedAmount, setLockedAmount] = useState<string>('0');
  const [lockPeriod, setLockPeriod] = useState<number>(0);
  const [stakeAmount, setStakeAmount] = useState<string>('0');
  const [isExtend, setIsExtend] = useState<boolean>(true);
  const [veashSupply, setVeashSupply] = useState<string>('0');
  const [accumulatedFee, setAccumulatedFee] = useState<string>('0');
  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const res = await getUserTokenBalance(apiAddress, address, [ASH_TOKEN_ID]);
        if (res.length > 0 && res[0].balance) {
          const _ashBalance = res[0].balance;
          setAshBalance(_ashBalance);
        }
      }

      const _farms = await queryViewJewelFarms(apiAddress);
      setAshswapFarms(_farms);

      let _fee = '0';
      for (let i = 0; i < _farms.length; i++) {
        _fee = BigNumber(_fee).plus(_farms[i].pending_reward_token_amount).toFixed();
      }
      setAccumulatedFee(_fee);

      const _commonSettings = await queryViewJewelFarmBaseContext();
      if (_commonSettings) {
        // console.log('_commonSettings', _commonSettings);
        setCommonSettings(_commonSettings);
      }

      // const _commonSettings = await queryViewJewelFarmBaseContextData(apiAddress, address, balance);
      // console.log('_commonSettings', _commonSettings);
      // if (_commonSettings) {
      //   setCommonSettings(_commonSettings);
      //   let _fee = '0';
      //   for (let i = 0; i < _commonSettings.farms.length; i++) {
      //     _fee = BigNumber(_fee).plus(_commonSettings.farms[i].pending_reward_token_amount).toFixed();
      //   }
      //   setAccumulatedFee(_fee);
      // }

      const proxy = new ProxyNetworkProvider(isDev ? MVXProxyNetworkAddress.Devnet : NETWORK_JEWEL_API, { timeout: apiTimeout });
      const votingEscrowContract = ContractManager
        .getVotingEscrowContract(ASHSWAP_VOTING_ESCROW_ADDRESS)
        .onChain(isDev ? ChainId.Devnet : ChainId.Mainnet)
        .onProxy(proxy);

      const currentTimestamp = Math.floor(Date.now() / SECOND_TO_MILLISECONDS);
      const _veashBalance = await votingEscrowContract.getUserBalanceAtTs(JEWEL_ASHSWAP_SC_ADDRESS, currentTimestamp);
      setVeashBalance(_veashBalance.toFixed());

      const { amount, end } = await votingEscrowContract.getUserLocked(JEWEL_ASHSWAP_SC_ADDRESS);
      setLockedAmount(amount.toFixed());
      if (amount.gt('0')) {
        setLockPeriod(end.toNumber() - (Date.now() / SECOND_TO_MILLISECONDS));
      }

      const veashTotalSupply = await votingEscrowContract.getTotalSupplyAtTs(currentTimestamp);
      setVeashSupply(veashTotalSupply.toFixed());
    })();
  }, [hasPendingTransactions]);

  const onStakeAmountChange = (value: string) => {
    if (!isLoggedIn) return;

    setStakeAmount(value);
  };

  const handleStake = async () => {
    if (!isLoggedIn) return;

    if (convertEsdtToWei(stakeAmount, getTokenDecimal(ASH_TOKEN_ID)).gt(ashBalance)) return;

    if (BigNumber(stakeAmount).lte(0) || stakeAmount === '') return;

    if (BigNumber(lockedAmount).gt(0)) {
      // manage stake
      await manageStakeFarm(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, 'govIncreaseAmount', stakeAmount);
    } else {
      // add stake
      await manageStakeFarm(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, 'govCreateLock', stakeAmount);
    }
  };

  const handleIncrease = async () => {
    if (!isLoggedIn) return;

    if (isExtend && BigNumber(lockedAmount).gt(0)) {
      // extend locked period
      await increaseLockedTime(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address);
    }
  };

  const handleWithdraw = async () => {
    if (!isLoggedIn) return;

    await govWithdraw(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address);
  };

  const handleClaimFee = async () => {
    if (!isLoggedIn) return;

    await govFeeClaim(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address);
  };

  return (
    <>
      <div className='container ' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Admin</span>
              <span>Add/Lock ash for veASH.</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='row gy-2 gx-2 dashboard-egld-info mb-5' style={{ marginTop: '40px' }}>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>ASH Balance(Wallet)</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(ashBalance))}</div>
                  <img className='egld-badge' src={ash_logo} alt="ASH" />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>veASH Balance</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(veashBalance))} veASH`}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Locked Balance</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(lockedAmount))}</div>
                  <img className='egld-badge' src={ash_logo} alt="ASH" />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Current Lock Period</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{convertSecondsToPrintDateTimeStringInYear(lockPeriod)}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Fee</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(accumulatedFee))}</div>
                  <img className='egld-badge' src={ash_logo} alt="ASH" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className='row gy-2 gx-2 dashboard-egld-info mb-3' style={{ marginTop: '40px' }}>
            <div className='col-sm-12'>
              <div>{BigNumber(lockedAmount).gt(0) ? 'I want to stake more' : 'I want to stake'}</div>
              <div className='borrow-form mt-1' >
                <div className='d-flex justify-content-between'>
                  <div className='borrow-coin-logo'>
                    <img src={ash_logo} alt="ASH" />
                    <span>ASH</span>
                  </div>
                  <input type="number" className="borrow-input" value={stakeAmount} onChange={(e) => onStakeAmountChange(e.target.value)} />
                </div>
                <div className='d-flex justify-content-between'>
                  <span style={{ fontSize: '0.85rem' }}>{`Available: ${convertBigNumberValueToLocalString(convertWeiToEsdt(ashBalance))} ASH`}</span>
                </div>
              </div>
            </div>
            {BigNumber(lockedAmount).gt(0) && (
              <div className='col-sm-12 mt-4'>
                <div className='d-flex'>
                  <Checkbox
                    checked={isExtend}
                    onChange={e => setIsExtend(e.target.checked)}
                    sx={{ '&': { padding: '0px', marginLeft: '3px' } }}
                  />
                  <div className='mx-2'>I want to extend my locked period to 4 years</div>
                </div>
              </div>
            )}
          </div>
          <div className='row mb-2'>
            <div className={`${isExtend && BigNumber(lockedAmount).gt(0) ? 'col-lg-4 col-md-4' : 'col-lg-6 col-md-6'} col-sm-12 d-flex justify-content-center mt-2`}>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleStake}>
                {BigNumber(lockedAmount).gt(0) ? 'Manage Stake' : 'Add Stake'}
              </div>
            </div>
            {isExtend && BigNumber(lockedAmount).gt(0) && (
              <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center mt-2'>
                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleIncrease}>
                  Increase Period
                </div>
              </div>
            )}
            <div className={`${isExtend && BigNumber(lockedAmount).gt(0) ? 'col-lg-4 col-md-4' : 'col-lg-6 col-md-6'} col-sm-12 d-flex justify-content-center mt-2`}>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleClaimFee}>
                Claim Fee
              </div>
            </div>
            {/* <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center mt-2'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleWithdraw}>
                Withdraw
              </div>
            </div> */}
          </div>

          <hr className='mt-5 mb-3' />

          <div className='row mb-2'>
            <div className='col-12'>Boost</div>
            <div className='col-12 mt-3'>
              {ashswapFarms.map((item: JewelFarmContext, index: number) => (
                <Boost key={index} commonSettings={commonSettings} farm={item} totalCurrentVeASH={veashSupply} veashBalance={veashBalance} />
              ))}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
