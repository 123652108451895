import React, { FC, useEffect, useState } from 'react';
import { Backdrop, Box, InputAdornment, InputBase, Modal } from '@mui/material';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelFarmBaseContextData, JewelLendPool } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_EXCEED_MAX_VALUE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getApy, getFarmApy, getJewelLendPoolApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, parseBigNumber, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import BigNumber from 'bignumber.js';
import { useGetAccount, useGetAccountInfo, useGetIsLoggedIn, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { sendJewelLend, sendJewelWithdraw } from 'z/elrond/sc/jewel-lend';
import { isMobile } from 'react-device-detect';
import { queryGetEquivalent } from 'z/elrond';
import { ASH_TOKEN_ID, contracts, JEWEL_ASHSWAP_SC_DEPLOYED_TIMESTAMP, JEWEL_JWLASH_LEND_SC_DEPLOYED_TIMESTAMP, JEWEL_JWLEGLD_LEND_SC_DEPLOYED_TIMESTAMP, JEWEL_LEND_MAX_BORROWING_CAP_PERCENT, JEWEL_SEGLD_LEND_SC_DEPLOYED_TIMESTAMP, MAX_GAS_LIMIT } from 'config';
import { XMexInput } from './xmexInput';
import { JewelHistory } from 'z/types/history';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

interface lendPoolProps {
    pool: JewelLendPool,
    userTokens: any,
    isXMex: boolean,
    jewelLendAprs: JewelHistory[],
}

export const LendPool: FC<lendPoolProps> = ({
    pool,
    userTokens,
    isXMex,
    jewelLendAprs,
}) => {
    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, shard, account } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();
    const { balance } = useGetAccount();

    const [ibTokenPrice, setIbTokenPrice] = useState<string>('0');
    const [apy, setApy] = useState<string>('-');

    const [userDepositTokenBalance, setUserDepositTokenBalance] = useState<string>('0');
    const [userIbTokenBalance, setUserIbTokenBalance] = useState<string>('0');

    const [jewelLendApr, setJewelLendApr] = useState<number>(0);
    useEffect(() => {
        for (const jewelLendApr of jewelLendAprs) {
            if (getTokenTicker(pool.ib_token_id) === jewelLendApr.name) {
                setJewelLendApr(jewelLendApr.aprs[0].apr);
                break;
            }
        }
    }, [jewelLendAprs]);

    useEffect(() => {
        (async () => {
            if (pool) {
                if (BigNumber(pool.ib_token_amount).gt('0')) {
                    const token_amount = convertEsdtToWei(BigNumber(1), getTokenDecimal(pool.deposit_token_id)).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount).toFixed();
                    setIbTokenPrice(token_amount);
                } else {
                    setIbTokenPrice('0');
                }

                setApy(convertBigNumberValueToLocalString(getJewelLendPoolApy(pool)));
            }
        })();
    }, [pool]);

    useEffect(() => {
        if (pool && userTokens) {
            let depositTokenBalance = '0';
            let ibTokenBalance = '0';
            if (isLoggedIn) {
                if (getTokenTicker(pool.deposit_token_id) === 'EGLD') {
                    depositTokenBalance = userTokens['EGLD'].balance;
                } else {
                    depositTokenBalance = userTokens[pool.deposit_token_id]?.balance;
                }
                ibTokenBalance = userTokens[pool.ib_token_id]?.balance;
            }
            setUserDepositTokenBalance(depositTokenBalance);
            setUserIbTokenBalance(ibTokenBalance);
        }
    }, [pool, userTokens]);

    const [isDeposit, setIsDeposit] = useState<boolean>(true);
    const [showLendModal, setShowLendModal] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>('0');
    const [amountError, setAmountError] = useState<string>('');
    const [isCalculating, setIsCalculating] = useState<boolean>(false);
    const [outAmount, setOutAmount] = useState<string>('');

    const getOutputAmount = (inputAmount: string): string => {
        setIsCalculating(true);
        // let _outputAmount = '0';
        // if (isDeposit) {
        //     _outputAmount = await queryGetEquivalent(apiAddress, pool.deposit_token_id, convertEsdtToWei(inputAmount, getTokenDecimal(pool.deposit_token_id)).toFixed());
        // } else {
        //     _outputAmount = await queryGetEquivalent(apiAddress, pool.ib_token_id, convertEsdtToWei(inputAmount, getTokenDecimal(pool.deposit_token_id)).toFixed());
        // }
        let _outputAmount = '0';
        if (isDeposit) {
            _outputAmount = convertEsdtToWei(inputAmount, getTokenDecimal(pool.deposit_token_id)).multipliedBy(pool.ib_token_amount).dividedBy(pool.deposit_token_amount).toFixed(0);
        } else {
            _outputAmount = convertEsdtToWei(inputAmount, getTokenDecimal(pool.deposit_token_id)).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount).toFixed(0);
        }

        setOutAmount(_outputAmount);
        setIsCalculating(false);

        return _outputAmount;
    };

    const handleMax = () => {
        let amount = '0';
        if (isDeposit) {
            let depositAmount = userDepositTokenBalance;
            if (getTokenTicker(pool.deposit_token_id) === 'EGLD') {
                if (BigNumber(depositAmount).gt(EXTRA_GAS_FEE_AMOUNT)) {
                    depositAmount = BigNumber(depositAmount).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
                } else {
                    depositAmount = '0';
                }
            }
            amount = convertWeiToEsdt(depositAmount, getTokenDecimal(pool.deposit_token_id)).toFixed();
        } else {
            amount = convertWeiToEsdt(userIbTokenBalance, getTokenDecimal(pool.deposit_token_id)).toFixed();
        }
        setAmount(amount);
        getOutputAmount(amount);
    };

    const checkAmount = (value: string) => {
        const bValue = parseBigNumber(value);
        let error = '';
        if (bValue == undefined) {
            error = 'Invalid number';
        } else if (bValue.comparedTo(0) < 0) {
            error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
        } else {
            if (isDeposit && convertEsdtToWei(bValue, getTokenDecimal(pool.deposit_token_id)).comparedTo(userDepositTokenBalance) > 0) {
                error = ERROR_EXCEED_MAX_VALUE;
            }
            if (isDeposit && getTokenTicker(pool.deposit_token_id) === 'EGLD') {
                if (BigNumber(userDepositTokenBalance).lte(EXTRA_GAS_FEE_AMOUNT) || convertEsdtToWei(bValue, getTokenDecimal(pool.deposit_token_id)).gt(BigNumber(userDepositTokenBalance).minus(EXTRA_GAS_FEE_AMOUNT))) {
                    error = ERROR_EXCEED_MAX_VALUE;
                }
            }
            if (!isDeposit && convertEsdtToWei(bValue, getTokenDecimal(pool.deposit_token_id)).comparedTo(userIbTokenBalance) > 0) {
                error = ERROR_EXCEED_MAX_VALUE;
            }
            if (!isDeposit && convertEsdtToWei(bValue, getTokenDecimal(pool.deposit_token_id)).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount).comparedTo(BigNumber(pool.deposit_token_amount).minus(pool.loan_amount)) > 0) {
                error = 'Withdraw cant be more than the unutilised token';
            }
        }

        return error;
    };

    const onChangeAmount = (value: string) => {
        setAmount(value);
        if (!isXMex) {
            const error = checkAmount(value);
            setAmountError(error);
            getOutputAmount(value);
        }
    };

    const onClickDeposit = async () => {
        if (!address) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        if (getTokenTicker(pool.deposit_token_id) === 'BUSD') return;

        setAmount('0');
        setOutAmount('0');
        setAmountError('');
        setIsDeposit(true);
        setShowLendModal(true);
    };

    const onClickWithdraw = async () => {
        if (!address) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        setAmount('0');
        setOutAmount('0');
        setAmountError('');
        setIsDeposit(false);
        setShowLendModal(true);
    };

    const handleDeposit = async () => {
        if (!address) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
            toastError('Invalid number');
            return;
        }

        const error = checkAmount(amount);
        if (error !== '') {
            toastError(error);
            return;
        }

        let wrapAddress = contracts.Wrap0.address;

        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        // deposit
        await sendJewelLend(chainID, address, pool.deposit_token_id, convertEsdtToWei(amount, getTokenDecimal(pool.deposit_token_id)).toFixed(), wrapAddress);

        setShowLendModal(false);
    };

    const handleWithdraw = async () => {
        if (!address) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
            toastError('Invalid number');
            return;
        }

        const error = checkAmount(amount);
        if (error !== '') {
            toastError(error);
            return;
        }

        let unWrapAddress = contracts.Wrap0.address;

        if (shard === 1) {
            unWrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            unWrapAddress = contracts.Wrap2.address;
        }

        const _outAmount = getOutputAmount(amount);

        // withdraw
        await sendJewelWithdraw(chainID, address, pool.ib_token_id, convertEsdtToWei(amount, getTokenDecimal(pool.deposit_token_id)).toFixed(), pool.deposit_token_id, _outAmount, unWrapAddress, account.nonce);

        setShowLendModal(false);
    };

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 lend-pool-container mobile-lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={getTokenImage(pool.deposit_token_id)} className='egld-image mx-2' alt={pool.deposit_token_id}></img>
                            <div>
                                <div>{getTokenTicker(pool.deposit_token_id)}</div>
                                <div className='mt-1'>{`1 ${getTokenTicker(pool.ib_token_id)} = ${convertBigNumberValueToLocalString(convertWeiToEsdt(ibTokenPrice, getTokenDecimal(pool.deposit_token_id), 5), 5)} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-3'>
                        <div>APY / APR(24H)</div>
                        {/* <div className='avaliable-color'>{`${apy} %`}</div> */}
                        <div className='avaliable-color'>{`${apy}% / ${convertBigNumberValueToLocalString(jewelLendApr)}%`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Total Supply</div>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.deposit_token_amount, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Max Borrow</div>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.deposit_token_amount, getTokenDecimal(pool.deposit_token_id)).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Total Borrowed</div>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.loan_amount, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Utilization</div>
                        <div className='avaliable-color'>{`${BigNumber(pool.deposit_token_amount).gt(0) ? convertBigNumberValueToLocalString(BigNumber(pool.loan_amount).multipliedBy(100).dividedBy(pool.deposit_token_amount)) : '0'} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Your Balance</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userIbTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.ib_token_id)} (= ${BigNumber(pool.ib_token_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(userIbTokenBalance).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount), getTokenDecimal(pool.deposit_token_id))) : '0'} ${getTokenTicker(pool.deposit_token_id)})`}</div>
                            <div>{`${getTokenTicker(pool.deposit_token_id) === 'EGLD' ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance)) : convertBigNumberValueToLocalString(convertWeiToEsdt(userDepositTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-2'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                {
                                    getTokenTicker(pool.deposit_token_id) === 'BUSD' ? (
                                        <div className="eg-btn capsule px-2 py-2" style={{ width: '100%', border: '1px solid #3d634e', borderRadius: '5px', color: 'grey' }} onClick={onClickDeposit}>
                                            Deposit
                                        </div>
                                    ) : (
                                        <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={onClickDeposit}>
                                            Deposit
                                        </div>
                                    )
                                }
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={onClickWithdraw}>
                                    Withdraw
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                        <div className='d-flex align-items-center'>
                            <img src={getTokenImage(pool.deposit_token_id)} className='egld-image mx-2' alt={pool.deposit_token_id}></img>
                            <div>
                                <div>{getTokenTicker(pool.deposit_token_id)}</div>
                                <div className='mt-1'>{`1 ${getTokenTicker(pool.ib_token_id)} = ${convertBigNumberValueToLocalString(convertWeiToEsdt(ibTokenPrice, getTokenDecimal(pool.deposit_token_id), 5), 5)} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 text-center align-self-center'>
                        {/* <div className='avaliable-color'>{`${apy} %`}</div> */}
                        <div className='avaliable-color'>{`${apy}% / ${convertBigNumberValueToLocalString(jewelLendApr)}%`}</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 d-flex'>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.deposit_token_amount, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`} /</div>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.deposit_token_amount, getTokenDecimal(pool.deposit_token_id)).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.loan_amount, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div className='avaliable-color'>{`${BigNumber(pool.deposit_token_amount).gt(0) ? convertBigNumberValueToLocalString(BigNumber(pool.loan_amount).multipliedBy(100).dividedBy(pool.deposit_token_amount)) : '0'} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 text-end align-self-center'>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userIbTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.ib_token_id)} (= ${BigNumber(pool.ib_token_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(userIbTokenBalance).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount), getTokenDecimal(pool.deposit_token_id))) : '0'} ${getTokenTicker(pool.deposit_token_id)})`}</div>
                        <div>{`Avail. ${getTokenTicker(pool.deposit_token_id) === 'EGLD' ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance)) : convertBigNumberValueToLocalString(convertWeiToEsdt(userDepositTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                {
                                    getTokenTicker(pool.deposit_token_id) === 'BUSD' ? (
                                        <div className="eg-btn capsule px-2 py-2" style={{ width: '100%', border: '1px solid #3d634e', borderRadius: '5px', color: 'grey' }} onClick={onClickDeposit}>
                                            Deposit
                                        </div>
                                    ) : (
                                        <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={onClickDeposit}>
                                            Deposit
                                        </div>
                                    )
                                }
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={onClickWithdraw}>
                                    Withdraw
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showLendModal}
                onClose={() => setShowLendModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showLendModal}>
                    {
                        isXMex && isDeposit ? (
                            <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                                <div className='d-flex align-items-center'>
                                    <div>{`Depositing ${getTokenTicker(pool.deposit_token_id)}`}</div>
                                    <img src={getTokenImage(pool.deposit_token_id)} className='egld-image mx-2' alt={pool.deposit_token_id}></img>
                                </div>
                                <div className='mt-4'>
                                    <XMexInput amount={amount} onChangeAmount={(value: string) => onChangeAmount(value)} setAmountError={(value: string) => setAmountError(value)} />
                                </div>
                                <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                                    {amountError}
                                </div>
                                <div className='d-flex mt-3'>
                                    {`The amount of ibTokens you'll receive is worth the same as the amount of tokens you deposited. There is no fee and you are not losing any value. Their exchange rate is not 1:1.`}
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }}>
                                        Deposit
                                    </div>
                                </div>
                            </Box>
                        ) : (
                            <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                                <div className='d-flex align-items-center'>
                                    <div>{`${isDeposit ? 'Depositing' : 'Withdrawing'} ${getTokenTicker(pool.deposit_token_id)}`}</div>
                                    <img src={getTokenImage(pool.deposit_token_id)} className='egld-image mx-2' alt={pool.deposit_token_id}></img>
                                </div>
                                <div className='d-flex mt-3'>
                                    {`Available Balance: ${isDeposit ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userDepositTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.deposit_token_id)}` : `${convertBigNumberValueToLocalString(convertWeiToEsdt(userIbTokenBalance, getTokenDecimal(pool.deposit_token_id)))} ${getTokenTicker(pool.ib_token_id)}`}`}
                                </div>
                                <div className='d-flex mt-1'>
                                    <InputBase
                                        sx={{
                                            flex: 1,
                                            border: '1px',
                                            borderStyle: 'solid',
                                            borderRadius: '5px',
                                            borderColor: 'gray',
                                            padding: '2px 5px',
                                        }}
                                        endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                                        type='number'
                                        placeholder='0.00'
                                        value={amount}
                                        onChange={(e: any) => onChangeAmount(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                                    {amountError}
                                </div>
                                <div className='d-flex mt-3'>
                                    {`You will receive: ~ ${isCalculating ? '?' : convertBigNumberValueToLocalString(convertWeiToEsdt(outAmount, getTokenDecimal(pool.deposit_token_id)))} ${isDeposit ? getTokenTicker(pool.ib_token_id) : getTokenTicker(pool.deposit_token_id)}`}
                                </div>
                                {isDeposit && (
                                    <div className='d-flex mt-2'>
                                        {`The amount of ibTokens you'll receive is worth the same as the amount of tokens you deposited. There is no fee and you are not losing any value. Their exchange rate is not 1:1.`}
                                    </div>
                                )}
                                <div className='d-flex justify-content-center mt-4'>
                                    {isDeposit ? (
                                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleDeposit}>
                                            Deposit
                                        </div>
                                    ) : (
                                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleWithdraw}>
                                            Withdraw
                                        </div>
                                    )}
                                </div>
                            </Box>
                        )
                    }
                </Fade>
            </Modal>
        </>
    );
};
