import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Fade, Modal } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isMobile } from 'react-device-detect';
import BigNumber from 'bignumber.js';
import { queryJewelStableCommonContext, queryJewelStableDynamicFee, queryJewelStableStatsContext, sendJewelStableMint, sendJewelStableRedeem, sendJewelStableStake, sendJewelStableUnbond, sendJewelStableUnstake } from 'z/elrond';
import { ElrondStatsType, JewelStableCommonContext, JewelStableStatsContext, NftWithIndexType } from 'z/types';
import { Spinner } from 'components/Spinner';
import { StakeInput } from './StakeInput';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_INVALID_VALUE, getFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, getUnbondEndTimeString, getUnstakeTokenAttributes, PERCENT_DENOMINATOR, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import './index.scss';
import { getElrondStatsFromApi, getJewelLendAprs, getNftsByCollectionFromApi, getUserTokenBalance } from 'z/api';
import { isDev, JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP, USDC_TOKEN_ID, WDAI_TOKEN_ID } from 'config';
import { Dropdown } from 'react-bootstrap';
import { TokenType } from '@multiversx/sdk-dapp/types/tokens.types';

export const JewelStableStake = () => {

    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, balance } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [selectedTokenId, setSelectedTokenId] = useState<string>(USDC_TOKEN_ID);
    const [selectedTokenBalance, setSelectedTokenBalance] = useState<string>('0');

    const [jewelStableCommonContext, setJewelStableCommonContext] = useState<JewelStableCommonContext | undefined>();
    const [jewelStableStatsContext, setJewelStableStatsContext] = useState<JewelStableStatsContext | undefined>();
    const [jwlusdcTokenAmount, setJwlusdcTokenAmount] = useState<string>('0');
    const [sjwlusdcTokenAmount, setSjwlusdcTokenAmount] = useState<string>('0');
    const [unstakeNfts, setUnstakeNfts] = useState<NftWithIndexType[]>([]);
    const [userBalances, setUserBalances] = useState<TokenType[]>([]);

    const [isMintExpanded, setIsMintExpanded] = useState<boolean>(false);
    const [mintInputAmount, setMintInputAmount] = useState<string>('0');
    const [mintInputAmountError, setMintInputAmountError] = useState<string>('');
    const [redeemInputAmount, setRedeemInputAmount] = useState<string>('0');
    const [redeemInputAmountError, setRedeemInputAmountError] = useState<string>('');

    const [isStakeExpanded, setIsStakeExpanded] = useState<boolean>(false);
    const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
    const [stakeInputAmountError, setStakeInputAmountError] = useState<string>('');
    const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
    const [unstakeInputAmountError, setUnstakeInputAmountError] = useState<string>('');

    const [networkConfig, setNetworkConfig] = useState<ElrondStatsType>();
    const [unbondingNfts, setUnbondingNfts] = useState<NftWithIndexType[]>([]);
    const [unbondedNfts, setUnbondedNfts] = useState<NftWithIndexType[]>([]);
    const [withdrawlAmount, setWithdrawlAmount] = useState<string>('0');
    const [apy, setApy] = useState<string>('-');
    const [redeemFeeModal, setRedeemFeeModal] = useState<boolean>(false);
    const [dynamicFeePercent, setDynamicFeePercent] = useState<number>(0);

    useEffect(() => {
        (async () => {
            const _commonContext = await queryJewelStableCommonContext();
            // console.log('_commonContext', _commonContext);
            if (_commonContext) {
                setJewelStableCommonContext(_commonContext);
            }
        })();
    }, []);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _statsContext = await queryJewelStableStatsContext();
            // console.log('_statsContext: ', _statsContext);
            if (_statsContext) {
                setJewelStableStatsContext(_statsContext);

                if (BigNumber(_statsContext.sjwlusd_pool_reserve).gt('0')) {
                    const _tokenAmount = convertEsdtToWei(1).multipliedBy(_statsContext.jwlusd_pool_reserve).dividedBy(_statsContext.sjwlusd_pool_reserve);
                    const _passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
                    const _apy = _tokenAmount.minus(convertEsdtToWei(1)).dividedBy(_passedDays).multipliedBy(365).multipliedBy(100);
                    setApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
                }
            }
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions || !jewelStableCommonContext || !isLoggedIn) return;

        (async () => {
            const tokenIds = [jewelStableCommonContext.jwlusd_token, jewelStableCommonContext.sjwlusd_token, WDAI_TOKEN_ID];
            for (const tokenId of jewelStableCommonContext.hatom_base_token_ids) {
                tokenIds.push(tokenId);
            }
            const _userBalance = await getUserTokenBalance(apiAddress, address, tokenIds);
            // console.log('_userBalance: ', _userBalance);
            setUserBalances(_userBalance);

            let currentBalance = '0';
            let jwlusdcBalance = '0';
            let sjwlusdcBalance = '0';
            for (const item of _userBalance) {
                if (item.identifier === selectedTokenId && item.balance) {
                    currentBalance = item.balance;
                } else if (item.identifier === jewelStableCommonContext.jwlusd_token && item.balance) {
                    jwlusdcBalance = item.balance;
                } else if (item.identifier === jewelStableCommonContext.sjwlusd_token && item.balance) {
                    sjwlusdcBalance = item.balance;
                }
            }
            setSelectedTokenBalance(currentBalance);
            setJwlusdcTokenAmount(jwlusdcBalance);
            setSjwlusdcTokenAmount(sjwlusdcBalance);
        })();
    }, [hasPendingTransactions, jewelStableCommonContext, isLoggedIn]);

    useEffect(() => {
        if (hasPendingTransactions || !jewelStableCommonContext || !isLoggedIn) return;

        (async () => {
            const _unstakeNfts = await getNftsByCollectionFromApi(apiAddress, address, jewelStableCommonContext.unstake_token);
            // console.log('_unstakeNfts: ', _unstakeNfts);
            if (_unstakeNfts.length > 0) {
                setUnstakeNfts(_unstakeNfts);
            }

            const _networkConfig = await getElrondStatsFromApi();
            // console.log('networkConfig: ', _networkConfig);
            if (_networkConfig) {
                setNetworkConfig(_networkConfig);

                let _withdrawlAmount = BigNumber('0');
                const _unbondingNfts: NftWithIndexType[] = [];
                const _unbondedNfts: NftWithIndexType[] = [];
                for (const item of _unstakeNfts) {
                    const attribute = getUnstakeTokenAttributes(item);
                    if (_networkConfig.epoch >= attribute.unbond_epoch) {
                        _withdrawlAmount = _withdrawlAmount.plus(attribute.unstake_amount);
                        _unbondedNfts.push(item);
                    } else {
                        _unbondingNfts.push(item);
                    }
                }
                setWithdrawlAmount(_withdrawlAmount.toFixed(0));
                setUnbondingNfts(_unbondingNfts);
                setUnbondedNfts(_unbondedNfts);
            }

        })();
    }, [hasPendingTransactions, jewelStableCommonContext, isLoggedIn]);

    // get apr from api
    const [realApr, setRealApr] = useState<number>(0);
    useEffect(() => {
        (async () => {
            const aprs = await getJewelLendAprs();

            for (const apr of aprs) {
                if (apr.name === 'SJWLUSD') {
                    setRealApr(apr.aprs[0].apr);
                    break;
                }
            }
        })();
    }, [hasPendingTransactions]);

    const handleSelectedTokenId = async (value: string) => {
        setSelectedTokenId(value);

        let currentBalance = '0';
        for (const item of userBalances) {
            if (item.identifier === value && item.balance) {
                currentBalance = item.balance;
                break;
            }
        }
        setSelectedTokenBalance(currentBalance);
        setMintInputAmount('0');
    };

    const handleMintExpand = () => {
        cleanMintAmount();

        setIsMintExpanded(!isMintExpanded);
    };

    const handleStakeExpand = () => {
        cleanStakeAmount();

        setIsStakeExpanded(!isStakeExpanded);
    };

    const cleanMintAmount = () => {
        setMintInputAmount('0');
        setMintInputAmountError('');
        setRedeemInputAmount('0');
        setRedeemInputAmountError('');
    };

    const cleanStakeAmount = () => {
        setStakeInputAmount('0');
        setStakeInputAmountError('');
        setUnstakeInputAmount('0');
        setUnstakeInputAmountError('');
    };

    const handleOpenFeeModal = async () => {
        (async () => {
            const fee = await queryJewelStableDynamicFee();
            setDynamicFeePercent(fee);

            setRedeemFeeModal(true);
        })();
    };

    const handleMint = async () => {
        if (!jewelStableCommonContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(mintInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (mintInputAmountError) {
            toastError(mintInputAmountError);
            return;
        }

        await sendJewelStableMint(chainID, address, selectedTokenId, convertEsdtToWei(mintInputAmount, getTokenDecimal(selectedTokenId)).toFixed(0));

        cleanMintAmount();
    };

    const handleRedeem = async () => {
        setRedeemFeeModal(false);
        if (!jewelStableCommonContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(redeemInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (redeemInputAmountError) {
            toastError(redeemInputAmountError);
            return;
        }

        await sendJewelStableRedeem(chainID, address, jewelStableCommonContext.jwlusd_token, convertEsdtToWei(redeemInputAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)).toFixed(0));

        cleanMintAmount();
    };

    const handleWithdraw = async () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(withdrawlAmount).lte('0') || unbondedNfts.length === 0) {
            return;
        }

        await sendJewelStableUnbond(chainID, address, unbondedNfts);
    };

    const handleStake = async () => {
        if (!jewelStableCommonContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (stakeInputAmountError) {
            toastError(stakeInputAmountError);
            return;
        }

        await sendJewelStableStake(chainID, address, jewelStableCommonContext.jwlusd_token, convertEsdtToWei(stakeInputAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)).toFixed(0));

        cleanStakeAmount();
    };

    const handleUnstake = async () => {
        if (!jewelStableCommonContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unstakeInputAmountError) {
            toastError(unstakeInputAmountError);
            return;
        }

        await sendJewelStableUnstake(chainID, address, jewelStableCommonContext.sjwlusd_token, convertEsdtToWei(unstakeInputAmount, getTokenDecimal(jewelStableCommonContext.sjwlusd_token)).toFixed(0));

        cleanStakeAmount();
    };

    return (
        <div>
            <div className='d-flex mt-2'>
                {`JWLUSD is a USD-pegged stablecoin.`}
            </div>
            <div className='d-flex mt-1'>
                <span>
                    {`Mint USDC/USDT/BUSD/DAI for JWLUSD 1:1 which will be sent for Hatom supply, collateral activation when able to. A dynamic % will be sent for LP creation. Stake JWLUSD for SJWLUSD which will accrue staking yield. When you redeem JWLUSD, you will receive in the current proportion of stables. (Eg. if 30% USDC, 30% USDT, 30% BUSD, 10% DAI for 100 JWLUSD redemption, you will receive 30 USDC, 30 USDT, 30 BUSD, 10 DAI). There may be a very minute amount of difference due to rounding figures. A redemption fee of 0.5% onwards may be charged subsequently. You can now swap USDC-JWLUSD at `}
                    <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                </span>
            </div>
            {
                jewelStableCommonContext && jewelStableStatsContext ? (
                    <>
                        <div className='row mt-4' style={{ justifyContent: "center" }}>
                            <Accordion
                                expanded={isMintExpanded}
                            >
                                <AccordionSummary
                                    className={`col-12`}
                                    sx={{ cursor: 'auto !important' }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                            <Dropdown className='stable-dropdown'>
                                                <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src={getTokenImage(selectedTokenId)} className='egld-image mx-2' alt={selectedTokenId}></img>
                                                        <div>{getTokenTicker(selectedTokenId)}</div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        jewelStableCommonContext.hatom_base_token_ids.map((tokenId: string, index: number) => (
                                                            getTokenTicker(tokenId) !== 'BUSD' && (
                                                                <Dropdown.Item className='d-flex align-items-center' key={index} onClick={() => handleSelectedTokenId(tokenId)}>
                                                                    <img src={getTokenImage(tokenId)} alt={tokenId}></img>
                                                                    <div className='token-id'>{getTokenTicker(tokenId)}</div>
                                                                </Dropdown.Item>
                                                            )
                                                        ))
                                                    }
                                                    <Dropdown.Item className='d-flex align-items-center' onClick={() => handleSelectedTokenId(WDAI_TOKEN_ID)}>
                                                        <img src={getTokenImage(WDAI_TOKEN_ID)} alt={WDAI_TOKEN_ID}></img>
                                                        <div className='token-id'>{getTokenTicker(WDAI_TOKEN_ID)}</div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`TVL`}</div>
                                            <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelStableStatsContext.jwlusd_supply, getTokenDecimal(jewelStableCommonContext.jwlusd_token)))} JWLUSD`}</div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-12'></div>
                                        <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                            <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                <div className='d-flex align-items-center'>Your balance</div>
                                                <div className='text-end'>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance, getTokenDecimal(selectedTokenId)))} ${getTokenTicker(selectedTokenId)}` : '-'}</div>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlusdcTokenAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)))} JWLUSD` : '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                            <div
                                                className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                onClick={handleMintExpand}
                                            >
                                                <div className='d-flex'>Mint / Redeem</div>
                                                {isMintExpanded ? (
                                                    <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                ) : (
                                                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails
                                    className='row'
                                    sx={{ padding: '0px 16px 16px !important' }}
                                >
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                <div className='d-flex'>
                                                    {`Avail ${getTokenTicker(selectedTokenId)} Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance, getTokenDecimal(selectedTokenId))) : '-'} ${getTokenTicker(selectedTokenId)}`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                <StakeInput
                                                    userBalance={selectedTokenBalance}
                                                    inputAmount={mintInputAmount}
                                                    amountError={mintInputAmountError}
                                                    setInputAmount={setMintInputAmount}
                                                    setAmountError={setMintInputAmountError}
                                                    isUnstake={false}
                                                    tokenId={selectedTokenId}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleMint}>
                                                        {`Mint`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                <div className='d-flex'>
                                                    {`Avail JWLUSD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlusdcTokenAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token))) : '-'} JWLUSD`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                                <StakeInput
                                                    userBalance={jwlusdcTokenAmount}
                                                    inputAmount={redeemInputAmount}
                                                    amountError={redeemInputAmountError}
                                                    setInputAmount={setRedeemInputAmount}
                                                    setAmountError={setRedeemInputAmountError}
                                                    isUnstake={true}
                                                    tokenId={jewelStableCommonContext.jwlusd_token}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleOpenFeeModal}>
                                                        {`Redeem`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                                <div className='d-flex justify-content-center'>
                                                    <div>{`Unbonding Period`}</div>
                                                </div>
                                                {
                                                    !networkConfig || unbondingNfts.length === 0 ? (
                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <div>{`-`}</div>
                                                        </div>
                                                    ) : (
                                                        unbondingNfts.map((item: NftWithIndexType, index: number) => {
                                                            const attribute = getUnstakeTokenAttributes(item);
                                                            return <div className='d-flex justify-content-between mt-1' key={index}>
                                                                <div>{`• ${convertBigNumberValueToLocalString(convertWeiToEsdt(attribute.unstake_amount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)))} JWLUSD`}</div>
                                                                <div>{`${getUnbondEndTimeString(attribute.unbond_epoch, networkConfig)}`}</div>
                                                            </div>;
                                                        })
                                                    )
                                                }
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                                <div className='d-flex justify-content-center'>
                                                    <div>{`Unbonded: ${convertBigNumberValueToLocalString(convertWeiToEsdt(withdrawlAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)))} JWLUSD`}</div>
                                                </div>
                                                <div className='d-flex justify-content-center mt-4'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleWithdraw}>
                                                        {`Withdraw`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className='row mt-4' style={{ justifyContent: "center" }}>
                            <Accordion
                                expanded={isStakeExpanded}
                            >
                                <AccordionSummary
                                    className={`col-12`}
                                    sx={{ cursor: 'auto !important' }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                            <div className='d-flex align-items-center'>
                                                <img src={getTokenImage(jewelStableCommonContext.jwlusd_token)} className='egld-image mx-2' alt='ash'></img>
                                                <div>
                                                    <div>{`JWLUSD`}</div>
                                                    <div style={{ fontSize: '0.8rem' }}>{`1 SJWLUSD = ${BigNumber(jewelStableStatsContext.sjwlusd_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(BigNumber(jewelStableStatsContext.jwlusd_pool_reserve).dividedBy(jewelStableStatsContext.sjwlusd_pool_reserve), 5) : '-'} JWLUSD`}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`TVL`}</div>
                                            <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelStableStatsContext.sjwlusd_pool_reserve))} SJWLUSD`}</div>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                            <div>{`APY / APR(24H)`}</div>
                                            {
                                                isDev ? (
                                                    <div className='avaliable-color'>{`${apy == '0' ? '-' : apy} %`}</div>
                                                ) : (
                                                    <div className='avaliable-color'>{`${apy == '0' ? '-' : apy}% / ${convertBigNumberValueToLocalString(realApr)}%`}</div>
                                                )
                                            }
                                        </div>
                                        <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                            <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                <div className='d-flex align-items-center'>Your balance</div>
                                                <div className='text-end'>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlusdcTokenAmount, getTokenDecimal(jewelStableCommonContext.jwlusd_token)))} JWLUSD` : '-'}</div>
                                                    <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlusdcTokenAmount, getTokenDecimal(jewelStableCommonContext.sjwlusd_token)))} SJWLUSD (= ${BigNumber(jewelStableStatsContext.sjwlusd_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(sjwlusdcTokenAmount).multipliedBy(jewelStableStatsContext.jwlusd_pool_reserve).dividedBy(jewelStableStatsContext.sjwlusd_pool_reserve))) : '0'} JWLUSD)` : '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                            <div
                                                className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                onClick={handleStakeExpand}
                                            >
                                                <div className='d-flex'>Stake / Unstake</div>
                                                {isStakeExpanded ? (
                                                    <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                ) : (
                                                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails
                                    className='row'
                                    sx={{ padding: '0px 16px 16px !important' }}
                                >
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                <div className='d-flex'>
                                                    {`Avail JWLUSD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlusdcTokenAmount)) : '-'} JWLUSD`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                <StakeInput
                                                    userBalance={jwlusdcTokenAmount}
                                                    inputAmount={stakeInputAmount}
                                                    amountError={stakeInputAmountError}
                                                    setInputAmount={setStakeInputAmount}
                                                    setAmountError={setStakeInputAmountError}
                                                    isUnstake={false}
                                                    tokenId={jewelStableCommonContext.jwlusd_token}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleStake}>
                                                        {`Stake`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                <div className='d-flex'>
                                                    {`Avail SJWLUSD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlusdcTokenAmount)) : '-'} SJWLUSD`}
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                                <StakeInput
                                                    userBalance={sjwlusdcTokenAmount}
                                                    inputAmount={unstakeInputAmount}
                                                    amountError={unstakeInputAmountError}
                                                    setInputAmount={setUnstakeInputAmount}
                                                    setAmountError={setUnstakeInputAmountError}
                                                    isUnstake={true}
                                                    tokenId={jewelStableCommonContext.sjwlusd_token}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                                <div className='d-flex'>
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleUnstake}>
                                                        {`Unstake`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </>
                ) : (
                    <div className='mt-5'>
                        <Spinner />
                    </div>
                )}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={redeemFeeModal}
                onClose={() => setRedeemFeeModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={redeemFeeModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex'>
                            {`A ${dynamicFeePercent / PERCENT_DENOMINATOR}% fee (${convertBigNumberValueToLocalString(BigNumber(redeemInputAmount).multipliedBy(dynamicFeePercent / PERCENT_DENOMINATOR / 100))} JWLUSD) will be charged for redemption.`}
                        </div>
                        <div className='d-flex justify-content-center align-items-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleRedeem}>
                                {`Redeem`}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};


