import React, { FC, useEffect, useMemo, useState } from 'react';
import { JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData } from 'z/types';
import { ASH_LP_DECIMALS, calcYieldBoost, convertBigNumberValueToLocalString, getTokenTicker } from 'z/utils';
import BigNumber from 'bignumber.js';
import { queryViewJewelFarmData } from 'z/elrond';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';

interface FarmPoolProps {
    commonSettings: JewelFarmBaseContext | undefined,
    farm: JewelFarmContext,
    totalCurrentVeASH: string,
    veashBalance: string,
}

export const Boost: FC<FarmPoolProps> = ({
    commonSettings,
    farm,
    totalCurrentVeASH,
    veashBalance,
}) => {

    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();

    const [currentBoost, setCurrentBoost] = useState<string>('-');
    const [maxBoost, setMaxBoost] = useState<string>('-');

    const [farmPool, setFarmPool] = useState<JewelFarmContextData>();
    useEffect(() => {
        if (!commonSettings || !farm) return;

        (async () => {
            const _farmPool = await queryViewJewelFarmData(apiAddress, farm, commonSettings.ashswap_tokens);
            // console.log(_farmPool);
            if (_farmPool) {
                setFarmPool(_farmPool);
            }
        })();

    }, [farm, commonSettings]);

    // const [lpValue, setLpValue] = useState<string>('0');
    // const [TVLExcludeOwnLPValue, setTVLExcludeOwnLPValue] = useState<string>('0');

    // const totalLPExcludeOwnLPWei = useMemo(() => {
    //     if (!farmPool) return new BigNumber(0);
    //     return BigNumber(TVLExcludeOwnLPValue).multipliedBy(farmPool.lp_locked_amt).div(
    //         farmPool.total_liquidity_value
    //     );
    // }, [farmPool, TVLExcludeOwnLPValue]);

    // const lpWei = useMemo(() => {
    //     return BigNumber(lpValue)
    //         .multipliedBy(farmPool.lp_locked_amt)
    //         .div(farmPool.total_liquidity_value);
    // }, [lpValue, farmPool]);

    // const veForMaxBoost = useMemo(() => {
    //     return BigNumber(totalCurrentVeASH)
    //         .multipliedBy(lpValue)
    //         .div(TVLExcludeOwnLPValue);
    // }, [lpValue, totalCurrentVeASH, TVLExcludeOwnLPValue]);

    // const maxYieldBoost = useMemo(() => {
    //     // if (veForMaxBoost.eq(0)) return 1;
    //     const totalLP = lpWei.plus(totalLPExcludeOwnLPWei);
    //     const boost = calcYieldBoost(
    //         lpWei,
    //         totalLP,
    //         veForMaxBoost.multipliedBy(10 ** ASH_LP_DECIMALS),
    //         BigNumber(totalCurrentVeASH)
    //             .plus(veForMaxBoost)
    //             .multipliedBy(10 ** ASH_LP_DECIMALS),
    //         BigNumber(farmPool.farm_token_supply),
    //         BigNumber(farmPool.exist_farm_token_bal),
    //     );
    //     return boost;
    // }, [
    //     lpWei,
    //     veForMaxBoost,
    //     totalLPExcludeOwnLPWei,
    //     totalCurrentVeASH,
    //     farmPool.farm_token_supply,
    //     farmPool.exist_farm_token_bal,
    // ]);

    // const boost = useMemo(() => {
    //     return calcYieldBoost(
    //         lpWei,
    //         lpWei.plus(totalLPExcludeOwnLPWei),
    //         BigNumber(veashBalance),
    //         BigNumber(totalCurrentVeASH),
    //         BigNumber(farmPool.farm_token_supply),
    //         BigNumber(farmPool.exist_farm_token_bal)
    //     );
    // }, [
    //     lpWei,
    //     totalLPExcludeOwnLPWei,
    //     veashBalance,
    //     totalCurrentVeASH,
    //     farmPool.farm_token_supply,
    //     farmPool.exist_farm_token_bal,
    // ]);

    // useEffect(() => {
    //     const _totalStakedLpValue = farmPool.total_staked_lp_value;
    //     const _tvlExcludeOwnLPValue = BigNumber(farmPool.total_liquidity_value).minus(farmPool.total_staked_lp_value).toFixed();

    //     const _totalLPExcludeOwnLPWei = BigNumber(_tvlExcludeOwnLPValue).multipliedBy(farmPool.lp_locked_amt).div(farmPool.total_liquidity_value);
    //     const _lpWei = BigNumber(_totalStakedLpValue).multipliedBy(farmPool.lp_locked_amt).div(farmPool.total_liquidity_value);
    //     const _veForMaxBoost = BigNumber(totalCurrentVeASH).multipliedBy(_totalStakedLpValue).div(_tvlExcludeOwnLPValue);

    //     const _totalLP = _lpWei.plus(_totalLPExcludeOwnLPWei);
    //     const _maxYieldBoost = calcYieldBoost(
    //         _lpWei.toFixed(),
    //         _totalLP.toFixed(),
    //         _veForMaxBoost.multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
    //         BigNumber(totalCurrentVeASH)
    //             .plus(_veForMaxBoost)
    //             .multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
    //         farmPool.farm_token_supply,
    //         farmPool.exist_farm_token_bal,
    //     );
    //     const _boost = calcYieldBoost(
    //         _lpWei.toFixed(),
    //         _totalLP.toFixed(),
    //         veashBalance,
    //         totalCurrentVeASH,
    //         farmPool.farm_token_supply,
    //         farmPool.exist_farm_token_bal
    //     );

    //     setCurrentBoost(convertBigNumberValueToLocalString(_boost, 0));
    //     setMaxBoost(convertBigNumberValueToLocalString(_maxYieldBoost, 0));

    //     // setLpValue(_totalStakedLpValue);
    //     // setTVLExcludeOwnLPValue(_tvlExcludeOwnLPValue);
    // }, [farmPool, totalCurrentVeASH, veashBalance]);

    return (
        <div className={`row mt-2`}>
            <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>
                <div>{farm.token_ids.map((item: string, key: number) => {
                    let poolName = '';
                    if (key < (farm.token_ids.length - 1)) {
                        if (key === (farm.token_ids.length - 2)) {
                            poolName += getTokenTicker(item);
                        } else {
                            poolName += `${getTokenTicker(item)}-`;
                        }
                    }
                    return poolName;
                })}</div>
            </div>
            {/* <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Current: X${convertBigNumberValueToLocalString(boost, 0)}`}</div>
            <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Max: X${convertBigNumberValueToLocalString(maxYieldBoost, 0)}`}</div> */}
            {/* <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Current: X${currentBoost}`}</div>
            <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Max: X${maxBoost}`}</div> */}
            <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Current: ${farmPool ? `X${convertBigNumberValueToLocalString(farmPool.current_boost)}` : '-'}`}</div>
            <div className='col-lg-4 col-md-4 col-sm-12 mt-1'>{`Max: ${farmPool ? `X${convertBigNumberValueToLocalString(farmPool.max_yeild_boost)}` : '-'}`}</div>
        </div>
    );
};
