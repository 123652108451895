import React, { useEffect, useState, useContext } from 'react';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccount, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { animated, useSpring } from 'react-spring';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, InputAdornment, InputBase, Modal, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { isMobile } from 'react-device-detect';
import { GlobalPosition } from './GlobalPosition';
import { GlobalPool } from './GlobalPool';
import { queryJewelGlobalPools, queryJewelGlobalUserPools, queryJewelGlobalUserContext, sendJewelGlobalPoolSupply, sendJewelGlobalPoolWithdraw, sendJewelGlobalPoolBorrow, sendJewelGlobalPoolRepay, queryJewelGlobalPoolCommonContext, queryPriceFromJewelOracle } from 'z/elrond';
import BigNumber from 'bignumber.js';
import { JewelGlobalPoolContext, JewelGlobalUserPoolContext, JewelGlobalUserContext, JewelGlobalPoolCommonContext } from 'z/types';
import { useNavigate, useParams } from 'react-router-dom';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_EXCEED_MAX_VALUE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getJewelLendPoolApy, getLiquidStakeApy, getMoneyMarketType, getTokenDecimal, getTokenImage, getTokenTicker, hatomFormatRewards, parseBigNumber, PERCENT_DENOMINATOR, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import { getUserTokenBalance } from 'z/api';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown } from 'react-bootstrap';

import './index.scss';
import { contracts, isDev, JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP } from 'config';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const JewelGlobalMoneyMarket = () => {
  const {
    network: { apiAddress },
    chainID,
  } = useGetNetworkConfig();
  const { address, shard, balance } = useGetAccount();
  // const address = 'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z';
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  const [isSuppliedExpanded, setIsSuppliedExpanded] = useState<boolean>(false);
  const [isBorrowedExpanded, setIsBorrowedExpanded] = useState<boolean>(false);

  const [showSupplyModal, setShowSupplyModal] = useState<boolean>(false);
  const [isSupply, setIsSupply] = useState<boolean>(false);
  const [showBorrowModal, setShowBorrowModal] = useState<boolean>(false);
  const [isBorrow, setIsBorrow] = useState<boolean>(false);
  const [isHideAssets, setIsHideAssets] = useState<boolean>(false);

  const [userTokens, setUserTokens] = useState<any>();
  const [jewelGlobalCommonContext, setJewelGlobalCommonContext] = useState<JewelGlobalPoolCommonContext | undefined>();
  const [jewelGlobalPools, setJewelGlobalPools] = useState<JewelGlobalPoolContext[]>([]);
  const [jewelGlobalUserPools, setJewelGlobalUserPools] = useState<JewelGlobalUserPoolContext[]>([]);
  const [jewelGlobalUserContext, setJewelGlobalUserContext] = useState<JewelGlobalUserContext | undefined>();
  const [healthFactor, setHealthFactor] = useState<number>(0);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const context = await queryJewelGlobalPoolCommonContext();
      // console.log('global pool common context: ', context);
      setJewelGlobalCommonContext(context);
    })();
  }, [isLoggedIn, hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const pools = await queryJewelGlobalPools();
      // console.log('global pools: ', pools);

      // get token price from jewel oracle
      const prices = [];
      for (const pool of pools) {        
        const price = await queryPriceFromJewelOracle(pool.token_id);
        prices.push(price);
      }

      const globalPools = [];
      let index = 0;
      for (const pool of pools) {
        pool.token_price = prices[index];
        index++;
        globalPools.push(pool);
      }
      setJewelGlobalPools(globalPools);

      const userTokensBalance: any = [];
      const tokenIds: string[] = [];
      for (const pool of pools) {
        if (!tokenIds.includes(pool.token_id)) {
          tokenIds.push(pool.token_id);
        }
      }

      if (isLoggedIn) {
        const balances = await getUserTokenBalance(apiAddress, address, tokenIds);
        for (const item of balances) {
          userTokensBalance[item.identifier] = {
            balance: item.balance,
          };
        }
      }

      for (const item of tokenIds) {
        if (!userTokensBalance[item]) {
          userTokensBalance[item] = {
            balance: '0',
          };
        }
      }

      userTokensBalance['EGLD'] = {
        balance: balance,
      };

      // console.log('userTokensBalance: ', userTokensBalance);
      setUserTokens(userTokensBalance);
    })();
  }, [isLoggedIn, hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn) return;

    (async () => {
      const userPools = await queryJewelGlobalUserPools(address);
      // console.log('global user pools: ', userPools);
      setJewelGlobalUserPools(userPools);

      const userContext = await queryJewelGlobalUserContext(address);
      // console.log('global user context: ', userContext);
      setJewelGlobalUserContext(userContext);
    })();
  }, [isLoggedIn, hasPendingTransactions]);

  useEffect(() => {
    if (!jewelGlobalCommonContext || !isLoggedIn || !jewelGlobalUserContext) return;

    if (BigNumber(jewelGlobalUserContext.debt_value).gt(0)) {
      const health = BigNumber(jewelGlobalUserContext.supplied_value).multipliedBy(jewelGlobalCommonContext.liquidation_percent).dividedBy(100 * PERCENT_DENOMINATOR).dividedBy(jewelGlobalUserContext.debt_value);
      setHealthFactor(health.toNumber());
    } else {
      setHealthFactor(0);
    }
  }, [jewelGlobalCommonContext, jewelGlobalUserContext]);

  const onChangeHide = (e: any) => {
    setIsHideAssets(e.target.checked);
  };

  const [selectedMmPool, setSelectedMmPool] = useState<JewelGlobalPoolContext>();
  const [avaliableAmount, setAvaliableAmount] = useState<string>('');
  const [userBorrowedAmount, setUserBorrowedAmount] = useState<string>('');
  const [amount, setAmount] = useState<string>('0');
  const [amountError, setAmountError] = useState<string>('');

  const handleOpenSupplyModal = (is_supply: boolean, mm_pool: JewelGlobalPoolContext) => {
    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!jewelGlobalCommonContext || !jewelGlobalUserContext) return;

    setIsSupply(is_supply);
    let availAmount = '0';
    if (is_supply) {
      // base token amount of wallet
      let userBalance = userTokens && userTokens[mm_pool.token_id] ? userTokens[mm_pool.token_id].balance : '0';
      if (getTokenTicker(mm_pool.token_id) === 'EGLD') {
        if (BigNumber(userBalance).gt(EXTRA_GAS_FEE_AMOUNT)) {
          userBalance = BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed(0);
        } else {
          userBalance = '0';
        }
      }
      availAmount = userBalance;
    } else {
      // user withdrawble amount
      const poolAvailAmount = BigNumber(mm_pool.supplied_amount).gt(mm_pool.debt_amount) ? BigNumber(mm_pool.supplied_amount).minus(mm_pool.debt_amount) : BigNumber('0');
      const userMinSupplyValue = BigNumber(jewelGlobalUserContext.debt_value).multipliedBy(100 * PERCENT_DENOMINATOR).dividedBy(jewelGlobalCommonContext.max_debt_percent);
      const availWithdrawValue = BigNumber(jewelGlobalUserContext.supplied_value).gt(userMinSupplyValue) ? BigNumber(jewelGlobalUserContext.supplied_value).minus(userMinSupplyValue) : BigNumber('0');
      if (availWithdrawValue.gt('0')) {
        for (let i = 0; i < jewelGlobalUserPools.length; i++) {
          if (jewelGlobalUserPools[i].token_id === mm_pool.token_id) {
            let availWithdrawAmount = convertEsdtToWei(availWithdrawValue.dividedBy(mm_pool.token_price), getTokenDecimal(mm_pool.token_id));
            availWithdrawAmount = availWithdrawAmount.plus(availWithdrawAmount.multipliedBy(0.001));
            let withdrawAmount = availWithdrawAmount.gt(jewelGlobalUserPools[i].supplied_amount) ? BigNumber(jewelGlobalUserPools[i].supplied_amount) : availWithdrawAmount;
            if (withdrawAmount.gt(poolAvailAmount)) {
              withdrawAmount = poolAvailAmount;
            }

            availAmount = withdrawAmount.toFixed(0);
            break;
          }
        }
      }
    }

    setAvaliableAmount(availAmount);
    setSelectedMmPool(mm_pool);
    setAmount('0');
    setAmountError('');

    setShowSupplyModal(true);
  };

  const handleOpenBorrowModal = (is_borrow: boolean, mm_pool: JewelGlobalPoolContext) => {
    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!jewelGlobalCommonContext || !jewelGlobalUserContext) return;

    setIsBorrow(is_borrow);
    let availAmount = '0';
    if (is_borrow) {
      // avail borrow amount
      const userMaxBorrowValue = BigNumber(jewelGlobalUserContext.supplied_value).multipliedBy(jewelGlobalCommonContext.max_debt_percent).dividedBy(100 * PERCENT_DENOMINATOR);
      const userAvailBorrowValue = userMaxBorrowValue.minus(jewelGlobalUserContext.debt_value);
      let availBorrowAmount = convertEsdtToWei(userAvailBorrowValue.dividedBy(mm_pool.token_price), getTokenDecimal(mm_pool.token_id));
      availBorrowAmount = availBorrowAmount.minus(availBorrowAmount.multipliedBy(0.005));
      const poolAvailAmount = BigNumber(mm_pool.supplied_amount).gt(mm_pool.debt_amount) ? BigNumber(mm_pool.supplied_amount).minus(mm_pool.debt_amount) : BigNumber('0');
      if (availBorrowAmount.gt(poolAvailAmount)) {
        availBorrowAmount = poolAvailAmount;
      }
      availAmount = availBorrowAmount.toFixed(0);
    } else {
      // debt token amount of wallet
      let userBalance = userTokens && userTokens[mm_pool.token_id] ? userTokens[mm_pool.token_id].balance : '0';
      if (getTokenTicker(mm_pool.token_id) === 'EGLD') {
        if (BigNumber(userBalance).gt(EXTRA_GAS_FEE_AMOUNT)) {
          userBalance = BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed(0);
        } else {
          userBalance = '0';
        }
      }
      for (let i = 0; i < jewelGlobalUserPools.length; i++) {
        if (jewelGlobalUserPools[i].token_id === mm_pool.token_id) {
          const borrowedAmount = BigNumber(jewelGlobalUserPools[i].debt_amount).plus(BigNumber(jewelGlobalUserPools[i].debt_amount).multipliedBy(0.005)).toFixed(0);
          setUserBorrowedAmount(borrowedAmount);
          if (BigNumber(userBalance).gte(borrowedAmount)) {
            availAmount = borrowedAmount;
          } else {
            availAmount = userBalance;
          }
          break;
        }
      }
    }

    setAvaliableAmount(availAmount);
    setSelectedMmPool(mm_pool);
    setAmount('0');
    setAmountError('');

    setShowBorrowModal(true);
  };

  const handleMax = () => {
    onChangeAmount(avaliableAmount);
  };

  const checkAmount = (value: string) => {
    if (!selectedMmPool) return '';

    const bValue = parseBigNumber(value);
    let error = '';
    if (bValue == undefined) {
      error = 'Invalid number';
    } else if (bValue.comparedTo(0) < 0) {
      error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
    } else {
      if (bValue.comparedTo(avaliableAmount) > 0) {
        error = ERROR_EXCEED_MAX_VALUE;
      }
    }

    return error;
  };

  const onChangeAmount = (value: string) => {
    setAmount(value);
    const error = checkAmount(value);
    setAmountError(error);
  };

  const handleSupply = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    let wrapAddress = contracts.Wrap0.address;
    if (shard === 1) {
      wrapAddress = contracts.Wrap1.address;
    } else if (shard === 2) {
      wrapAddress = contracts.Wrap2.address;
    }

    await sendJewelGlobalPoolSupply(chainID, address, selectedMmPool.token_id, amount, wrapAddress);

    setShowSupplyModal(false);
  };

  const handleWithdraw = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    await sendJewelGlobalPoolWithdraw(chainID, address, selectedMmPool.token_id, amount);

    setShowSupplyModal(false);
  };

  const handleBorrow = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    // let isExceedMaxDebtCap = false;
    // for (let i = 0; i < jewelMoneyMarketPools.length; i++) {
    //   if (jewelMoneyMarketPools[i].base_token_id === selectedMmPool.base_token_id && jewelMoneyMarketPools[i].debt_token_id === selectedMmPool.debt_token_id) {
    //     const newBorrowAmount = BigNumber(jewelMoneyMarketPools[i].borrowed_amount).plus(amount);
    //     if (newBorrowAmount.gt(jewelMoneyMarketPools[i].max_debt_cap)) {
    //       isExceedMaxDebtCap = true;
    //     }

    //     break;
    //   }
    // }
    // if (isExceedMaxDebtCap) {
    //   toastError('Cannot borrow more than max cap');
    //   return;
    // }

    await sendJewelGlobalPoolBorrow(chainID, address, selectedMmPool.token_id, amount);

    setShowBorrowModal(false);
  };

  const handleRepay = async () => {
    if (!selectedMmPool) return;

    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    if (amount == '' || BigNumber(amount).comparedTo(0) <= 0) {
      toastError('Invalid amount');
      return;
    }

    const error = checkAmount(amount);
    if (error !== '') {
      toastError(error);
      return;
    }

    let wrapAddress = contracts.Wrap0.address;
    if (shard === 1) {
      wrapAddress = contracts.Wrap1.address;
    } else if (shard === 2) {
      wrapAddress = contracts.Wrap2.address;
    }

    await sendJewelGlobalPoolRepay(chainID, address, selectedMmPool.token_id, amount, wrapAddress);

    setShowBorrowModal(false);
  };

  return (
    <>
      <div className='container' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div style={{ fontSize: isMobile ? '24px' : '28px' }}>
              <span>Money Market (Global Pool)</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='user-positions container'>
            <div className='row mt-4' style={{ justifyContent: "center" }}>
              <Accordion
                expanded={isSuppliedExpanded}
              >
                <AccordionSummary
                  sx={{ cursor: 'auto !important' }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6 col-sm-12 user-positions-title'>My Supplied Positions</div>
                      <div className={`col-lg-7 col-md-5 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-3' : 'justify-content-end'}`}>
                        <div className='d-flex align-items-center positions-border'>
                          <div className='text-gray-color'>Supplied</div>
                          <div>{`$${jewelGlobalUserContext ? convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalUserContext.supplied_value, 8)) : '-'}`}</div>
                        </div>
                      </div>
                      <div className={`col-lg-1 col-md-1 col-sm-12 d-flex justify-content-end align-items-center ${isMobile ? 'mt-3 justify-content-center' : ''}`}>
                        {isSuppliedExpanded ? (
                          <KeyboardArrowUpIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsSuppliedExpanded(!isSuppliedExpanded)} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsSuppliedExpanded(!isSuppliedExpanded)} />
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: '0px 16px 16px !important' }}
                >
                  <div className={`d-flex mb-2 ${isMobile ? 'justify-content-start' : 'justify-content-end'}`}>
                    <Checkbox
                      checked={isHideAssets}
                      onChange={e => onChangeHide(e)}
                      sx={{ '&': { padding: '0px' } }}
                    />
                    <div className='mx-3'>{`Hide assets < 1 USD`}</div>
                  </div>
                  {
                    !isMobile && (
                      <div className='row mt-1 mb-2'>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Assets</div>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Supplied</div>
                        </div>
                        <div className='col-lg-2 com-md-2 col-sm-2 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>APY</div>
                        </div>
                        <div className='col-lg-4 com-md-4 col-sm-4'></div>
                      </div>
                    )
                  }
                  {
                    jewelGlobalUserPools.map((item: JewelGlobalUserPoolContext, index: number) => (
                      isHideAssets ? (
                        convertBigNumberValueToLocalString(convertWeiToEsdt(item.supplied_amount, getTokenDecimal(item.token_id))) !== '0' && (
                          <GlobalPosition
                            key={index}
                            pool={item}
                            isSupplied={true}
                            jewelGlobalCommonContext={jewelGlobalCommonContext}
                            jewelGlobalPools={jewelGlobalPools}
                            handleOpenModal={handleOpenSupplyModal}
                          />
                        )
                      ) : (
                        BigNumber(item.supplied_amount).gt('0') && (
                          <GlobalPosition
                            key={index}
                            pool={item}
                            isSupplied={true}
                            jewelGlobalCommonContext={jewelGlobalCommonContext}
                            jewelGlobalPools={jewelGlobalPools}
                            handleOpenModal={handleOpenSupplyModal}
                          />
                        )
                      )
                    ))
                  }
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='user-positions container'>
            <div className='row mt-4' style={{ justifyContent: "center" }}>
              <Accordion
                expanded={isBorrowedExpanded}
              >
                <AccordionSummary
                  sx={{ cursor: 'auto !important' }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6 col-sm-12 user-positions-title'>My Borrowed Positions</div>
                      <div className={`col-lg-7 col-md-5 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-3' : 'justify-content-end'}`}>
                        <div className='d-flex align-items-center positions-border' style={{ marginRight: '5px' }}>
                          <div className='text-gray-color'>Borrowed</div>
                          <div>{`$${jewelGlobalUserContext ? convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalUserContext.debt_value, 8)) : '-'}`}</div>
                        </div>
                        <div className='d-flex align-items-center positions-border'>
                          <div className='text-gray-color' style={{ marginRight: '4px' }}>Health</div>
                          <Tooltip
                            placement="top"
                            title="The health factor is calculated from user's collateral balance multiplied by current liquidation threshold for all the user's assets, divided by 100, divided by user's borrow balance/fees. Positions with health factor <1 will trigger liquidation."
                          >
                            <InfoCircleOutlined style={{ fontSize: '14px', cursor: 'pointer', marginRight: '8px' }} />
                          </Tooltip>
                          <div>{healthFactor === 0 ? '-' : convertBigNumberValueToLocalString(healthFactor, 2)}</div>
                        </div>
                      </div>
                      <div className={`col-lg-1 col-md-1 col-sm-12 d-flex justify-content-end align-items-center ${isMobile ? 'mt-3 justify-content-center' : ''}`}>
                        {isBorrowedExpanded ? (
                          <KeyboardArrowUpIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsBorrowedExpanded(!isBorrowedExpanded)} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsBorrowedExpanded(!isBorrowedExpanded)} />
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: '0px 16px 16px !important' }}
                >
                  {
                    !isMobile && (
                      <div className='row mt-1 mb-2'>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Assets</div>
                        </div>
                        <div className='col-lg-3 com-md-3 col-sm-3 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>Borrowed (Incl. interests)</div>
                        </div>
                        <div className='col-lg-2 com-md-2 col-sm-2 d-flex justify-content-center'>
                          <div className='d-flex text-gray-color'>APY</div>
                        </div>
                        <div className='col-lg-4 com-md-4 col-sm-4'></div>
                      </div>
                    )
                  }
                  {
                    jewelGlobalUserPools.map((item: JewelGlobalUserPoolContext, index: number) => (
                      BigNumber(item.debt_amount).gt('0') && (
                        <GlobalPosition
                          key={index}
                          pool={item}
                          isSupplied={false}
                          jewelGlobalCommonContext={jewelGlobalCommonContext}
                          jewelGlobalPools={jewelGlobalPools}
                          handleOpenModal={handleOpenBorrowModal}
                        />
                      )
                    ))
                  }
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='mt-5'>
            {
              !isMobile && (
                <div className='row' style={{ justifyContent: "center" }}>
                  <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center align-items-center'>
                    <div>Pool</div>
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center'>
                    <div className='d-flex'>
                      <div className='text-center'>Supply APY</div>
                    </div>
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center'>
                    <div className='d-flex'>
                      <div className='text-center'>Borrow APY</div>
                    </div>
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center'>
                    <div>LTV</div>
                  </div>
                  <div className='col-lg-3 col-md-3 col-sm-3 d-flex align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-6 text-center'>
                      <div className='text-center'>Total Supply</div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 text-center'>
                      <div className='text-center'>Total Borrowed /</div>
                      <div className='text-center'>Available</div>
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-end align-items-center'>
                    <div>Your Balance</div>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2'></div>
                </div>
              )
            }
            {
              userTokens && jewelGlobalPools.map((item: JewelGlobalPoolContext, index: number) => (
                <GlobalPool
                  key={index}
                  pool={item}
                  userTokens={userTokens}
                  jewelGlobalCommonContext={jewelGlobalCommonContext}
                  handleOpenSupplyModal={handleOpenSupplyModal}
                  handleOpenBorrowModal={handleOpenBorrowModal}
                />
              ))
            }
          </div>
        </div>
      </div>
      {
        selectedMmPool && (
          <>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showSupplyModal}
              onClose={() => setShowSupplyModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showSupplyModal}>
                {
                  isSupply ? (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Supply ${getTokenTicker(selectedMmPool.token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedMmPool.token_id)))} ${getTokenTicker(selectedMmPool.token_id)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.token_id), getTokenDecimal(selectedMmPool.token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleSupply}>
                          Supply
                        </div>
                      </div>
                    </Box>
                  ) : (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Withdraw ${getTokenTicker(selectedMmPool.token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedMmPool.token_id)))} ${getTokenTicker(selectedMmPool.token_id)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.token_id), getTokenDecimal(selectedMmPool.token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleWithdraw}>
                          Withdraw
                        </div>
                      </div>
                    </Box>
                  )
                }
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showBorrowModal}
              onClose={() => setShowBorrowModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showBorrowModal}>
                {
                  isBorrow ? (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Borrow ${getTokenTicker(selectedMmPool.token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. ${convertBigNumberValueToLocalString(convertWeiToEsdt(avaliableAmount, getTokenDecimal(selectedMmPool.token_id)))} ${getTokenTicker(selectedMmPool.token_id)}`}
                      </div>
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.token_id), getTokenDecimal(selectedMmPool.token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleBorrow}>
                          Borrow
                        </div>
                      </div>
                    </Box>
                  ) : (
                    <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
                      <div className='d-flex align-items-center'>
                        <div style={{ fontSize: '1.2rem' }}>{`Repay ${getTokenTicker(selectedMmPool.token_id)}`}</div>
                      </div>
                      <div className='d-flex mt-3 justify-content-start' style={{ color: 'gray' }}>
                        {`Avail. balance in Wallet: ${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens && userTokens[selectedMmPool.token_id] ? userTokens[selectedMmPool.token_id].balance : '0', getTokenDecimal(selectedMmPool.token_id)))} ${getTokenTicker(selectedMmPool.token_id)}`}
                      </div>
                      <div className='d-flex justify-content-start' style={{ color: 'gray' }}>
                        {`Position Debt: ${convertBigNumberValueToLocalString(convertWeiToEsdt(userBorrowedAmount, getTokenDecimal(selectedMmPool.token_id)))} ${getTokenTicker(selectedMmPool.token_id)}`}
                      </div>
                      {
                        userTokens && userTokens[selectedMmPool.token_id] && BigNumber(userBorrowedAmount).gt(userTokens[selectedMmPool.token_id].balance) && (
                          <div className='d-flex justify-content-start my-1' style={{ color: 'red' }}>
                            {`You are short of ${convertWeiToEsdt(BigNumber(userBorrowedAmount).minus(userTokens[selectedMmPool.token_id].balance), getTokenDecimal(selectedMmPool.token_id), getTokenDecimal(selectedMmPool.token_id))} ${getTokenTicker(selectedMmPool.token_id)} for full repayment.`}
                          </div>
                        )
                      }
                      <div className='d-flex mt-1'>
                        <InputBase
                          sx={{
                            flex: 1,
                            border: '1px',
                            borderStyle: 'solid',
                            borderRadius: '5px',
                            borderColor: 'gray',
                            padding: '2px 5px',
                          }}
                          endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMax}>Max</InputAdornment>}
                          type='number'
                          placeholder='0.00'
                          value={amount ? convertWeiToEsdt(amount, getTokenDecimal(selectedMmPool.token_id), getTokenDecimal(selectedMmPool.token_id)).toFixed() : ''}
                          onChange={(e: any) => onChangeAmount(e.target.value ? convertEsdtToWei(e.target.value, getTokenDecimal(selectedMmPool.token_id)).toFixed(0) : '')}
                        />
                      </div>
                      <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '0.8rem' }}>
                        {amountError}
                      </div>
                      <div className='d-flex justify-content-center mt-4'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleRepay}>
                          Repay
                        </div>
                      </div>
                    </Box>
                  )
                }
              </Fade>
            </Modal>
          </>
        )
      }
    </>
  );
};
