import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isMobile } from 'react-device-detect';
import BigNumber from 'bignumber.js';
import {
    queryJewelBtcEthStakeBaseContext, sendJewelBtcEthMint, sendJewelBtcEthRedeem, sendJewelBtcEthWithdraw, sendJewelBtcEthStake, sendJewelBtcEthUnstake, queryJewelBtcEthStakeUserContext
} from 'z/elrond';
import {
    ElrondStatsType,
    JewelBtcEthStakeBaseContext,
    NftWithIndexType
} from 'z/types';
import { Spinner } from 'components/Spinner';
import { StakeInput } from './StakeInput';
import {
    convertBigNumberValueToLocalString,
    convertEsdtToWei,
    convertWeiToEsdt,
    DAY_IN_SECONDS,
    ERROR_CONNECT_YOUR_WALLET,
    ERROR_INVALID_VALUE,
    getLiquidUnstakeTokenAttributes,
    getTokenDecimal,
    getTokenImage,
    getTokenTicker, getUnbondEndTimeString,
    printEsdt,
    SECOND_TO_MILLISECONDS,
    toastError
} from 'z/utils';
import wbtc_logo from 'assets/img/coin/wbtc.svg';

import './index.scss';
import { getElrondStatsFromApi, getNftsByCollectionFromApi, getUserTokenBalance } from 'z/api';
import { JEWEL_BTC_ETH_SC_DEPLOYED_TIMESTAMP, WBTC_TOKEN_ID } from 'config';

export const BtcStake = () => {

    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, balance } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const [networkConfig, setNetworkConfig] = useState<ElrondStatsType>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [commonSettings, setCommonSettings] = useState<JewelBtcEthStakeBaseContext | undefined>();
    const [userBaseTokenBalance, setUserBaseTokenBalance] = useState<string>('0');
    const [userJwlTokenBalance, setUserJwlTokenBalance] = useState<string>('0');
    const [userSjwlTokenBalance, setUserSjwlTokenBalance] = useState<string>('0');
    const [apy, setApy] = useState<string>('-');
    const [unbondingNfts, setUnbondingNfts] = useState<NftWithIndexType[]>([]);
    const [unbondedNfts, setUnbondedNfts] = useState<NftWithIndexType[]>([]);
    const [withdrawalAmount, setWithdrawalAmount] = useState<string>('0');

    const [isMintExpanded, setIsMintExpanded] = useState<boolean>(false);
    const [isStakeExpanded, setIsStakeExpanded] = useState<boolean>(false);

    const [inputAmount, setInputAmount] = useState<string>('0');
    const [amountError, setAmountError] = useState<string>('');
    const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
    const [unstakeAmountError, setUnstakeAmountError] = useState<string>('');
    const [unboundInputAmount, setUnboundInputAmount] = useState<string>('0');
    const [unboundAmountError, setUnbondAmountError] = useState<string>('');
    const [isStakeAfterLock, setIsStakeAfterLock] = useState<boolean>(false);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsLoading(true);
            const _commonSettings = await queryJewelBtcEthStakeBaseContext(WBTC_TOKEN_ID);
            // console.log('_commonSettings', _commonSettings);

            if (_commonSettings) {
                setCommonSettings(_commonSettings);

                if (BigNumber(_commonSettings.sjwl_token_pool_reserve).gt('0')) {
                    const _tokenAmount = convertEsdtToWei(1).multipliedBy(_commonSettings.jwl_token_pool_reserve).dividedBy(_commonSettings.sjwl_token_pool_reserve);
                    const _passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_BTC_ETH_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
                    const _apy = _tokenAmount.minus(convertEsdtToWei(1)).dividedBy(_passedDays).multipliedBy(365).multipliedBy(100);
                    setApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
                }

                if (isLoggedIn) {
                    const tokenBalances = await getUserTokenBalance(apiAddress, address, [_commonSettings.base_token_id, _commonSettings.jwl_token_id]);
                    // console.log('tokenBalances: ', tokenBalances);

                    let baseAmount = '0';
                    let jwlAmount = '0';
                    for (let i = 0; i < tokenBalances.length; i++) {
                        const tokenBalance = tokenBalances[i];
                        if (tokenBalance.identifier === _commonSettings.base_token_id) {
                            if (tokenBalance.balance) baseAmount = tokenBalance.balance;
                        }

                        if (tokenBalance.identifier === _commonSettings.jwl_token_id) {
                            if (tokenBalance.balance) jwlAmount = tokenBalance.balance;
                        }
                    }

                    setUserBaseTokenBalance(baseAmount);
                    setUserJwlTokenBalance(jwlAmount);

                    const _userContext = await queryJewelBtcEthStakeUserContext(_commonSettings.base_token_id, address);
                    console.log('_userContext: ', _userContext);

                    if (_userContext) {
                        setUserSjwlTokenBalance(_userContext.user_sjwl_amount);
                    }

                    const _unstakeNfts = await getNftsByCollectionFromApi(apiAddress, address, _commonSettings.unstake_token_id);
                    // console.log('_unstakeNfts: ', _unstakeNfts);

                    const _networkConfig = await getElrondStatsFromApi();
                    // console.log('networkConfig: ', _networkConfig);
                    if (_networkConfig) {
                        setNetworkConfig(_networkConfig);

                        let _withdrawalAmount = BigNumber('0');
                        const _unbondingNfts: NftWithIndexType[] = [];
                        const _unbondedNfts: NftWithIndexType[] = [];
                        for (const item of _unstakeNfts) {
                            const attribute = getLiquidUnstakeTokenAttributes(item);
                            if (_networkConfig.epoch >= attribute.unbond_epoch) {
                                _withdrawalAmount = _withdrawalAmount.plus(attribute.unstake_amount);
                                _unbondedNfts.push(item);
                            } else {
                                _unbondingNfts.push(item);
                            }
                        }

                        setWithdrawalAmount(_withdrawalAmount.toFixed());
                        setUnbondingNfts(_unbondingNfts);
                        setUnbondedNfts(_unbondedNfts);
                    }
                }
            }
            setIsLoading(false);

        })();
    }, [hasPendingTransactions, address]);

    const handleMintExpand = () => {
        cleanMintAmount();

        setIsMintExpanded(!isMintExpanded);
    };

    const handleStakeExpand = () => {
        cleanStakeAmount();

        setIsStakeExpanded(!isStakeExpanded);
    };

    const cleanMintAmount = () => {
        setInputAmount('0');
        setAmountError('');
        setUnboundInputAmount('0');
        setUnbondAmountError('');
    };

    const cleanStakeAmount = () => {
        setStakeInputAmount('0');
        setStakeAmountError('');
        setUnstakeInputAmount('0');
        setUnstakeAmountError('');
    };

    const onMint = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(inputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (amountError) {
            toastError(amountError);
            return;
        }

        await sendJewelBtcEthMint(chainID, address, commonSettings.base_token_id, convertEsdtToWei(inputAmount, getTokenDecimal(commonSettings.base_token_id)).toFixed());

        cleanMintAmount();

    };

    const onRedeem = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(unboundInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unboundAmountError) {
            toastError(unboundAmountError);
            return;
        }

        await sendJewelBtcEthRedeem(chainID, address, commonSettings.base_token_id, commonSettings.jwl_token_id, convertEsdtToWei(unboundInputAmount).toFixed());

        cleanMintAmount();
    };

    const onWithdraw = async () => {
        if (!commonSettings) return;
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(withdrawalAmount).lte('0') || unbondedNfts.length === 0) {
            return;
        }

        await sendJewelBtcEthWithdraw(chainID, address, commonSettings.base_token_id, unbondedNfts);
    };

    const onStake = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (stakeAmountError) {
            toastError(stakeAmountError);
            return;
        }

        await sendJewelBtcEthStake(chainID, address, commonSettings.base_token_id, commonSettings.jwl_token_id, convertEsdtToWei(stakeInputAmount, getTokenDecimal(commonSettings.jwl_token_id)).toFixed());

        cleanStakeAmount();
    };

    const onUnstake = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unstakeAmountError) {
            toastError(unstakeAmountError);
            return;
        }

        await sendJewelBtcEthUnstake(chainID, address, commonSettings.base_token_id, convertEsdtToWei(unstakeInputAmount, getTokenDecimal(commonSettings.jwl_token_id)).toFixed());

        cleanStakeAmount();

    };

    return (
        <div>
            <div className='d-flex mt-2'>
                {`Mint BTC for JWLBTC 1:1 which will be sent for Hatom supply, collateral activation when able to. A dynamic % will be sent for LP creation. Stake JWLBTC for staking yield. Unstaking is instant. Redeem your JWLBTC for BTC on a 1:1 basis with a 10-day unbonding period.`}
            </div>
            <div className='d-flex mt-1'>
                <span>
                    {`You will be able to swap BTC-JWLBTC at `}
                    <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                </span>
            </div>

            {isLoading ? (
                <div className='mt-5'>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className='row mt-3' style={{ justifyContent: "center" }}>
                        <Accordion
                            expanded={isMintExpanded}
                        >
                            <AccordionSummary
                                className={`col-12`}
                                sx={{ cursor: 'auto !important' }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={getTokenImage(WBTC_TOKEN_ID)} className='egld-image mx-2' alt='wbtc'></img>
                                            <div>
                                                <div>{`WBTC`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-3 col-md-3 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-1' : 'align-self-center'}`}>
                                        <div>{`TVL`}</div>
                                        <div className=''>{commonSettings ? `${printEsdt(convertWeiToEsdt(commonSettings.jwl_token_supply, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} ${getTokenTicker(commonSettings.jwl_token_id)}` : '-'}</div>
                                    </div>
                                    <div className={`col-lg-4 col-md-4 col-sm-12 align-self-center`}>
                                        <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                            <div className='d-flex align-items-center'>Your balance.</div>
                                            <div className='text-end'>
                                                <div>{commonSettings ? `${printEsdt(convertWeiToEsdt(userBaseTokenBalance, getTokenDecimal(commonSettings.base_token_id), getTokenDecimal(commonSettings.base_token_id)), getTokenDecimal(commonSettings.base_token_id))} ${getTokenTicker(commonSettings.base_token_id)}` : '-'}</div>
                                                <div>{commonSettings ? `${printEsdt(convertWeiToEsdt(userJwlTokenBalance, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} ${getTokenTicker(commonSettings.jwl_token_id)}` : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                        <div
                                            className="px-2 py-2 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }} onClick={handleMintExpand}
                                        >
                                            <div className='d-flex'>Mint / Redeem</div>
                                            {isMintExpanded ? (
                                                <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                            ) : (
                                                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className='row'
                                sx={{ padding: '0px 16px 16px !important' }}
                            >
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                            <div className='d-flex'>
                                                {`Avail WBTC Balance: ${commonSettings ? `${printEsdt(convertWeiToEsdt(userBaseTokenBalance, getTokenDecimal(commonSettings.base_token_id), getTokenDecimal(commonSettings.base_token_id)), getTokenDecimal(commonSettings.base_token_id))} ${getTokenTicker(commonSettings.base_token_id)}` : '-'}`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                            <StakeInput
                                                userBalance={userBaseTokenBalance}
                                                inputAmount={inputAmount}
                                                amountError={amountError}
                                                setInputAmount={setInputAmount}
                                                setAmountError={setAmountError}
                                                isCheckBox={false}
                                                isStakeAfterLock={isStakeAfterLock}
                                                setIsStakeAfterLock={setIsStakeAfterLock}
                                                isUnstake={false}
                                                tokenId={commonSettings ? commonSettings.base_token_id : ''}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onMint}>
                                                    {`Mint`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                            <div className='d-flex'>
                                                {`Avail JWLBTC Balance: ${commonSettings ? `${printEsdt(convertWeiToEsdt(userJwlTokenBalance, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} ${getTokenTicker(commonSettings.jwl_token_id)}` : '-'}`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                            <StakeInput
                                                userBalance={userJwlTokenBalance}
                                                inputAmount={unboundInputAmount}
                                                amountError={unboundAmountError}
                                                setInputAmount={setUnboundInputAmount}
                                                setAmountError={setUnbondAmountError}
                                                isCheckBox={false}
                                                isStakeAfterLock={isStakeAfterLock}
                                                setIsStakeAfterLock={setIsStakeAfterLock}
                                                isUnstake={false}
                                                tokenId={commonSettings ? commonSettings.jwl_token_id : ''}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onRedeem}>
                                                    {`Redeem`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className='d-flex justify-content-center'>
                                                <div>{`Unbonding Period`}</div>
                                            </div>
                                            {
                                                !networkConfig || unbondingNfts.length === 0 ? (
                                                    <div className='d-flex justify-content-center mt-4'>
                                                        <div>{`-`}</div>
                                                    </div>
                                                ) : (
                                                    unbondingNfts.map((item: NftWithIndexType, index: number) => {
                                                        const attribute = getLiquidUnstakeTokenAttributes(item);
                                                        return <div className='d-flex justify-content-between mt-1' key={index}>
                                                            <div>{`• ${printEsdt(convertWeiToEsdt(attribute.unstake_amount, 18, 18), 18)} WBTC`}</div>
                                                            <div>{`${getUnbondEndTimeString(attribute.unbond_epoch, networkConfig)}`}</div>
                                                        </div>;
                                                    })
                                                )
                                            }
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className='d-flex justify-content-center'>
                                                <div>{`Unbonded: ${printEsdt(convertWeiToEsdt(withdrawalAmount, 18, 18), 18)} WBTC`}</div>
                                            </div>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onWithdraw}>
                                                    {`Withdraw`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='row mt-3' style={{ justifyContent: "center" }}>
                        <Accordion
                            expanded={isStakeExpanded}
                        >
                            <AccordionSummary
                                className={`col-12`}
                                sx={{ cursor: 'auto !important' }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={getTokenImage('JWLBTC-')} className='egld-image mx-2' alt='wbtc'></img>
                                            <div>
                                                <div>{`JWLBTC`}</div>
                                                <div style={{ fontSize: '0.8rem' }}>{`1 SJWLBTC = ${commonSettings && BigNumber(commonSettings.sjwl_token_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(BigNumber(commonSettings.jwl_token_pool_reserve).dividedBy(commonSettings.sjwl_token_pool_reserve), 5) : '-'} JWLBTC`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-3 col-md-3 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-3' : 'align-self-center'}`}>
                                        <div>{`APY`}</div>
                                        <div className='avaliable-color'>{`${apy} %`}</div>
                                    </div>
                                    <div className={`col-lg-4 col-md-4 col-sm-12 align-self-center`}>
                                        <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                            <div className='d-flex align-items-center'>Staked balance.</div>
                                            <div className='text-end'>
                                                <div>{commonSettings ? `${printEsdt(convertWeiToEsdt(userSjwlTokenBalance, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} SJWLBTC` : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-1 col-md-1 col-se-12'></div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                        <div
                                            className="px-2 py-2 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }} onClick={handleStakeExpand}
                                        >
                                            <div className='d-flex'>Stake / Unstake</div>
                                            {isStakeExpanded ? (
                                                <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                            ) : (
                                                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className='row'
                                sx={{ padding: '0px 16px 16px !important' }}
                            >
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                            <div className='d-flex'>
                                                {`Avail JWLBTC Balance: ${commonSettings ? `${printEsdt(convertWeiToEsdt(userJwlTokenBalance, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} ${getTokenTicker(commonSettings.jwl_token_id)}` : '-'}`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                            <StakeInput
                                                userBalance={userJwlTokenBalance}
                                                inputAmount={stakeInputAmount}
                                                amountError={stakeAmountError}
                                                setInputAmount={setStakeInputAmount}
                                                setAmountError={setStakeAmountError}
                                                isCheckBox={false}
                                                isStakeAfterLock={isStakeAfterLock}
                                                setIsStakeAfterLock={setIsStakeAfterLock}
                                                isUnstake={false}
                                                tokenId={commonSettings ? commonSettings.jwl_token_id : ''}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onStake}>
                                                    {`Stake`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                            <div className='d-flex'>
                                                {`SJWLBTC Balance: ${commonSettings ? `${printEsdt(convertWeiToEsdt(userSjwlTokenBalance, getTokenDecimal(commonSettings.jwl_token_id), getTokenDecimal(commonSettings.jwl_token_id)), getTokenDecimal(commonSettings.jwl_token_id))} SJWLBTC` : '-'}`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                            <StakeInput
                                                userBalance={userSjwlTokenBalance}
                                                inputAmount={unstakeInputAmount}
                                                amountError={unstakeAmountError}
                                                setInputAmount={setUnstakeInputAmount}
                                                setAmountError={setUnstakeAmountError}
                                                isCheckBox={false}
                                                isStakeAfterLock={isStakeAfterLock}
                                                setIsStakeAfterLock={setIsStakeAfterLock}
                                                isUnstake={true}
                                                tokenId={commonSettings ? commonSettings.jwl_token_id : ''}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onUnstake}>
                                                    {`Unstake`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'></div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
            )}
        </div>
    );
};
