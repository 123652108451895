import { TokenTransfer } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import abiJson from 'assets/abi/jewel-liquid-restaking.abi.json';
import { JEWEL_LIQUID_RESTAKING_ADDRESS } from 'config';
import { EsdtTokenPayment } from 'z/types';
import { parseEsdtTokenPayment } from '../common';
import { createSmartContract, mvxQuery, mvxSendTransaction } from "../provider";

export interface LiquidRestakingStatsContext {
  rsegld_pool_reserve: string,
  srsegld_pool_reserve: string,
}

export enum LiquidRestakingTokenType {
  Idle = 'Idle',
  Hatom = 'Hatom',
  Ashswap = 'Ashswap',
  Onedex = 'Onedex',
}

export interface LiquidRestakingPoolContext {
  token_id: string,
  token_type: LiquidRestakingTokenType,
  token_reserve: BigNumber,
  farm_token_payment: EsdtTokenPayment | undefined,
}

export function parsePoolContext(value: any): LiquidRestakingPoolContext {
  return {
    token_id: value.token_id.toString(),
    token_type: value.token_type.name as LiquidRestakingTokenType,
    token_reserve: value.token_reserve as BigNumber,
    farm_token_payment: value.opt_farm_token_payment ? parseEsdtTokenPayment(value.opt_farm_token_payment) : undefined,
  };
}

export const smartContract = createSmartContract(abiJson, JEWEL_LIQUID_RESTAKING_ADDRESS);
export class LiquidRestakingContract {
  async viewStatsContext(): Promise<LiquidRestakingStatsContext | undefined> {
    try {
      const value = await mvxQuery(smartContract.methods.viewStatsContext());
      const decoded = {
        rsegld_pool_reserve: value.rsegld_pool_reserve.toFixed(),
        srsegld_pool_reserve: value.srsegld_pool_reserve.toFixed(),
      };

      return decoded;
    } catch (err) {
      console.error(`${LiquidRestakingContract.name}.viewStatsContext:`, err);
      return undefined;
    }
  }

  async viewPoolContexts(): Promise<LiquidRestakingPoolContext[]> {
    try {
      const values = await mvxQuery(smartContract.methods.viewPoolContexts());
      const decoded = values.map((value: any) => parsePoolContext(value));

      return decoded;
    } catch (err) {
      console.error(`${LiquidRestakingContract.name}.viewPoolContexts:`, err);
      return [];
    }
  }

  async getEquivalent(
    tokenIn: string,
    amountIn: BigNumber,
  ): Promise<BigNumber | undefined> {
    try {
      const value = await mvxQuery(smartContract.methods.getEquivalent([
        tokenIn,
        amountIn,
      ]));
      const decoded = value as BigNumber;

      return decoded;
    } catch (err) {
      console.error(`${LiquidRestakingContract.name}.getEquivalent:`, err);
      return undefined;
    }
  }

  async mintRsegld(
    paymentIn: TokenTransfer,
  ) {
    const interaction = smartContract.methods.mintRsegld();
    const payments = [
      paymentIn
    ];
    await mvxSendTransaction({
      interaction,
      payments,
      gasLimit: 100_000_000,
      txName: "Mint rsEGLD"
    });
  }

  async mintRsegldFromEgld(
    amountIn: BigNumber,
  ) {
    const interaction = smartContract.methods.mintRsegldFromEgld();
    await mvxSendTransaction({
      interaction,
      value: amountIn,
      gasLimit: 100_000_000,
      txName: "Mint rsEGLD"
    });
  }

  async stakeRsegld(
    paymentIn: TokenTransfer,
  ) {
    const interaction = smartContract.methods.stake();
    const payments = [
      paymentIn
    ];
    await mvxSendTransaction({
      interaction,
      payments,
      gasLimit: 400_000_000,
      txName: "Stake rsEGLD"
    });
  }

  async unstakeRsegld(
    paymentIn: TokenTransfer,
  ) {
    const interaction = smartContract.methods.unstake();
    const payments = [
      paymentIn
    ];
    await mvxSendTransaction({
      interaction,
      payments,
      gasLimit: 400_000_000,
      txName: "Unstake rsEGLD"
    });
  }
}
