import React, { FC, useEffect, useState } from 'react';
import { InputAdornment, InputBase, MenuItem, Select } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, ERROR_NOT_ENOUGH_BALANCE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getTokenDecimal, getTokenImage, getTokenTicker, parseBigNumber } from 'z/utils';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';

interface StakeInputProps {
    userBalance: string,
    inputAmount: string,
    amountError: string,
    setInputAmount: (value: string) => void,
    setAmountError: (value: string) => void,
    tokenId: string,
    isCheckBox?: boolean,
    isStakeAfterLock?: boolean,
    setIsStakeAfterLock?: (value: boolean) => void,
    isUnstake: boolean,
    lockReleaseDate?: Date | undefined,
}

export const StakeInput: FC<StakeInputProps> = ({
    userBalance,
    inputAmount,
    amountError,
    setInputAmount,
    setAmountError,
    tokenId,
    isStakeAfterLock,
    setIsStakeAfterLock,
    isCheckBox,
    isUnstake,
    lockReleaseDate,
}) => {
    const isLoggedIn = useGetIsLoggedIn();

    const onChangeAmount = (value: string) => {
        if (!isLoggedIn) return;

        let error = '';
        if (value === '') {
            error = 'Invalid number';
        }
        const bValue = parseBigNumber(value);
        if (bValue == undefined) {
            error = 'Invalid number';
        } else if (bValue.comparedTo(0) < 0) {
            error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
        } else {
            if (tokenId === 'EGLD') {
                if (convertEsdtToWei(bValue).comparedTo(BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT)) >= 0) error = ERROR_NOT_ENOUGH_BALANCE;
            } else {
                if (convertEsdtToWei(bValue, getTokenDecimal(tokenId)).gt(userBalance)) error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        setAmountError(error);
        setInputAmount(value);

    };

    const handleMaxAmount = () => {
        if (!isLoggedIn) return;

        if (tokenId === 'EGLD') {
            onChangeAmount(
                BigNumber(userBalance).comparedTo(EXTRA_GAS_FEE_AMOUNT) <= 0
                    ? '0'
                    : convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(userBalance).minus(EXTRA_GAS_FEE_AMOUNT), 18, 18))
            );
        } else {
            onChangeAmount(convertWeiToEsdt(userBalance, getTokenDecimal(tokenId), getTokenDecimal(tokenId)).toFixed());
        }
    };

    return (
        <>
            <div className='d-flex mt-1'>
                <InputBase
                    sx={{
                        flex: 1,
                        border: '1px',
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        borderColor: 'gray',
                        padding: '2px 5px',
                    }}
                    endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleMaxAmount}>Max</InputAdornment>}
                    type='number'
                    placeholder='0.00'
                    value={inputAmount}
                    onChange={(e: any) => onChangeAmount(e.target.value)}
                />
            </div>
            {/* <div className='d-flex mt-3 w-100'>
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label=''
                    defaultValue={0}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': { padding: 1 },
                    }}
                >
                    <MenuItem value={0}>
                        <div className='d-flex justify-content-between align-items-center w-100 lend-modal'>
                            <div className='d-flex align-items-center'>
                                <img className='egld-image' src={getTokenImage('XMEX-')} alt='xmex' />
                                <div className='mx-2'>XMEX #1</div>
                            </div>
                            <div>10000</div>
                        </div>
                    </MenuItem>
                    <MenuItem value={1}>
                        <div className='d-flex justify-content-between align-items-center w-100 lend-modal'>
                            <div className='d-flex align-items-center'>
                                <img className='egld-image' src={getTokenImage('XMEX-')} alt='xmex' />
                                <div className='mx-2'>XMEX #2</div>
                            </div>
                            <div>20000</div>
                        </div>
                    </MenuItem>
                </Select>
            </div> */}
            <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                {amountError}
            </div>
            {isCheckBox && setIsStakeAfterLock && (
                <div className='d-flex mt-1'>
                    <Checkbox
                        checked={isStakeAfterLock}
                        onChange={e => setIsStakeAfterLock(e.target.checked)}
                        sx={{ '&': { padding: '0px', marginRight: '3px' } }}
                    />
                    <div className='d-flex'>{isStakeAfterLock ? `You will stake ${getTokenTicker(tokenId)} after lock` : `You will get ${getTokenTicker(tokenId)} after lock`}</div>
                </div>
            )}
            {isUnstake && tokenId && lockReleaseDate && (
                <div className='d-flex mt-1'>
                    {new Date() >= lockReleaseDate ? `You can unstake ${getTokenTicker(tokenId)} now` : `You can unstake ${getTokenTicker(tokenId)} after ${lockReleaseDate.toLocaleString()}`}
                </div>
            )}
        </>
    );
};
