import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Fade, Modal } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isMobile } from 'react-device-detect';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import BigNumber from 'bignumber.js';
import { queryLiquidStakeBaseContext, queryLiquidStakStatsContext, sendJewelJwlEgldStake, sendJewelJwlEgldUnstake, sendJewelLiquidStake, sendJewelUnbond, sendJewelLiquidUnstake, queryLiquidStakDynamicFee } from 'z/elrond';
import { ElrondStatsType, JewelLiquidStakeBaseContext, JewelLiquidStakeStatsContext, NftWithIndexType } from 'z/types';
import { Spinner } from 'components/Spinner';
import { StakeInput } from './StakeInput';
import {
    convertBigNumberValueToLocalString,
    convertEsdtToWei,
    convertSecondsToPrintDateTimeString,
    convertWeiToEsdt,
    DAY_IN_SECONDS,
    ERROR_CONNECT_YOUR_WALLET,
    ERROR_INVALID_VALUE,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_VALUE_CANNOT_BE_NEGATIVE,
    EXTRA_GAS_FEE_AMOUNT,
    getApr,
    getApy,
    getFarmApy,
    getFarmTokenIds,
    getLiquidStakeApy,
    getLiquidUnstakeTokenAttributes,
    getTokenDecimal,
    getTokenImage,
    getTokenTicker,
    getUnbondEndTimeString,
    isUnbondable,
    MAX_PERCENTAGE,
    parseBigNumber,
    PERCENT_DENOMINATOR,
    SECOND_TO_MILLISECONDS,
    toastError
} from 'z/utils';
import jwlegld_logo from 'assets/img/coin/jwlegld.svg';
import './index.scss';
import { getAccountDelegation, getElrondStatsFromApi, getJewelLendAprs, getNftsByCollectionFromApi, getStakingProviderApr, getUserTokenBalance } from 'z/api';
import { ADMIN_ADDRESS, isDev, JEWEL_LIQUID_STAKE_SC_ADDRESS, JEWEL_LIQUID_STAKE_SC_DEPLOYED_TIMESTAMP } from 'config';
import { SP_METADATA } from 'data';
import { routeNames } from 'routes';
import { JewelHistory } from 'z/types/history';

export const LiquidStake = () => {

    const {
        network: { apiAddress },
        chainID,
    } = useGetNetworkConfig();
    const { address, balance } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();

    const handleGoGauge = () => {
        navigate(routeNames.gauge);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [networkConfig, setNetworkConfig] = useState<ElrondStatsType>();
    const [liquidStakeBaseContext, setLiquidStakeBaseContext] = useState<JewelLiquidStakeBaseContext>();
    const [liquidStakeStatsContext, setLiquidStakeStatsContext] = useState<JewelLiquidStakeStatsContext>();
    const [jwlEgldTokenAmount, setJwlEgldTokenAmount] = useState<string>('0');
    const [unstakeNfts, setUnstakeNfts] = useState<NftWithIndexType[]>([]);
    const [unbondingNfts, setUnbondingNfts] = useState<NftWithIndexType[]>([]);
    const [unbondedNfts, setUnbondedNfts] = useState<NftWithIndexType[]>([]);
    const [withdrawlAmount, setWithdrawlAmount] = useState<string>('0');
    const [sjwlEgldTokenAmount, setSjwlEgldTokenAmount] = useState<string>('0');
    // const [stakingProviderApr, setStakingProviderApr] = useState<number>(0);
    const [apy, setApy] = useState<string>('-');

    const [isLiquidStakeExpanded, setIsLiquidStakeExpanded] = useState<boolean>(false);

    const [liquidStakeInputAmount, setLiquidStakeInputAmount] = useState<string>('0');
    const [liquidStakeInputAmountError, setLiquidStakeInputAmountError] = useState<string>('');
    const [liquidUnstakeInputAmount, setLiquidUnstakeInputAmount] = useState<string>('0');
    const [liquidUnstakeInputAmountError, setLiquidUnstakeInputAmountError] = useState<string>('');

    const [isStakeExpanded, setIsStakeExpanded] = useState<boolean>(false);
    const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
    const [stakeInputAmountError, setStakeInputAmountError] = useState<string>('');
    const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
    const [unstakeInputAmountError, setUnstakeInputAmountError] = useState<string>('');
    const [redeemFeeModal, setRedeemFeeModal] = useState<boolean>(false);
    const [dynamicFeePercent, setDynamicFeePercent] = useState<number>(0);

    useEffect(() => {
        (async () => {
            const _liquidStakeCommonSettings = await queryLiquidStakeBaseContext();
            // console.log('_liquidStakeCommonSettings', _liquidStakeCommonSettings);

            if (_liquidStakeCommonSettings) {
                setLiquidStakeBaseContext(_liquidStakeCommonSettings);
            }
        })();
    }, []);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _liquidStakeStatsContext = await queryLiquidStakStatsContext();
            // console.log('_liquidStakeStatsContext', _liquidStakeStatsContext);

            if (_liquidStakeStatsContext) {
                setLiquidStakeStatsContext(_liquidStakeStatsContext);

                // const _providerApr = await getStakingProviderApr(apiAddress, liquidStakeBaseContext.auto_delegate_sp_address);
                // console.log('_providerApr: ', _providerApr);
                // setStakingProviderApr(_providerApr);

                const _apy = getLiquidStakeApy(_liquidStakeStatsContext.jwlegld_pool_reserve, _liquidStakeStatsContext.sjwlegld_pool_reserve);
                setApy(convertBigNumberValueToLocalString(convertWeiToEsdt(_apy)));
            }
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions || !liquidStakeBaseContext || !isLoggedIn) return;

        (async () => {
            const _tokenIds = [liquidStakeBaseContext.jwlegld_token_id, liquidStakeBaseContext.sjwlegld_token_id];
            const _userBalance = await getUserTokenBalance(apiAddress, address, _tokenIds);
            // console.log('_userBalance: ', _userBalance);

            let jwlegldAmount = '0';
            let sjwlegldAmount = '0';
            for (const item of _userBalance) {
                if (item.identifier === liquidStakeBaseContext.jwlegld_token_id && item.balance) {
                    jwlegldAmount = item.balance;
                } else if (item.identifier === liquidStakeBaseContext.sjwlegld_token_id && item.balance) {
                    sjwlegldAmount = item.balance;
                }
            }
            setJwlEgldTokenAmount(jwlegldAmount);
            setSjwlEgldTokenAmount(sjwlegldAmount);

            const _unstakeNfts = await getNftsByCollectionFromApi(apiAddress, address, liquidStakeBaseContext.unstake_token_id);
            // console.log('_unstakeNfts: ', _unstakeNfts);
            if (_unstakeNfts.length > 0) {
                setUnstakeNfts(_unstakeNfts);
            }

            const _networkConfig = await getElrondStatsFromApi();
            // console.log('networkConfig: ', _networkConfig);
            if (_networkConfig) {
                setNetworkConfig(_networkConfig);

                let _withdrawlAmount = BigNumber('0');
                const _unbondingNfts: NftWithIndexType[] = [];
                const _unbondedNfts: NftWithIndexType[] = [];
                for (const item of _unstakeNfts) {
                    const attribute = getLiquidUnstakeTokenAttributes(item);
                    if (isUnbondable(attribute.unbond_epoch, _networkConfig)) {
                        _withdrawlAmount = _withdrawlAmount.plus(attribute.unstake_amount);
                        _unbondedNfts.push(item);
                    } else {
                        _unbondingNfts.push(item);
                    }
                }

                setWithdrawlAmount(_withdrawlAmount.toFixed());
                setUnbondingNfts(_unbondingNfts);
                setUnbondedNfts(_unbondedNfts);
            }

        })();
    }, [hasPendingTransactions, liquidStakeBaseContext]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        cleanLiquidStakeAmount();
        cleanStakeAmount();
    }, [hasPendingTransactions]);

    // get apr from api
    const [realApr, setRealApr] = useState<number>(0);
    useEffect(() => {
        (async () => {
            const aprs = await getJewelLendAprs();

            for (const apr of aprs) {
                if (apr.name === 'SJWLEGLD') {
                    setRealApr(apr.aprs[0].apr);
                    break;
                }
            }
        })();
    }, [hasPendingTransactions]);

    // useEffect(() => {
    //     if (hasPendingTransactions) return;

    //     (async () => {
    //         const _accountDelegation = await getAccountDelegation(apiAddress);
    //         console.log('_accountDelegation: ', _accountDelegation);
    //     })();
    // }, [hasPendingTransactions]);

    const handleOpenFeeModal = async () => {
        (async () => {
            const fee = await queryLiquidStakDynamicFee();
            setDynamicFeePercent(fee);

            setRedeemFeeModal(true);
        })();
    };


    const handleLiquidStakeExpand = () => {
        cleanLiquidStakeAmount();

        setIsLiquidStakeExpanded(!isLiquidStakeExpanded);
    };

    const handleStakeExpand = () => {
        cleanStakeAmount();

        setIsStakeExpanded(!isStakeExpanded);
    };

    const cleanLiquidStakeAmount = () => {
        setLiquidStakeInputAmount('0');
        setLiquidStakeInputAmountError('');
        setLiquidUnstakeInputAmount('0');
        setLiquidUnstakeInputAmountError('');
    };

    const cleanStakeAmount = () => {
        setStakeInputAmount('0');
        setStakeInputAmountError('');
        setUnstakeInputAmount('0');
        setUnstakeInputAmountError('');
    };

    const onLiquidStake = async () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(liquidStakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (liquidStakeInputAmountError) {
            toastError(liquidStakeInputAmountError);
            return;
        }

        await sendJewelLiquidStake(chainID, address, convertEsdtToWei(liquidStakeInputAmount).toFixed());

        cleanLiquidStakeAmount();
    };

    const onLiquidUnstake = async () => {
        setRedeemFeeModal(false);
        if (!liquidStakeBaseContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(liquidUnstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (liquidUnstakeInputAmountError) {
            toastError(liquidUnstakeInputAmountError);
            return;
        }

        await sendJewelLiquidUnstake(chainID, address, liquidStakeBaseContext.jwlegld_token_id, convertEsdtToWei(liquidUnstakeInputAmount).toFixed());

        cleanLiquidStakeAmount();
    };

    const onLiquidWithdraw = async () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(withdrawlAmount).lte('0') || unbondedNfts.length === 0) {
            return;
        }

        await sendJewelUnbond(chainID, address, unbondedNfts, JEWEL_LIQUID_STAKE_SC_ADDRESS);
    };

    const onStake = async () => {
        if (!liquidStakeBaseContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (stakeInputAmountError) {
            toastError(stakeInputAmountError);
            return;
        }

        await sendJewelJwlEgldStake(chainID, address, liquidStakeBaseContext.jwlegld_token_id, convertEsdtToWei(stakeInputAmount).toFixed());

        cleanStakeAmount();
    };

    const onUnstake = async () => {
        if (!liquidStakeBaseContext) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unstakeInputAmountError) {
            toastError(unstakeInputAmountError);
            return;
        }

        await sendJewelJwlEgldUnstake(chainID, address, liquidStakeBaseContext.sjwlegld_token_id, convertEsdtToWei(unstakeInputAmount).toFixed());

        cleanStakeAmount();
    };

    return (
        <div>
            <div className='d-flex mt-2'>
                {`JWLEGLD is a EGLD-pegged stablecoin.`}
            </div>
            <div className='d-flex mt-1'>
                <span>
                    {`Mint EGLD for JWLEGLD 1:1 which will be sent for validator staking when able to. A dynamic % will be sent for LP creation. Stake JWLEGLD for SJWLEGLD which will accrue staking yield. A redemption fee of 0.5% onwards may be charged subsequently. You can now swap EGLD-JWLEGLD at `}
                    <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                </span>
            </div>
            {/* <div className='d-flex mt-1'>
                {`To incentivize SJWLEGLD staking, enjoy a % Rewards Boost from all Ashswap Farms for a time-limited period.`}
            </div> */}
            <div className='d-flex mt-2 align-items-center w-100 overflow-auto'>
                <div className='mr-2 d-flex align-items-center' style={{ fontSize: '17px', whiteSpace: 'nowrap' }}>
                    <span>List of</span>
                    <span className='mx-2' style={{ borderBottom: '1px solid rgba(84, 245, 183, 0.6)', cursor: 'pointer' }} onClick={handleGoGauge}>Validators</span>
                    <span>-</span>
                </div>
                {
                    liquidStakeBaseContext && liquidStakeBaseContext.whitelisted_sp_addresses.map((item: string, index: number) => (
                        <div key={index} className='d-flex px-2 py-2 align-items-center'>
                            <img src={SP_METADATA[item].logo} alt={SP_METADATA[item].name} className='egld-image' width={30} height={30} style={{ borderRadius: '50%' }} />
                            <div style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>{SP_METADATA[item].name}</div>
                        </div>
                    ))
                }
            </div>
            {isLoading ? (
                <div className='mt-5'>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className='row mt-4' style={{ justifyContent: "center" }}>
                        <Accordion
                            expanded={isLiquidStakeExpanded}
                        >
                            <AccordionSummary
                                className={`col-12`}
                                sx={{ cursor: 'auto !important' }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                    <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={egld_white_logo} className='egld-image mx-2' alt='ash'></img>
                                            <div>
                                                <div>{`EGLD`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-1' : 'align-self-center'}`}>
                                        <div>{`TVL`}</div>
                                        <div className=''>{liquidStakeStatsContext ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(liquidStakeStatsContext.jwlegld_supply))} JWLEGLD` : '-'}</div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'></div>
                                    <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                        <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                            <div className='d-flex align-items-center'>Your balance</div>
                                            <div className='text-end'>
                                                <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(balance))} EGLD` : '-'}</div>
                                                <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlEgldTokenAmount))} JWLEGLD` : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                        <div
                                            className="px-2 py-2 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                            onClick={handleLiquidStakeExpand}
                                        >
                                            <div className='d-flex'>Mint / Redeem</div>
                                            {isLiquidStakeExpanded ? (
                                                <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                            ) : (
                                                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className='row'
                                sx={{ padding: '0px 16px 16px !important' }}
                            >
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                            <div className='d-flex'>
                                                {`Avail EGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance)) : '-'} EGLD`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                            <StakeInput
                                                userBalance={balance}
                                                inputAmount={liquidStakeInputAmount}
                                                amountError={liquidStakeInputAmountError}
                                                setInputAmount={setLiquidStakeInputAmount}
                                                setAmountError={setLiquidStakeInputAmountError}
                                                isUnstake={false}
                                                tokenId={'EGLD'}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLiquidStake}>
                                                    {`Mint`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                            <div className='d-flex'>
                                                {`Avail JWLEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlEgldTokenAmount)) : '-'} JWLEGLD`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                            <StakeInput
                                                userBalance={jwlEgldTokenAmount}
                                                inputAmount={liquidUnstakeInputAmount}
                                                amountError={liquidUnstakeInputAmountError}
                                                setInputAmount={setLiquidUnstakeInputAmount}
                                                setAmountError={setLiquidUnstakeInputAmountError}
                                                isUnstake={true}
                                                tokenId={'JWLEGLD'}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleOpenFeeModal}>
                                                    {`Redeem`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className='d-flex justify-content-center'>
                                                <div>{`Unbonding Period`}</div>
                                            </div>
                                            {
                                                !networkConfig || unbondingNfts.length === 0 ? (
                                                    <div className='d-flex justify-content-center mt-4'>
                                                        <div>{`-`}</div>
                                                    </div>
                                                ) : (
                                                    unbondingNfts.map((item: NftWithIndexType, index: number) => {
                                                        const attribute = getLiquidUnstakeTokenAttributes(item);
                                                        return <div className='d-flex justify-content-between mt-1' key={index}>
                                                            <div>{`• ${convertBigNumberValueToLocalString(convertWeiToEsdt(attribute.unstake_amount))} EGLD`}</div>
                                                            <div>{`${getUnbondEndTimeString(attribute.unbond_epoch, networkConfig)}`}</div>
                                                        </div>;
                                                    })
                                                )
                                            }
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3'>
                                            <div className='d-flex justify-content-center'>
                                                <div>{`Unbonded: ${convertBigNumberValueToLocalString(convertWeiToEsdt(withdrawlAmount))} EGLD`}</div>
                                            </div>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLiquidWithdraw}>
                                                    {`Withdraw`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='row mt-4' style={{ justifyContent: "center" }}>
                        <Accordion
                            expanded={isStakeExpanded}
                        >
                            <AccordionSummary
                                className={`col-12`}
                                sx={{ cursor: 'auto !important' }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                    <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                        <div className='d-flex align-items-center'>
                                            <img src={jwlegld_logo} className='egld-image mx-2' alt='ash'></img>
                                            <div>
                                                <div>{`JWLEGLD`}</div>
                                                <div style={{ fontSize: '0.8rem' }}>{`1 SJWLEGLD = ${liquidStakeStatsContext && BigNumber(liquidStakeStatsContext.sjwlegld_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(BigNumber(liquidStakeStatsContext.jwlegld_pool_reserve).dividedBy(liquidStakeStatsContext.sjwlegld_pool_reserve), 5) : '-'} JWLEGLD`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-1' : 'align-self-center'}`}>
                                        <div>{`TVL`}</div>
                                        <div className=''>{liquidStakeStatsContext ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(liquidStakeStatsContext.sjwlegld_pool_reserve))} SJWLEGLD` : '-'}</div>
                                    </div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                        <div>{`APY / APR(24H)`}</div>
                                        {
                                            <div className='avaliable-color'>{`${apy == '0' ? '-' : apy}% / ${convertBigNumberValueToLocalString(realApr)}%`}</div>
                                        }
                                    </div>
                                    <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                        <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                            <div className='d-flex align-items-center'>Your balance</div>
                                            <div className='text-end'>
                                                <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jwlEgldTokenAmount))} JWLEGLD` : '-'}</div>
                                                <div>{isLoggedIn && liquidStakeStatsContext ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlEgldTokenAmount))} SJWLEGLD (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(sjwlEgldTokenAmount).multipliedBy(liquidStakeStatsContext.jwlegld_pool_reserve).dividedBy(liquidStakeStatsContext.sjwlegld_pool_reserve)))} JWLEGLD)` : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                        <div
                                            className="px-2 py-2 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                            onClick={handleStakeExpand}
                                        >
                                            <div className='d-flex'>Stake / Unstake</div>
                                            {isStakeExpanded ? (
                                                <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                            ) : (
                                                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className='row'
                                sx={{ padding: '0px 16px 16px !important' }}
                            >
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                            <div className='d-flex'>
                                                {`Avail JWLEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(jwlEgldTokenAmount)) : '-'} JWLEGLD`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                            <StakeInput
                                                userBalance={jwlEgldTokenAmount}
                                                inputAmount={stakeInputAmount}
                                                amountError={stakeInputAmountError}
                                                setInputAmount={setStakeInputAmount}
                                                setAmountError={setStakeInputAmountError}
                                                isUnstake={false}
                                                tokenId={'JWLEGLD'}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onStake}>
                                                    {`Stake`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                            <div className='d-flex'>
                                                {`Avail SJWLEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(sjwlEgldTokenAmount)) : '-'} SJWLEGLD`}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                            <StakeInput
                                                userBalance={sjwlEgldTokenAmount}
                                                inputAmount={unstakeInputAmount}
                                                amountError={unstakeInputAmountError}
                                                setInputAmount={setUnstakeInputAmount}
                                                setAmountError={setUnstakeInputAmountError}
                                                isUnstake={true}
                                                tokenId={'SJWLEGLD'}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                            <div className='d-flex'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onUnstake}>
                                                    {`Unstake`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
            )}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={redeemFeeModal}
                onClose={() => setRedeemFeeModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={redeemFeeModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex'>
                            {`A ${dynamicFeePercent / PERCENT_DENOMINATOR}% fee (${convertBigNumberValueToLocalString(BigNumber(liquidUnstakeInputAmount).multipliedBy(dynamicFeePercent / PERCENT_DENOMINATOR / 100))} JWLEGLD) will be charged for redemption.`}
                        </div>
                        <div className='d-flex justify-content-center align-items-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLiquidUnstake}>
                                {`Redeem`}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};
