import React, { useState, FC } from 'react';
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { isMobile } from 'react-device-detect';
import {
  claimExchangeReward,
  unbondExchangeFarm,
  unstakeExchangeFarm,
} from 'z/elrond';
import { XexchangeFarmInfo } from 'z/elrond/xexchange-api';
import {
  ElrondStatsType,
  JewelExchangeFarmBaseContext,
  JewelExchangeFarmContext,
  JewelExchangeFarmTokenContext,
  JewelExchangePositionContext,
} from 'z/types';
import {
  convertBigNumberValueToLocalString,
  convertWeiToEsdt,
  ERROR_CONNECT_YOUR_WALLET,
  getTokenDecimal,
  getTokenImage,
  getTokenTicker,
  getUnbondEndTimeString,
  printEsdt,
  toastError,
} from 'z/utils';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const tabs = ['Actived Farms', 'Unstaked Farms', 'Unbonded Farms'];

interface JewelExchangePositionsProps {
  commonSettings: JewelExchangeFarmBaseContext | undefined,
  farms: JewelExchangeFarmContext[],
  networkConfig: ElrondStatsType,
  userActivePositions: JewelExchangePositionContext[],
  userUnstakePositions: JewelExchangePositionContext[],
  userUnbondPositions: JewelExchangePositionContext[],
  isPositionsLoading: boolean,
  farmTokenContext: JewelExchangeFarmTokenContext | undefined,
  exchangeFarmsInfo: XexchangeFarmInfo[],
}

export const JewelExchangePositions: FC<JewelExchangePositionsProps> = ({
  commonSettings,
  farms,
  networkConfig,
  userActivePositions,
  userUnstakePositions,
  userUnbondPositions,
  isPositionsLoading,
  farmTokenContext,
  exchangeFarmsInfo,
}) => {
  const { chainID } = useGetNetworkConfig();
  const { address } = useGetAccount();
  const isLoggedIn = useGetIsLoggedIn();

  const [tab, setTab] = useState(0);

  const farmRewardsRate = commonSettings ? commonSettings.fee_percents.reduce((pv, cv) => pv + cv) / 100_0000 : 1;

  //
  const columns: GridColDef[] = [
    {
      field: 'index',
      headerName: '#',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='py-2'>
          {`Jewel #${params.value}`}
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'farm_address',
      headerName: 'Pool',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((item: JewelExchangeFarmContext) => item.farm_address === params.row.farm_address)[0];
        return <div className='py-2'>
          {`${getTokenTicker(farm.pair_token_ids[0])}-${getTokenTicker(farm.pair_token_ids[1])}`}
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'farm_apr',
      headerName: 'APR',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const exchangeFarmInfo = exchangeFarmsInfo.find((farmInfo) => farmInfo.address == params.row.farm_address);
        return <div className='py-2'>{exchangeFarmInfo ? `${convertBigNumberValueToLocalString(exchangeFarmInfo.totalApr)} %` : '-'}</div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_state',
      headerName: 'State',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'current_value',
      headerName: 'Current Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        const index = params.row.contextIndex;
        if (!farmTokenContext || !farmTokenContext.pair_token_payments_vec || farmTokenContext.pair_token_payments_vec.length < index + 1) {
          return <div></div>;
        }
        const payments = farmTokenContext.pair_token_payments_vec[index];
        return (
          <div className='d-flex flex-column gap-1 align-items-start my-1 w-100'>
            <div>
              <img className='egld-badge me-2' src={getTokenImage(payments[0].token_identifier)} alt='logo' />
              <span>{printEsdt(convertWeiToEsdt(payments[0].amount, getTokenDecimal(payments[0].token_identifier), getTokenDecimal(payments[0].token_identifier)), getTokenDecimal(payments[0].token_identifier))}</span>
            </div>
            <div>
              <img className='egld-badge me-2' src={getTokenImage(payments[1].token_identifier)} alt='logo' />
              <span>{printEsdt(convertWeiToEsdt(payments[1].amount, getTokenDecimal(payments[1].token_identifier), getTokenDecimal(payments[1].token_identifier)), getTokenDecimal(payments[1].token_identifier))}</span>
            </div>
            <div>
              {'(LP: '}
              <span>{printEsdt(convertWeiToEsdt(payments[2].amount, getTokenDecimal(payments[2].token_identifier), getTokenDecimal(payments[2].token_identifier)), getTokenDecimal(payments[2].token_identifier))}</span>
              {')'}
            </div>
          </div>
        );
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'rewards',
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className='d-flex align-items-center'>
          <div>Rewards</div>
          <Tooltip
            placement='top'
            title='Actual rewards (much more) will be sent to your wallet once you click Claim Rewards'
          >
            <InfoCircleOutlined className='mx-1' style={{ fontSize: '14px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        if (tab !== 0 || !farmTokenContext) {
          return <div></div>;
        }
        const index = params.row.contextIndex;
        if (!farmTokenContext || !farmTokenContext.user_reward_amounts_vec || farmTokenContext.user_reward_amounts_vec.length < index + 1) {
          return <div></div>;
        }
        return <div className='my-1'>
          <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(farmTokenContext.user_reward_amounts_vec[index]).multipliedBy(farmRewardsRate))} JWLXMEX</div>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'unstake_epoch',
      headerName: 'Unbond Time',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        if (tab === 0) {
          return <div></div>;
        }
        const farm = farms.filter((item: JewelExchangeFarmContext) => item.farm_address === params.row.farm_address)[0];
        return <div className='my-1'>
          <div>{getUnbondEndTimeString(params.row.unstake_epoch + farm.minimum_farming_epochs, networkConfig)}</div>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'button',
      headerName: 'Action',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='my-1 d-flex flex-column gap-1'>
          {
            tab === 0 && (
              <>
                <button className='approval_but' onClick={() => handleClaimRewards(params.row.collection, params.row.nonce, params.row.balance)}>Claim Rewards</button>
                <button className='approval_but' onClick={() => handleUnstake(params.row.collection, params.row.nonce, params.row.balance)}>Unstake</button>
              </>
            )
          }
          {
            tab === 2 && (
              <button className='approval_but' onClick={() => handleUnbond(params.row.collection, params.row.nonce, params.row.balance)}>Unbond</button>
            )
          }
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  const handleUnstake = async (collection: string, nonce: number, balance: string) => {
    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    await unstakeExchangeFarm(chainID, address, collection, nonce, balance);
  };

  const handleUnbond = async (collection: string, nonce: number, balance: string) => {
    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    await unbondExchangeFarm(chainID, address, collection, nonce, balance);
  };

  const handleClaimRewards = async (collection: string, nonce: number, balance: string) => {
    if (!isLoggedIn) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }

    await claimExchangeReward(chainID, address, collection, nonce, balance);
  };

  return (
    <>
      <div className='w-100' style={{ marginTop: '30px' }}>
        <div className='d-flex justify-content-center align-items-center main-tab-container'>
          {tabs.map((row, index) => (
            <span className={`main-tab-individual text-white ${tab === index ? 'focus-bg-gradient' : ''}`} key={row} onClick={() => setTab(index)}>{row}</span>
          ))}
        </div>
        <div className='mt-5'>
          <DataGrid
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 },
              fontSize: '12px',
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '400px'
              },
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            rows={tab === 0 ? userActivePositions : tab === 1 ? userUnstakePositions : userUnbondPositions}
            columns={columns}
            getRowId={(row) => row.index}
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
            loading={isPositionsLoading}
          />
        </div>
      </div>
    </>
  );
};
