import React, { useState, FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { Backdrop, Box, Modal } from '@mui/material';
import { InputNumber } from 'antd';
import { animated, useSpring } from 'react-spring';
import BigNumber from 'bignumber.js';
import { closeOnedexPosition } from 'z/elrond';
import { EsdtTokenPayment, JewelOnedexFarmBaseContext, JewelOnedexFarmContext, JewelOnedexPostionContext } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, getFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR, YEAR_IN_HOURS } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { SLIPPAGE } from 'config';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

interface OnedexPositionsProps {
  commonSettings: JewelOnedexFarmBaseContext | undefined,
  farms: JewelOnedexFarmContext[],
  isPositionsLoading: boolean,
  openedPositions: JewelOnedexPostionContext[],
}

export const OnedexPositions: FC<OnedexPositionsProps> = ({
  commonSettings,
  farms,
  isPositionsLoading,
  openedPositions,
}) => {
  const {
    network: { apiAddress },
    chainID,
  } = useGetNetworkConfig();
  const { address } = useGetAccount();

  const [selectedSlippage, setSelectedSlippage] = useState<number | string | null>(SLIPPAGE);
  const [showClosePositionModal, setShowClosePositionModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<JewelOnedexPostionContext | undefined>();
  const [selectedFarm, setSelectedFarm] = useState<JewelOnedexFarmContext | undefined>();

  const handleOpenClosePositionModal = (item: JewelOnedexPostionContext) => {
    setSelectedPosition(item);
    const farm = farms.filter((farm: JewelOnedexFarmContext) => farm.farm_id === item.position_farm_id)[0];
    setSelectedFarm(farm);

    setSelectedSlippage(SLIPPAGE);
    setShowClosePositionModal(true);
  };

  const handleChangeSlippage = (value: number | string | null) => {
    setSelectedSlippage(value);
  };

  const handleClosePosition = async () => {

    if (selectedPosition && typeof selectedSlippage === "number") {
      await closeOnedexPosition(chainID, address, selectedPosition.position_id, selectedSlippage * PERCENT_DENOMINATOR);
      setShowClosePositionModal(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'position_id',
      headerName: '#',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='py-2'>
          {`Jewel #${params.value}`}
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_id',
      headerName: 'Pool',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((item: JewelOnedexFarmContext) => item.farm_id === params.row.position_farm_id)[0];
        return <div className='py-2'>
          {`${getTokenTicker(farm.first_token_id)}-${getTokenTicker(farm.second_token_id)}`}
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_apy',
      headerName: 'APY',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((item: JewelOnedexFarmContext) => item.farm_id === params.row.position_farm_id)[0];
        return <div className='py-2'>{`${convertBigNumberValueToLocalString(getFarmApy(farm.apr, YEAR_IN_HOURS))} %`}</div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_state',
      headerName: 'State',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_value',
      headerName: 'Position Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        const farm = farms.filter((item: JewelOnedexFarmContext) => item.farm_id === params.row.position_farm_id)[0];
        const lpSharePercent = BigNumber(farm.lp_token_amount).dividedBy(farm.lp_token_supply);
        const farmSharePercent = BigNumber(params.row.position_farm_share).dividedBy(farm.farm_share);
        return <div className='my-1'>
          <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(farm.first_token_id)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.first_token_reserve).multipliedBy(lpSharePercent).multipliedBy(farmSharePercent), getTokenDecimal(farm.first_token_id)))}</span>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(farm.second_token_id)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(farm.second_token_reserve).multipliedBy(lpSharePercent).multipliedBy(farmSharePercent), getTokenDecimal(farm.second_token_id)))}</span>
          </div>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deposit_value',
      headerName: 'Deposit Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        const farm = farms.filter((item: JewelOnedexFarmContext) => item.farm_id === params.row.position_farm_id)[0];
        return <div className='my-1'>
          {
            params.row.position_deposit_payments.map((item: EsdtTokenPayment, index: number) => (
              <div key={index} className='d-flex gap-2 align-items-center justify-content-center farm-modal'>
                {item.token_identifier !== farm.first_token_id && item.token_identifier !== farm.second_token_id ? (
                  <div className='currency-item align-items-center'>
                    <div className={`combine-bage`}>
                      <img src={getTokenImage(farm.first_token_id)} className='egld-image mx-2' alt={farm.first_token_id} />
                      <img src={getTokenImage(farm.second_token_id)} className='egld-image mx-2' alt={farm.second_token_id} />
                    </div>
                  </div>
                ) : (
                  <img className='egld-badge' src={getTokenImage(item.token_identifier)} alt='logo' />
                )}
                <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(item.amount, getTokenDecimal(item.token_identifier)))}</span>
              </div>
            ))
          }
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'button',
      headerName: 'Action',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='my-1'>
          <button className='approval_but' onClick={() => handleOpenClosePositionModal(params.row)}>Close</button>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <>
      <div className='w-100' style={{ marginTop: '30px' }}>
        <DataGrid
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 20 },
            fontSize: '12px',
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '400px'
            },
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          rows={openedPositions}
          columns={columns}
          getRowId={(row) => row.position_id}
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
          loading={isPositionsLoading}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showClosePositionModal}
        onClose={() => setShowClosePositionModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showClosePositionModal}>
          <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
            <div className='d-flex mt-4 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Initial Deposit Tokens
              </div>
              <div className='d-flex align-items-center text-end'>
                {selectedPosition && (
                  <>
                    {
                      selectedPosition.position_deposit_payments.map((item: EsdtTokenPayment, index: number) => {
                        return `${convertBigNumberValueToLocalString(convertWeiToEsdt(item.amount, getTokenDecimal(item.token_identifier)))} ${getTokenTicker(item.token_identifier)} ${selectedPosition.position_deposit_payments.length === (index + 1) ? '' : ' + '}`;
                      })
                    }
                  </>
                )}
              </div>
            </div>
            <div className='d-flex mt-2 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Current Position Value
              </div>
              <div className='d-flex align-items-center text-end'>
                {selectedPosition && selectedFarm && (
                  <>
                    {`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedFarm.first_token_reserve).multipliedBy(selectedFarm.lp_token_amount).dividedBy(selectedFarm.lp_token_supply).multipliedBy(selectedPosition.position_farm_share).dividedBy(selectedFarm.farm_share), getTokenDecimal(selectedFarm.first_token_id)))} ${getTokenTicker(selectedFarm.first_token_id)} + ${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedFarm.second_token_reserve).multipliedBy(selectedFarm.lp_token_amount).dividedBy(selectedFarm.lp_token_supply).multipliedBy(selectedPosition.position_farm_share).dividedBy(selectedFarm.farm_share), getTokenDecimal(selectedFarm.first_token_id)))} ${getTokenTicker(selectedFarm.second_token_id)}`}
                  </>
                )}
              </div>
            </div>
            <div className='d-flex mt-2 justify-content-between'>
              <div className='d-flex align-items-center'>
                Slippage
              </div>
              <div className='d-flex align-items-center'>
                <InputNumber
                  className={`farm-leverage-input`}
                  min={0}
                  max={100}
                  step={1}
                  value={selectedSlippage}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value!.replace('%', '')}
                  onChange={handleChangeSlippage}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleClosePosition}>
                Close Position
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
